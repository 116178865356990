@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

//
.p-products-lower {
    &__mainv {
        position: relative;
        margin-bottom: 110px;
        &__img {
            margin: 0 2px;
        }
        &__link {
            transition: $transition;
            &:hover {
                opacity: 0.75;
            }
        }
        // Slick設定
        .slick-slider {
            margin-bottom: 0;
        }
        .slick-initialized .slick-slide {
            display: flex;
            justify-content: flex-end;
        }
        .slick-prev, .slick-next {
            background: rgba(255,255,255,0.78);
            border-radius: 50%;
            width: 50px;
            height: 50px;
            border: 1px solid $c-accent;
            z-index: 1;
            transition: $transition;
            &::before {
                content: "";
                position: absolute;
                width: 9px;
                height: 14px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 1;
            }
            &:hover {
                opacity: 0.75;
            }
        }
        .slick-prev {
            &::before {
                background: url(/assets/img/common/slider_arrow_left.png) center/contain no-repeat;
            }
        }
        .slick-next {
            &::before {
                background: url(/assets/img/common/slider_arrow_right.png) center/contain no-repeat;
            }
        }
        &__control {
            display: flex;
            justify-content: flex-end;
            svg {
                vertical-align: inherit;
            }
            .slick-dots { 
                width: auto;
                bottom: -50px;
                text-align: right;
                padding-right: 0;
                z-index: 10;
                li {
                    position: relative;
                    width: 30px;
                    height: 30px;
                    &:not(:last-child) {
                        margin-right: 5px;
                    }
                    button {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                        font-size: 0;
                        &::before {
                            position: absolute;
                            content: '';
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            background: #555555;
                        }
                    }
                }
            }
        }
        // Slick設定ここまで
        @include sp {
            margin-bottom: 80px;
            .l-main__inner {
                padding: 0;
            }
            &__control {
                .slick-dots { 
                    bottom: -45px;
                    padding: 0 15px;
                }
            }
        }
    }
    &__index {
        &__list {
            display: flex;
            &__item {
                width: calc((100% - 80px) / 3);
                margin-right: 40px;
                position: relative;
                &:nth-child(3n) {
                    margin-right: 0;
                }
                .img {
                    overflow: hidden;
                    img {
                        transition: $transition;
                    }
                }
                .ttl {
                    margin-top: 25px;
                    font-size: 2rem;
                    font-weight: 500;
                    transition: $transition;
                }
                .txt {
                    margin-top: 15px;
                    font-size: 1.5rem;
                    color: $c-sub;
                }
                .link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                @include pc {
                    &:hover {
                        .img {
                            img {
                                transform: scale(1.1);
                            }
                        }
                        .ttl {
                            color: $c-accent;
                        }
                    }
                }
            }
        }
        @include sp {
            &__list {
                display: block;
                &__item {
                    width: 100%;
                    margin-right: 0px;
                    &:nth-child(n + 2) {
                        margin-top: 40px;
                    }
                    .ttl {
                        margin-top: 20px;
                        font-size: 1.7rem;
                    }
                    .txt {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }

    // 最新情報
    &__news {
        background: #f4f4f8;
        padding: 70px 0 90px;
        .m-news {
            margin-top: 30px;
        }
        @include sp {
            padding: 50px 0 60px;
            .m-news {
                margin-top: 0;
            }
        }
    }

    // 製品グループ
    &__products {
        &__list {
            margin-top: 50px;
            display: flex;
            flex-wrap: wrap;
            &__item {
                width: calc((100% - 60px) / 2);
                margin-right: 60px;
                display: flex;
                &:nth-child(2n) {
                    margin-right: 0;
                }
                &:nth-child(n + 3) {
                    margin-top: 50px;
                }
                .img {
                    width: calc((100% - 30px) / 2);
                    margin-right: 30px;
                }
                .txtbox {
                    width: calc((100% - 30px) / 2);
                    .ttl {
                        font-size: 2rem;
                        font-weight: 500;
                        margin-bottom: 25px;
                    }
                    .txt {
                        font-size: 1.6rem;
                        .link {
                            position: relative;
                            display: inline-block;
                            padding-left: 15px;
                            transition: $transition;
                            &::before {
                                content: "";
                                position: absolute;
                                width: 7px;
                                height: 10px;
                                top: 6px;
                                left: 0px;
                                background: url(/assets/img/common/icon_arrow_right.png) center/contain no-repeat;
                            }
                            &:hover {
                                color: $c-accent;
                            }
                        }
                        + .txt {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
        @include sp {
            &__list {
                margin-top: 25px;
                &__item {
                    width: 100%;
                    margin-right: 0;
                    display: block;
                    &:nth-child(n + 2) {
                        margin-top: 40px;
                    }
                    &:nth-child(n + 3) {
                        margin-top: 40px;
                    }
                    .img {
                        width: 100%;
                        margin: 0 auto;
                        background: #f7f7f7;
                        text-align: center;
                        img {
                            max-width: 180px;
                        }
                    }
                    .txtbox {
                        width: 100%;
                        margin-top: 20px;
                        .ttl {
                            font-size: 1.8rem;
                            margin-bottom: 20px;
                        }
                        .txt {
                            + .txt {
                                margin-top: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    .l-products__banner {
        .m-btn--center {
            display: flex;
        }
    }
}

