@charset "UTF-8";
@use "../foundation/variable" as *;

/*================================
	メディアクエリー
=================================*/
$breakpoints: (
 sp: 768px,
 pc: 769px,
 pcmin1600: 1600px,
 pcmin1200: 1200px,
 pcmin1260: 1260px,
 pcmin1280: 1280px,
 pcmin1000: 1000px,
  );

@mixin pc {
  @media screen and (min-width: map-get($breakpoints, pc)) {
    @content;
  }
}

// 画面幅 768 ~ 1600px TOP スライダー部分で使用
@mixin pcmin1600 {
  @media screen and (min-width: map-get($breakpoints, pc)) and (max-width: map-get($breakpoints, pcmin1600)) {
    @content;
  }
}

// 画面幅 768 ~ 1280px ヘッダーで使用
@mixin pcmin1280 {
  @media screen and (min-width: map-get($breakpoints, pc)) and (max-width: map-get($breakpoints, pcmin1280)) {
    @content;
  }
}

// 画面幅 768 ~ 1260px TOPコンテンツ部分で使用
@mixin pcmin1260 {
  @media screen and (min-width: map-get($breakpoints, pc)) and (max-width: map-get($breakpoints, pcmin1260)) {
    @content;
  }
}

// 画面幅 768 ~ 1200px ヘッダーメガメニューで使用 / TOP スライダー部分で使用
@mixin pcmin1200 {
  @media screen and (min-width: map-get($breakpoints, pc)) and (max-width: map-get($breakpoints, pcmin1200)) {
    @content;
  }
}

// 画面幅 768 ~ 1000px 製品情報遷移リンク
@mixin pcmin1000 {
  @media screen and (min-width: map-get($breakpoints, pc)) and (max-width: map-get($breakpoints, pcmin1000)) {
    @content;
  }
}

@mixin sp {
  @media screen and (max-width: map-get($breakpoints, sp)) {
    @content;
  }
}



@mixin link-underline {
  position: relative;
  display: inline-block;
  &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #222222;
      transform: scaleX(0);
      transform-origin: bottom left;
      transition: $transition;
  }
  @include pc {
      &:hover {
          &::before {
              transform: scaleX(1);
          }
      }
  }
  @content;
}