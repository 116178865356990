@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 会社概要 ご挨拶
.p-corporate-message {
   &__name {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-top: 15px;
    &__box {
        width: calc((100% - 20px) / 2);
    }
    &__title {
        font-size: 1.6rem;
    }
    &__main {
        font-size: 2rem;
    }
    @include sp {
        &__title {
            font-size: 1.4rem;
        }
        &__main {
            font-size: 1.8rem;
        }   
    }
   }
}