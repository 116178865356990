@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// オンライン展示会
.p-online_expo {
    .l-lower__mainv__ttl {
        font-size: 2.8rem;
        &__en {
            display: block;
            font-size: 5.4rem;
            font-family: Oswald-Regular;
        }
        @include sp {
            font-size: 1.6rem;
            &__en {
                font-size: 3rem;
            }
        }
    }
}