@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// サービス＆サポートTOP
.p-service_support {
    &__news {
        // background: #f4f4f8;
        // padding: 70px 0 90px;
        .m-news {
            margin-top: 30px;
            &__item .txt__link::before {
                display: none;
            }
        }
        @include sp {
            padding: 50px 0 60px;
            .m-news {
                margin-top: 0;
            }
        }

        // サポートのニュース一覧ボタンの調整
        .m-btn--arrow {
            margin-top: -33px;
            @include pcmin1000 {
                margin-top: 30px;
            }
            @include sp {
                margin-top: 40px;
            }
        }
    }
    &__index {
        display: flex;
        box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.12);
        background: #fff;
        &__item {
            width: calc(100% / 5);
            padding: 30px 0;
            position: relative;
            transition: $transition;
            &__inner {
                border-right: 1px solid #eeeeee;
            }
            &:last-child {
                .p-service_support__index__item__inner {
                    border-right: none;
                }
            }
            @include pc {
                &:hover {
                    background: #f4f4f8;
                    .p-service_support__index__text {
                        color: $c-accent;
                    }
                }
            }
        }
        &__img {
            width: 70px;
            margin: 0 auto;
        }
        &__text {
            font-size: 1.5rem;
            margin-top: 10px;
            text-align: center;
            transition: $transition;
            min-height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__link {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
        @include sp {
            flex-wrap: wrap;
            &__item {
                width: calc(100% / 2);
                padding: 20px 0;
                border-bottom: 1px solid #eeeeee;
                &__inner {
                    padding: 0 20px;
                    border-right: 1px solid #eeeeee;
                }
                &:last-child {
                    .p-service_support__index__item__inner {
                        border-right: 1px solid #eeeeee;
                    }
                }
                &:nth-child(2n) {
                    .p-service_support__index__item__inner {
                        border-right: none;
                    }
                }
            }
            &__img {
                width: 60px;
            }
            &__text {
                font-size: 1.3rem;
                min-height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &__link {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
            }
            // flex-wrap: wrap;
            // &__item {
            //     width:100%;
            //     border-right: 0;
            //     box-shadow: 0px 0px 20px 0px rgba(2, 34, 110, 0.12);
            //     display: flex;
            //     align-items: center;
            //     padding: 10px 20px;
            //     border-radius: 6px;
            //     &:nth-child(n + 2) {
            //         margin-top: 10px;
            //     }
            // }
            // &__img {
            //     width: 48px;
            // }
            // &__text {
            //     width: calc(100% - 48px);
            //     font-size: 1.5rem;
            //     margin-top: 0;
            //     text-align: left;
            //     min-height: inherit;
            //     display: inherit;
            //     justify-content: flex-start;
            //     padding-left: 15px;
            // }
        }
    }
    .m-banner {
        margin-top: 50px;
        @include sp {
            margin-top: 30px;
        }
    }
}