@charset "UTF-8";
@use "mixin" as *;
@use "color" as *;

/*================================
	共通設定
=================================*/
* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  line-height: 1.5;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", "Yu Gothic", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-feature-settings : "palt";
  color: $c-base;
  font-weight: 400;
  letter-spacing: 0.05rem;
  position: relative;
  word-break: break-word;
  .no-palt {
    font-feature-settings : inherit;
  }
  &.is-noscroll-ip {
	position: fixed; 
	z-index: -1;
	width: 100%;
	height: 100vh;
  }
  &.cover {
	position: relative;
	// overflow: hidden;
	&::after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0,0,0,0.5);
		z-index: 95;
	}
	@include sp {
		overflow: hidden;
	}
  }
}


// PC表示用クラス
.is-pc {
	display: block !important;
	@include sp {
		display: none !important;
	}
}

// SP表示用クラス
.is-pc--ib {
	display: inline-block !important;
	@include sp {
		display: none !important;
	}
}

.is-sp--ib {
	display: none !important;
	@include sp {
		display: inline-block !important;
	}
}

.is-sp {
	display: none !important;
	@include sp {
		display: block !important;
	}
}

.is-pc--flex {
	display: flex !important;
	@include sp {
		display: none !important;
	}
}

.is-sp--flex {
	display: none !important;
	@include sp {
		display: flex !important;
	}
}

.is-none {
	display: none !important;
}

img {
	width: 100%;
	height: auto;
	image-rendering: -webkit-optimize-contrast;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  margin-left: 0;
}

input {
  &::placeholder {
    color: #aaaaaa;
  }
}
input[type=checkbox], input[type=radio] {
	display: none;
}

#mrc_webform {
	select {
		border: 1px solid #333;
	}
	input[type=checkbox], input[type=radio] {
		display: inherit;
	}
	@include sp {
		textarea {
			width: 100% !important;
		}
	}
}