@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// サステナビリティトップ
.sustainability .l-lower__content {
  @include pc {
    overflow-x: hidden;
  }
}

.p-sustainability {
  &__sec01 {
    .m-sec__ttl__color {
      @include pc {
        font-size: 5.2rem;
      }

      @include sp {
        font-size: 4.2rem;
      }
    }

    .m-sec__ttl__sub {
      @include sp {
        margin-top: 15px;
      }
    }

    &__catchcopy {
      font-family: "Noto Serif JP";
      letter-spacing: 0.04em;
      line-height: 2;

      @include pc {
        font-size: clamp(1.8rem, 2.3vw, 3.2rem);
        margin-top: 45px;
      }

      @include sp {
        font-size: 2.1rem;
        margin-top: 35px;
      }

      + p {
        @include pc {
          margin-top: 40px;
        }
      }
    }

    .p-sustainability__sec01__txt {
      line-height: 2.2;
    }

    .m-image-text--type01 {
      @include sp {
        flex-direction: column-reverse;
      }

      &.m-image-text--reverse {
        .m-image-text__item {
          @include pc {
            margin-left: 0;
            margin-right: -90px;
          }

          @include pcmin1260 {
            margin-right: -60px;
          }
        }

        .m-image-text__image {
          @include pc {
            top: 0;
          }

          @include sp {
            margin-left: 0;
            margin-right: -15px;
          }
        }
      }

      .m-image-text__item {
        @include pc {
          margin-left: -75px;
          width: 47.5%;
        }

        @include pcmin1260 {
          margin-left: -60px;
        }

        @include sp {
          margin-top: 40px;
        }

        &:nth-child(2n) {
          @include pc {
            width: 54.17%;
            margin-right: 0;
          }

          @include sp {
            margin-top: 0;
          }
        }
      }

      .m-image-text__image {
        background-color: transparent;
        position: relative;

        @include pc {
          top: -30px;
        }

        @include sp {
          margin-left: -15px;
        }
      }
    }
  }

  &__sec02 {
    .p-sustainability__list {
      @include pc {
        display: flex;
      }

      &__item {
        background-color: #f0f3f7;

        @include pc {
          padding: 36px 3.3% 60px; //1200pxの時40px
          width: 33.05333333%;
        }

        @include sp {
          padding: 36px 15px 50px;
        }

        &__ttl {
          &__main {
            display: block;
            font-family: Oswald-Regular;
            text-align: center;

            @include pc {
              font-size: clamp(32px, 2.92vw, 35px);
            }

            @include sp {
              font-size: 3.2rem;
            }
          }

          &__sub {
            display: block;
            text-align: center;
            font-size: 1.6rem;
          }
        }

        &__icon {
          align-items: center;
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0 0 35px 0 rgba(2, 34, 110, .08);
          display: flex;
          height: 122px;
          justify-content: center;
          margin: 23px auto 0;
          width: 122px;

          img {
            width: 70px;
            height: 70px;
          }
        }

        &__txt {
          line-height: 1.6;
          text-align: center;

          @include pc {
            font-size: clamp(14px, 1.58vw, 19px);
            margin-top: 28px;
          }

          @include sp {
            margin-top: 25px;
          }
        }

        + .p-sustainability__list__item {
          @include pc {
            margin-left: 0.42%; //1200pxの時5px
          }

          @include sp {
            margin-top: 10px;
          }
        }
      }

      .m-btn {
        > .m-btn--normal {
          color: #fff;
          background: none;
          box-shadow: none;
          
          &::before {
            display: none;
          }

          @include pc {
            max-width: 290px;
            min-width: 0;
            width: 100%;
          }
        }

        .m-btn--cornflowerblue {
          background-color: #546dc0;
          border-color: #546dc0;

          &:hover {
            background-color: #6c88e2;
            border-color: #6c88e2;
            box-shadow: none;
          }
        }

        .m-btn--deepskyblue {
          background-color: #009fd3;
          border-color: #009fd3;

          &:hover {
            background-color: #2bbae9;
            border-color: #2bbae9;
          }
        }

        .m-btn--lightseagreen {
          background-color: #00a8a2;
          border-color: #00a8a2;

          &:hover {
            background-color: #01bfb8;
            border-color: #01bfb8;
          }
        }
      }
    }

    &__list {
      .m-text-link {
        + .m-text-link {
          margin-top: 10px;
        }
      }
    }
  }

  &__sec03 {
    .l-sustainability__report {
      &__body {
        background: center / cover no-repeat;

        @include pc {
          background-image: url("/assets/img/sustainability/sustainability_sec03_img001.jpg");
          padding: 60px 0;
        }

        @include sp {
          background-image: url("/assets/img/sustainability/sustainability_sec03_img001_sp.jpg");
          padding: 40px 0;
        }
      }

      &__ttl {
        color: #fff;

        @include pc {
          font-size: 3.2rem;
        }

        @include sp {
          font-size: 2.4rem;
        }
      }

      &__txt {
        color: #fff;
        line-height: 2;

        @include pc {
          margin-top: 30px;
        }

        @include sp {
          margin-top: 20px;
        }
      }

      .m-btn {
        > .m-btn--normal {
          background: #fff;
          border-color: #fff;
          color: $c-accent;
          box-shadow: none;

          &::before {
            display: none;
            box-shadow: none;
          }

          &:hover {
            background-color: $c-accent;
            border-color: $c-accent;
            color: #fff;
          }
        }
      }
    }
  }
}
