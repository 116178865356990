@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// NMRユーザーズミーティング要旨集 バックナンバー
.p-meeting {
    .m-ac01 {
        &__content {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        @include sp {
            &__content {
                padding-top: 0;
                padding-bottom: 0;
            }   
        }
    }
    .m-news {
        &__item {
            flex-wrap: wrap;
            padding-right: 15px;
            position: relative;
            &::after {
                position: absolute;
                content: "";
                width: 8px;
                height: 15px;
                top: 50%;
                right: 0px;
                transform: translate(0, -50%);
                transition: $transition;
                background: url(/assets/img/common/icon_arrow_right.png) center/contain no-repeat;
            }
            .ttl {
                font-size: 1.8rem;
                font-weight: bold;
                line-height: 1.4;
                transition: $transition;
            }
            .txt {
                width: 100%;
                margin-top: 10px;
            }
            &:last-child {
                border-bottom: none;
            }
            &:hover {
                .ttl {
                    color: $c-accent;
                }
                &::after {
                    right: 6px;
                }
            }
        }
        @include sp {
            &__item {
                .ttl {
                    font-size: 1.7rem;
                }
            }
        }
    }
}