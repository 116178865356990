@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 設置環境対策
.p-installation_room {
    &__map {
       margin-top: 50px;
       position: relative;
       &__pin {
            position: absolute;
            transition: $transition;
            cursor: pointer;
            display: flex;
            align-items: flex-start;
            &:hover {
                opacity: 0.6;
            }
            img {
                width: 36px;
                margin: 0 15px;
            }
            &__box {
                background: rgba(255, 255, 255, 0.8);
                padding: 8px 20px;
                text-align: center;
            }
            &__ttl {
                font-size: 1.8rem;
                font-weight: 500;
            }
            &__txt {
                font-size: 1.6rem;
            }
            &.building {
                top: 13%;
                left: 31%;
                flex-direction: row-reverse;
            }
            &.transmission {
                top: 22%;
                left: 55.5%;
            }
            &.subway {
                top: 47%;
                left: 51.5%;
            }
            &.traffic {
                top: 58%;
                left: 30%;
                flex-direction: row-reverse;
            }
            &.construction {
                top: 67%;
                left: 61%;
            }
            &.railway {
                top: 78%;
                left: 39%;
                flex-direction: row-reverse;
            }
       }
       @include pcmin1200 {
            &__pin {
                img {
                    width: vwm(36px);
                    margin: 0 vwm(15px);
                }
                &__box {
                    padding: vwm(8px) vwm(20px);
                }
                &__ttl {
                    font-size: vwm(18px);
                }
                &__txt {
                    font-size: vwm(16px);
                }
            } 
        }
        @include sp {
            margin-top: 40px;
            &__pin {
                img {
                    width: 30px;
                    margin: 0 7px;
                }
                &__box {
                    padding: 8px;
                }
                &__ttl {
                    font-size: 1.5rem;
                }
                &__txt {
                    font-size: 1.3rem;
                }
                &.building {
                    top: 9%;
                    left: 6%;
                    flex-direction: row-reverse;
                }
                &.transmission {
                    top: 19%;
                    left: 60.5%;
                }
                &.subway {
                    top: 42%;
                    left: 51.5%;
                }
                &.traffic {
                    top: 55%;
                    left: 5%;
                    flex-direction: row-reverse;
                }
                &.construction {
                    top: 65%;
                    left: 70%;
                    flex-direction: column;
                }
                &.railway {
                    top: 77%;
                    left: 19%;
                    flex-direction: row-reverse;
                }
           }
        } 
    }
    &__iconlist {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 25px;
        &__txt {
            font-size: 1.3rem;
            font-weight: 500;
        }
        &__item {
            display: flex;
            align-items: center;
            width: 130px;
            &:first-of-type {
                width: 150px;
                margin-left: 25px;
            }
            &__icon {
                width: 54px;
                margin-right: 10px;
            }
        }
        @include sp {
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-top: 20px;
            &__txt {
                font-size: 1.4rem;
                &:first-of-type {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
            &__item {
                width: 50%;
                &:first-of-type {
                    width: 50%;
                    margin-left: 0;
                }
                &:nth-of-type(n + 3) {
                    margin-top: 15px;
                }
                &__icon {
                    width: 36px;
                }
            }
        }
    }

    &__modal {
        .m-modal {
            &__contents {
                max-width: 620px;
                &__inner {
                    padding: 50px 70px;
                }
            }
        }
        &__content {
            position: relative;
        }
        &__ttl {
            font-size: 2.4rem;
        }
        &__icon {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            &__item {
                width: 40px;
                &:nth-child(n + 2) {
                    margin-left: 10px;
                }
            }
        }
        &__txt {
            margin-top: 25px;
            font-size: 1.6rem;
            line-height: 2;
            + .p-installation_room__modal__txt {
                margin-top: 15px;
            }
        }
        &__tag {
            color: #fff;
            background: $c-accent;
            font-size: 1.4rem;
            font-weight: 500;
            display: inline-block;
            margin-right: 10px;
            padding: 6px 8px 5px;
        }
        &__image {
            margin-top: 40px;
        }
        @include sp {
            .m-modal {
                &__contents {
                    &__inner {
                        padding: 40px 25px;
                    }
                }
            }
            &__ttl {
                font-size: 2rem;
            }
            &__icon {
                &__item {
                    width: 34px;
                    &:nth-child(n + 2) {
                        margin-left: 5px;
                    }
                }
            }
            &__txt {
                margin-top: 20px;
                font-size: 1.5rem;
            }
            &__tag {
                font-size: 1.3rem;
            }
            &__image {
                margin-top: 20px;
            }
        }
    }
}

// 設置室環境調査
.p-installation_room-01 {
    &__table {
        &__box {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            &:nth-child(n + 2) {
                margin-top: 90px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 90px;
                    height: 30px;
                    top: -60px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    border-style: solid;
                    border-width: 30px 45px 0 45px;
                    border-color: #ccc transparent transparent transparent;
                }
            }
        }
        &__ttl {
            width: 30%;
            background: #2760bf;
            padding: 20px;
            color: #fff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.8rem;
        }
        &__content {
            padding: 20px;
            background: #f2f2f2;
            width: 70%;
            .m-image__image img {
                width: 100%;
            }
        }
        @include sp {
            &__box {
                &:nth-child(n + 2) {
                    margin-top: 60px;
                    &::before {
                        width: 80px;
                        height: 15px;
                        top: -40px;
                        border-width: 25px 40px 0 40px;
                    }
                }
            }
            &__ttl {
                width: 100%;
                padding: 15px;
            }
            &__content {
                padding: 15px;
                width: 100%;
            }
        }
    }
    &__m-image {
        .m-image__item:first-child {
            img {
                min-width: 60%;
            }
        }
        @include sp {
            .m-image__item:first-child {
                img {
                    min-width: 100%;
                }
            }
        }
    }
}

// 設置室工事
.p-installation_room-02 {
    &__table {
        margin-top: 20px;
        &__box {
            display: flex;
            flex-wrap: wrap;
            &:nth-child(n + 2) {
                margin-top: 30px;
            }
        }
        &__ttl {
            width: 30%;
            background: #2760bf;
            padding: 20px;
            color: #fff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.8rem;
        }
        &__content {
            padding: 20px;
            background: #f2f2f2;
            width: 70%;
            .m-image__image img {
                width: 100%;
            }
        }
        @include sp {
            &__box {
                &:nth-child(n + 2) {
                    margin-top: 20px;
                }
            }
            &__ttl {
                width: 100%;
                padding: 15px;
            }
            &__content {
                padding: 15px;
                width: 100%;
            }
        }
    }
    .m-image__image img {
        width: 100%;
    }
    .m-list--dot__item:nth-child(n+2) {
        margin-top: 5px;
    }
}

// 騒音対策
.p-installation_room-04 {
    &__image-height {
        .m-image__image {
            height: 85%;
            @include sp {
                height: auto;
            }
        }
    }
    .m-image__image img {
        width: 100%;
    }
}


// 磁場対策
.p-installation_room-05 {
    &__lineup {
        display: flex;
        &--01 {
            &:nth-child(1) {
                width: 60%;
            }
            &:nth-child(2) {
                margin-left: 30px;
                width: calc((40% - 60px) / 2);
            }
            &:nth-child(3) {
                margin-left: 30px;
                width: calc((40% - 60px) / 2);
            }
        }
        &--02 {
            margin-top: 40px;
            &:nth-child(1) {
                width: 31.5%;
            }
            &:nth-child(2) {
                margin-left: 30px;
                width: calc(68.5% - 30px);
            }
        }
        &--03 {
            &:nth-child(1) {
                width: 66%;
            }
            &:nth-child(2) {
                width: calc(34% - 30px);
                margin-left: 30px;
            }
        }
        @include sp {
            flex-wrap: wrap;
            &--01 {
                &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                    width: 100%;
                    margin-left: 0;
                    img {
                        object-fit: contain;
                        width: 100%;
                        max-height: 300px;
                    }  
                }
            }
            &--02 {
                margin-top: 20px;
                &:nth-child(1), &:nth-child(2) {
                    width: 100%;
                    margin-left: 0;
                    img {
                        object-fit: contain;
                        width: 100%;
                        max-height: 300px;
                    }  
                }
                &:nth-child(2) {
                    margin-top: 20px;
                }
            }
            &--03 {
                &:nth-child(1), &:nth-child(2) {
                    width: 100%;
                    margin-left: 0;
                }
                &:nth-child(2) {
                    margin-top: 20px;
                }
            }
        }
    }
}


// 振動対策
.p-installation_room-06 {
    .m-image__image img {
        width: 100%;
    }
}

// 温度変動対策
.p-installation_room-03 {
    &__image {
        &.m-image--col2 {
            .m-image__item {
                &:nth-child(2) {
                    img {
                        max-height: 400px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}