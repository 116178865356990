@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 安全データシート（SDS）
.p-sds {
    &__table {
        overflow-x: auto;
        // overflow-y: auto;
        border: 1px solid #ccc;
        &__content {
            width: 100%;
            min-width: 1198px;
            // max-height: 525px;
        }
        &__data,
        &__check {
            padding: 25px 20px;
            font-weight: 500;
            display: flex;
            align-items: center;
        }
        &__data {
            position: relative;
            pointer-events: none;
        }
        .icon_new {
            position: relative;
            text-indent: 45px;
            &::before {
                position: absolute;
                content: "";
                top: 3px;
                left: 0px;
                width: 36px;
                height: 20px;
                background: url(/assets/img/common/icon_new.png) center/contain
                    no-repeat;
            }
        }
        &__sort {
            cursor: pointer;
            p {
                position: relative;
                display: inline-block;
                text-decoration: underline;
                text-decoration-color: #fff;
                text-underline-offset: 6px;
                &::before,
                &::after {
                    content: "";
                    height: 0;
                    width: 0;
                    position: absolute;
                    border: 5px solid transparent;
                    right: -20px;
                    top: 50%;
                }
                &::before {
                    border-bottom-color: #aaa;
                    margin-top: -10px;
                }
                &::after {
                    border-top-color: #aaa;
                    margin-top: 2px;
                }
            }
            &--up {
                p {
                    &::before {
                        border-bottom-color: #fff;
                    }
                }
            }
            &--down {
                p {
                    &::after {
                        border-top-color: #fff;
                    }
                }
            }
        }
        &__head__item,
        &__body__row > div {
            &:nth-child(1) {
                width: 120px;
            }
            &:nth-child(2) {
                width: 130px;
            }
            &:nth-child(3) {
                // width: 380px;
                width: 350px;
            }
            &:nth-child(4) {
                width: 250px;
            }
            &:nth-child(5) {
                width: 160px;
            }
            &:nth-child(6) {
                width: 170px;
            }
        }
        &__head {
            display: flex;
            background: #606e85;
            position: sticky;
            top: 0;
            z-index: 1;
            &__item {
                font-size: 1.8rem;
                color: #fff;
                font-weight: normal;
                text-align: left;
                padding: 15px 20px;
            }
        }
        &__body__content {
            overflow-y: auto;
            overflow-x: auto;
        }
        &__body {
            min-width: 1172px;
            max-height: 525px;
            &__row {
                border-top: 1px solid #ccc;
                display: flex;
                transition: $transition;
                position: relative;
                &.disabled {
                    &::before {
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: rgba(0, 0, 0, 0.3);
                        pointer-events: none;
                        z-index: 10;
                    }
                    .p-sds__table__check {
                        &__box {
                            label {
                                pointer-events: none;
                            }
                        }
                    }
                }
            }
        }
        &__check {
            background: #e0e3e8;
            text-align: center;
            padding: 0;
            justify-content: center;
            &__link {
                color: $c-accent;
                text-decoration: underline;
                text-decoration-color: $c-accent;
                text-underline-offset: 6px;
            }
            &__box {
                width: 100%;
                height: 100%;
                input[type="checkbox"] {
                    display: none;
                }
                label {
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    transition: $transition;
                    // position: relative;
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 52px;
                        // transform: translate(-50%, -50%);
                        transform: translate(0, -50%);
                        transition: $transition;
                    }
                    &::before {
                        width: 22px;
                        height: 22px;
                        background: #fff;
                        border: 2px solid #ccc;
                        border-radius: 2px;
                    }
                    &::after {
                        width: 10px;
                        height: 8px;
                        left: 57px;
                        background: url(/assets/img/common/icon_check.png)
                            center/contain no-repeat;
                        opacity: 0;
                    }
                }
                input[type="checkbox"]:checked ~ label {
                    background: rgba(#eaf5ff, 0.7);
                    &::before {
                        background: $c-accent;
                    }
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
        &__label {
            display: flex;
            flex-wrap: wrap;
            margin-top: -5px;
            &__item {
                font-size: 1.4rem;
                padding: 1px 5px;
                margin-right: 5px;
                margin-top: 5px;
                color: $c-accent;
                border: 1px solid $c-accent;
                min-width: 28px;
                text-align: center;
            }
        }
    }
    &__labelinfo {
        margin-top: 40px;
        &__list {
            margin-top: 7px;
            display: flex;
            flex-wrap: wrap;
            &__item {
                display: flex;
                align-items: center;
                margin-right: 20px;
                margin-top: 8px;
                &:last-child {
                    margin-right: 0;
                }
            }
            &__label {
                font-size: 1.4rem;
                padding: 1px 5px;
                margin-right: 5px;
                color: #002d78;
                border: 1px solid #002d78;
                min-width: 28px;
                text-align: center;
                display: inline-block;
                margin-right: 8px;
            }
            &__text {
                font-size: 1.4rem;
                color: $c-sub;
            }
        }
    }
    .js-sds-checkedlist,
    .js-result,
    .js-result-number {
        display: none;
    }
    .m-tab__container {
        .m-list--annotation {
            max-width: 600px;
            margin: 10px auto 0;
        }
    }
}

// 安全データシート　請求フォーム
.p-sds_form {
    &__backbtn {
        text-align: center;
        margin-top: 50px;
        &__link {
            position: relative;
            padding-left: 40px;
            transition: $transition;
            &::before,
            &::after {
                position: absolute;
                content: "";
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
            }
            &::before {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background: #fff;
                border: 1px solid #002d78;
            }
            &::after {
                background: url(/assets/img/common/icon_arrow_right.png)
                    center/contain no-repeat;
                width: 7px;
                height: 12px;
                transform: translate(-50%, -50%) rotate(-180deg);
                left: 14px;
                transition: $transition;
            }
            &:hover {
                color: $c-accent;
                &::after {
                    left: 10px;
                }
            }
        }
    }
    &__confirmbox {
        &.m-box {
            padding: 50px 40px;
            border-radius: 0;
            .m-btn--gradient {
                width: 400px;
                height: 70px;
                font-size: 1.8rem;
            }
            @include sp {
                margin-left: -15px;
                margin-right: -15px;
                .m-btn--gradient {
                    width: 100%;
                    height: 60px;
                    font-size: 1.6rem;
                }
            }
        }
    }
    // form画面
    &__selectlist {
        border-top: 1px solid #cccccc;
        &__item {
            border-bottom: 1px solid #cccccc;
            display: flex;
            &__txt {
                width: calc(100% - 280px);
                font-size: 1.6rem;
                font-weight: 500;
                display: flex;
                align-items: center;
            }
            &__btnwrap {
                width: 280px;
                background: #f5f6f9;
                padding: 15px 20px;
            }
        }
        &__btn {
            &__inner {
                background: #fff;
                padding: 14px 30px;
                border: 2px solid #dfdfe8;
                border-radius: 60px;
                font-size: 1.6rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #fff;
                transition: $transition;
                text-align: center;
                cursor: pointer;
                &:hover {
                    background: #dfdfe8;
                }
                &.active {
                    background: #dfdfe8;
                }
                .icon__pdf {
                    position: relative;
                    padding-left: 28px;
                    &::before {
                        position: absolute;
                        content: "";
                        width: 18px;
                        height: 17px;
                        top: 50%;
                        left: 0;
                        transform: translate(0, -50%);
                        background: url(/assets/img/common/icon_pdf.png)
                            center/contain no-repeat;
                    }
                }
                .size {
                    color: $c-sub;
                }
            }
        }
        @include sp {
            &__item {
                &__txt {
                    width: calc(100% - 115px);
                    font-size: 1.4rem;
                }
                &__btnwrap {
                    width: 115px;
                    padding: 12px 10px;
                }
            }
            &__btn {
                &__inner {
                    padding: 2px 10px;
                    font-size: 1.2rem;
                    flex-wrap: wrap;
                    .icon__pdf {
                        position: relative;
                        padding-left: 0;
                        display: block;
                        &::before {
                            display: inline-block;
                            position: inherit;
                            width: 14px;
                            height: 13px;
                            margin-right: 3px;
                            top: 1px;
                            left: 0;
                            transform: inherit;
                        }
                    }
                    .size {
                        display: block;
                    }
                }
            }
        }
    }

    .title_request.aform-input, .title_personal.aform-input, .title_office.aform-input, .title_product.aform-input {
        height: inherit;
        width: 100%;
        padding: inherit;
        border-radius: inherit;
        background: inherit;
        border: none;
        font-size: inherit;
        outline-color: inherit;
        @include sp {
            height: inherit;
            padding: inherit;
        }
    }
    .aform-header {
        display: none;
    }
    .title_request.aform-input, .title_personal.aform-input, .title_office.aform-input, .title_product.aform-input {
        font-size: 3rem;
        font-weight: 500;
        line-height: 1.4;
        border-top: 2px solid #eee;
        padding: 60px 0 0;
        position: relative;
        margin-top: 100px;
        &::before {
            position: absolute;
            content: "";
            display: block;
            top: -2px;
            left: 0;
            width: 150px;
            height: 4px;
            background: -webkit-linear-gradient(
                0deg,
                rgb(0, 45, 120) 0%,
                rgb(0, 86, 122) 50%,
                rgb(0, 130, 125) 100%
            );
        }
        + * {
            margin-top: 45px;
        }
        @include sp {
            font-size: 2.2rem;
            padding: 35px 0 0;
            margin-top: 60px;
            + * {
                margin-top: 30px;
            }
        }
    }

    .aform-required {
        position: relative;
        &::before {
            content: "＊";
            margin-left: 5px;
            font-size: 1.6rem;
            font-weight: 500;
            color: #e60e00;
        }
        font-size: 0;
    }
    .aform-label {
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 5px;
        display: block;
        margin-top: 30px;
        pointer-events: none;
    }
    .aform-input {
        height: 60px;
        width: 100%;
        padding: 0 20px;
        border-radius: 8px;
        background: #f5f6f9;
        border: 2px solid #d1d1d8;
        font-size: 1.6rem;
        outline-color: #002d78;
        margin-top: 5px;
        &.error {
            border: 2px solid #e60e00;
            background: #ffeeed;
        }
        @include sp {
            height: 50px;
            padding: 0 15px;
        }
    }
    .dl_name, .dl_name_kana {
        dd {
            display: flex;
            flex-wrap: wrap;
            .aform-name-ul, .aform-kana-ul {
                display: flex;
                width: 100%;
                li {
                    width: calc((100% - 30px)/2);
                    margin-right: 30px;
                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
                @include sp {
                    li {
                        width: calc((100% - 10px)/2);
                        margin-right: 10px;
                    }
                }
            }
            .aform-error {
                width: calc((100% - 30px)/2);
                margin-right: 30px;
                &:nth-of-type(2n) {
                    margin-right: 0px;
                }
            } 
        }
    }
    .dl_pref {
        dd {
            width: calc((100% - 30px)/2);
            margin-right: 30px;
            position: relative;
            &::after {
                position: absolute;
                content: "";
                top: 30px;
                right: 25px;
                width: 15px;
                height: 10px;
                background: url(/assets/img/common/icon_arrow_bottom.png) center/contain no-repeat;
                pointer-events: none;
            }
        }
        @include sp {
            dd {
                width: 100%;
                margin-right: 0;
                &::after {
                    top: 25px;
                }
            }
        }
    }
    .dl_comment {
        textarea {
            height: 200px;
            width: 100%;
            padding: 15px 20px;
            border-radius: 8px;
            background: #f5f6f9;
            border: 2px solid #d1d1d8;
            font-size: 1.6rem;
            outline-color: #002d78;
            @include sp {
                padding: 15px;
            }
        }
    }
    .aform-input-example, .aform-validation {
        font-size: 1.4rem;
        color: #666;
    }
    .aform-error {
        margin-top: 5px;
        pointer-events: none;
        font-size: 1.4rem;
        color: #e60e00;
    }
    
    .dl_zipcode, .dl_pref, .dl_tel_num, .dl_fax_num, .dl_product_name, .dl_unit_name{
        input {
            width: calc((100% - 30px)/2);
            margin-right: 30px;
        }
        @include sp {
            input {
                width: 100%;
                margin-right: 0;
            }
        }
    }
    .dl_file_ids, .dl_nm {
        padding: 30px 40px;
        border-radius: 10px;
        background: #f5f6f9;
        margin-top: 40px;
        dt {
            display: none;
        }
        dd {
            font-size: 1.6rem;
            line-height: 1.6;
        }
        @include sp {
            padding: 25px 20px;
            margin-top: 25px;
        }
    }
    .aform-button-area {
        width: 290px;
        margin: 50px auto 0;
        min-height: 56;
        border-radius: 60px;
        font-size: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: $transition;
        text-align: center;
        cursor: pointer;
        background: #fff;
        color: #fff;
        position: relative;
        overflow: hidden;
        &::before,
        &::after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &::before {
            background: -webkit-linear-gradient(
                0deg,
                rgb(87, 197, 207) 0%,
                rgb(58, 140, 220) 50%,
                rgb(39, 96, 191) 100%
            );
        }
        &::after {
            background: -webkit-linear-gradient(
                0deg,
                rgb(39, 96, 191) 0%,
                rgb(58, 140, 220) 50%,
                rgb(87, 197, 207) 100%
            );
            opacity: 0;
            transition: $transition;
        }
        &:hover {
            &::after {
                opacity: 1;
            }
        }
    }
    input[type="submit"] {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 14px 20px;
        border-radius: 60px;
        @include sp {            
            min-width: inherit;
            min-height: inherit;
            width: 100%;
        }
    }
    
}


// 確認画面
.p-sds_form_confirm {
    .aform-header {
        display: none;
    }
    .title_request, .title_personal, .title_office, .title_product {
        font-size: 3rem;
        font-weight: 500;
        line-height: 1.4;
        border-top: 2px solid #eee;
        padding: 60px 0 0;
        position: relative;
        margin-top: 100px;
        &::before {
            position: absolute;
            content: "";
            display: block;
            top: -2px;
            left: 0;
            width: 150px;
            height: 4px;
            background: -webkit-linear-gradient(
                0deg,
                rgb(0, 45, 120) 0%,
                rgb(0, 86, 122) 50%,
                rgb(0, 130, 125) 100%
            );
        }
        + * {
            margin-top: 45px;
        }
        @include sp {
            font-size: 2.2rem;
            padding: 35px 0 0;
            margin-top: 60px;
            + * {
                margin-top: 30px;
            }
        }
    }
    .dl_claim {
        padding: 30px 40px;
        border-radius: 10px;
        background: #f5f6f9;
        dt {
            display: none;
        }
        dd {
            font-size: 1.6rem;  
            line-height: 1.6;
        }
        @include sp {
            padding: 25px 20px;
        }
    }
    .aform-required {
        position: relative;
        &::before {
            content: "＊";
            margin-left: 5px;
            font-size: 1.6rem;
            font-weight: 500;
            color: #e60e00;
        }
        font-size: 0;
    }
    .aform-label {
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 5px;
        display: block;
        margin-top: 30px;
    }
    .aform-confirm {
        font-size: 1.6rem;
       line-height: 2;
    }
    #aform_confirm_msg {
        margin-top: 80px;
        padding: 50px 40px 30px;
        background: #f5f6f9;
        text-align: center;
        @include sp {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    .aform-button-area {
        padding: 0 40px 50px;
        background: #f5f6f9;
        display: flex;
        justify-content: center;
        @include sp {
            margin-left: -15px;
            margin-right: -15px;
            display: block;
        }
    }
    #aform_btn_back {
        min-width: 290px;
        min-height: 70px;
        padding: 14px 20px;
        border: 2px solid #dfdfe8;
        border-radius: 60px;
        font-size: 1.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: $transition;
        text-align: center;
        cursor: pointer;
        background: #fff;
        margin-right: 10px;
        &:hover {
            background: #dfdfe8;
        }
        @include sp {            
            min-width: inherit;
            min-height: inherit;
            width: 100%;
            height: 60px;
            margin: 0;
            font-size: 1.6rem;
        }
    }
    #aform_btn_submit {
        min-width: 290px;
        min-height: 70px;
        padding: 14px 20px;
        border-radius: 60px;
        font-size: 1.8rem;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: $transition;
        text-align: center;
        cursor: pointer;
        background: -webkit-linear-gradient(0deg, rgb(87, 197, 207) 0%, rgb(58, 140, 220) 50%, rgb(39, 96, 191) 100%);
        margin-left: 10px;
        &:hover {
            background: -webkit-linear-gradient(0deg, rgb(39, 96, 191) 0%, rgb(58, 140, 220) 50%, rgb(87, 197, 207) 100%);
        }
        @include sp {
            min-width: inherit;
            min-height: inherit;
            width: 100%;
            height: 60px;
            margin: 20px 0 0 0;
            font-size: 1.6rem;
        }
    }
}

// 完了画面
.p-sds_form_thanks {
    .aform-header {
        font-size: 3rem;
        font-weight: 500;
        line-height: 1.4;
        border-top: 2px solid #eee;
        padding: 60px 0 0;
        position: relative;
        margin-top: 100px;
        &::before {
            position: absolute;
            content: "";
            display: block;
            top: -2px;
            left: 0;
            width: 150px;
            height: 4px;
            background: -webkit-linear-gradient(
                0deg,
                rgb(0, 45, 120) 0%,
                rgb(0, 86, 122) 50%,
                rgb(0, 130, 125) 100%
            );
        }
        + * {
            margin-top: 45px;
        }
        @include sp {
            font-size: 2.2rem;
            padding: 35px 0 0;
            margin-top: 60px;
            + * {
                margin-top: 30px;
            }
        }
    }
    #aform_result {
        background: #f5f6f9;
        padding: 30px 40px;
        border-radius: 10px;
        a {
            width: 290px;
            margin: 30px auto 0;
            min-height: 56;
            padding: 14px 20px;
            border: 2px solid #dfdfe8;
            border-radius: 60px;
            font-size: 1.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: $transition;
            text-align: center;
            cursor: pointer;
            background: #fff;
            &:hover {
                background: #dfdfe8;
            }
            @include sp {            
                min-width: inherit;
                min-height: inherit;
                width: 100%;
            }
        }
        @include sp {
            padding: 25px 20px;
        }
    }
    .dl_lists {
        border-bottom: 1px solid #ccc;
        font-size: 1.6rem;
        font-weight: 500;
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:first-child {
            border-top: 1px solid #ccc;
        }
        .sds_id {
            width: calc(100% - 260px);
        }
        a {
            width: 240px;
            padding: 14px 30px;
            padding-left: 50px;
            border: 2px solid #dfdfe8;
            border-radius: 60px;
            font-size: 1.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            transition: $transition;
            text-align: center;
            cursor: pointer;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                width: 18px;
                height: 17px;
                top: 50%;
                left: 24px;
                -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
                background: url(/assets/img/common/icon_pdf.png) center/contain no-repeat;
            }
            &:hover {
                background: #dfdfe8;
            }
        }
        @include sp {
            .sds_id {
                width: calc(100% - 100px);
            }
            a {
                width: 95px;
                font-size: 1.2rem;
                padding: 2px 10px;
                padding-left: 24px;
                &::before {
                    width: 14px;
                    height: 13px;
                    left: 10px;
                }
            }
        }
    }
    #form_dl_zip {
        width: 290px;
        margin: 30px auto 0;
        min-height: 56;
        padding: 14px 20px;
        border-radius: 60px;
        font-size: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: $transition;
        text-align: center;
        cursor: pointer;
        background: #fff;
        color: #fff;
        position: relative;
        overflow: hidden;
        &::before,
        &::after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &::before {
            background: -webkit-linear-gradient(
                0deg,
                rgb(87, 197, 207) 0%,
                rgb(58, 140, 220) 50%,
                rgb(39, 96, 191) 100%
            );
        }
        &::after {
            background: -webkit-linear-gradient(
                0deg,
                rgb(39, 96, 191) 0%,
                rgb(58, 140, 220) 50%,
                rgb(87, 197, 207) 100%
            );
            opacity: 0;
            transition: $transition;
        }
        &:hover {
            &::after {
                opacity: 1;
            }
        }
    }
    #dl_all {
        padding-left: 28px;
        position: relative;
        z-index: 1;
        &::before {
            position: absolute;
            content: "";
            width: 18px;
            height: 18px;
            top: 50%;
            left: 0px;
            transform: translate(0, -50%);
            background: url(/assets/img/common/icon_dl_white.png) center/contain no-repeat;
        }
        @include sp {            
            min-width: inherit;
            min-height: inherit;
            width: 100%;
        }
    }
}