@use "sass:math";
@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

.l-header * {
    font-family: "Noto Sans JP","Yu Gothic","Yu Gothic Medium","游ゴシック Medium",YuGothic,"游ゴシック体","ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
    line-height: 1.5;
    letter-spacing: 0.05rem;
}
.l-header {
    height: 90px;
    display: flex;
    background: #fff;
    align-items: center;
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    transition: $transition;
    img {
        width: 100%;
        height: auto;
        image-rendering: -webkit-optimize-contrast;
        vertical-align: bottom;
    }
    @include pc {
        &.is-fixed--scroll {
            max-height: 70px;
            .l-header__logo__link {
                max-width: 100px;
            }
            .l-header__nav {
                width: calc(100% - 340px);
                &__main {
                    padding-top: 0;
                    .l-header__list__link {
                        display: flex;
                        align-items: center;
                        font-size: 1.4rem;
                    }
                }
                &__sub {
                    display: none;
                }
            }
            .l-header__contact {
                width: 120px;
                &__link {
                    img {
                        width: 22px;
                    }
                    &__txt {
                        margin-top: 5px;
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
    &__logo {
        width: 170px;
        padding-left: 30px;
        margin-right: 50px;
        &__link {
            display: block;
        }
    }
    &__nav {
        width: calc(100% - 350px);
        padding-right: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        height: 100%;
        &__sub {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            .l-header__list {
                display: flex;
                align-items: center;
                &__item {
                    &:nth-child(n + 2) {
                        margin-left: 20px;
                    }
                    &.search {
                        margin-left: 10px;
                        margin-right: -10px;
                        display: flex;
                        align-items: center;
                        &.active {
                            .l-header__list__link {
                                &::before {
                                    opacity: 0;
                                }
                                &::after {
                                    opacity: 1;
                                }
                            }
                        }
                        .l-header__list__link {
                            padding: 10px;
                            width: 36px;
                            display: inline-block;
                            position: relative;
                            vertical-align: middle;
                            &::before, &::after {
                                position: absolute;
                                content: '';
                                display: block;
                                top: 50%;
                                right: 50%;
                                width: 16px;
                                height: 16px;
                                transform: translate(50%, -50%);
                                transition: $transition;
                            }
                            &::before {
                                background: url(/assets/img/common/icon_header_search.png) center/contain no-repeat;
                                opacity: 1;
                            }
                            &::after {
                                background: url(/assets/img/common/icon_header_search_hover.png) center/contain no-repeat;
                                opacity: 0;
                            }
                            @include pc {
                                &:hover {
                                    &::before {
                                        opacity: 0;
                                    }
                                    &::after {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
                &__link {
                    color: #444444;
                    font-size: 1.3rem;
                    transition: $transition;
                    cursor: pointer;
                    @include pc {
                        &:hover {
                            color: $c-accent;
                            .newwindow, .global {
                                &::before {
                                    opacity: 0;
                                }
                                &::after {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    .newwindow {
                        position: relative;
                        padding-right: 18px;
                        &::before, &::after {
                            position: absolute;
                            content: '';
                            display: block;
                            top: 50%;
                            right: 0;
                            width: 11px;
                            height: 11px;
                            transform: translate(0, -50%);
                            transition: $transition;
                        }
                        &::before {
                            background: url(/assets/img/common/icon_newwindow.png) center/contain no-repeat;
                            opacity: 1;
                        }
                        &::after {
                            background: url(/assets/img/common/icon_newwindow_hover.png) center/contain no-repeat;
                            opacity: 0;
                        }
                    }
                    .global {
                        position: relative;
                        padding-left: 22px;
                        &::before, &::after {
                            position: absolute;
                            content: '';
                            display: block;
                            top: 50%;
                            left: 0;
                            width: 17px;
                            height: 17px;
                            transform: translate(0, -50%);
                            transition: $transition;
                        }
                        &::before {
                            background: url(/assets/img/common/icon_global.png) center/contain no-repeat;
                            opacity: 1;
                        }
                        &::after {
                            background: url(/assets/img/common/icon_global_hover.png) center/contain no-repeat;
                            opacity: 0;
                        }
                    }
                }
            }
        }
        &__main {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            padding-top: 5px;
            .l-header__list {
                display: flex;
                &__item {
                    height: 100%;
                    &:nth-child(n + 2) {
                        margin-left: 25px;
                    }
                    &.active {
                        .l-header__list__link {
                            color: $c-accent;
                            border-bottom: 3px solid $c-accent;
                        }
                    }
                }
                &__link {
                    font-size: 1.5rem;
                    font-weight: bold;
                    display: block;
                    height: 100%;
                    transition: $transition;
                    border-bottom: 3px solid transparent;
                    position: relative;
                    z-index: 10;
                    @include pc {
                        &.current {
                            color: $c-accent;
                            border-bottom: 3px solid $c-accent;
                        }
                        &:hover {
                            color: $c-accent;
                            border-bottom: 3px solid $c-accent;
                        }
                    }
                }
            }
        }
    }
    &__contact {
        width: 130px;
        height: 100%;
        &__link {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &::before, &::after {
                position: absolute;
                content: '';
                display: block;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 0;
                transition: $transition;
            }
            &::before {
                background-image: -webkit-linear-gradient( 75deg, rgb(1,60,136) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);
                opacity: 1;
            }
            &::after {
                background-image: -webkit-linear-gradient( 75deg, rgb(1,129,140) 0%, rgb(0,93,136) 50%, rgb(1,60,136) 100%);
                opacity: 0;
            }
            img {
                width: 26px;
                position: relative;
                z-index: 1;
            }
            &__txt {
                margin-top: 10px;
                text-align: center;
                display: block;
                color: #fff;
                font-weight: bold;
                font-size: 1.5rem;
                position: relative;
                z-index: 1;
            }
            @include pc {
                &:hover {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }
    @include pcmin1280 {
        &.is-fixed--scroll {
            max-height: vw(70px);
            .l-header__logo__link {
                max-width: vw(100px);
            }
            .l-header__nav {
                width: calc(100% - vw(340px));
            }
            .l-header__nav__main {
                .l-header__list__link {
                    font-size: vw(14px);
                }
            }
            .l-header__contact {
                width: vw(120px);
                &__link {
                    img {
                        width: vw(22px);
                    }
                    &__txt {
                        margin-top: vw(5px);
                        font-size: vw(13px);
                    }
                }
            }
        }
        height: vw(90px);
        &__logo {
            width: vw(170px);
            padding-left: vw(30px);
            margin-right: vw(50px);
        }
        &__nav {
            width: calc(100% - vw(350px));
            padding-right: vw(30px);
            &__sub {
                .l-header__list {
                    &__item {
                        &:nth-child(n + 2) {
                            margin-left: vw(25px);
                        }
                        &.search {
                            margin-left: vw(10px);
                            margin-right: vw(-10px);
                            .l-header__list__link {
                                padding: vw(10px);
                                width: vw(36px);
                                height: vw(20px);
                                &::before, &::after {
                                    width: vw(16px);
                                }
                            }
                        }
                    }
                    &__link {
                        font-size: vw(13px);
                        .newwindow {
                            padding-right: vw(18px);
                            &::before, &::after {
                                width: vw(11px);
                                height: vw(11px);
                            }
                        }
                        .global {
                            padding-left: vw(22px);
                            &::before, &::after {
                                width: vw(17px);
                                height: vw(17px);
                            }
                        }
                    }
                }
            }
            &__main {
                padding-top: vw(5px);
                .l-header__list {
                    &__item {
                        &:nth-child(n + 2) {
                            margin-left: vw(20px);
                        }
                        &.active {
                            .l-header__list__link {
                                border-bottom: vw(3px) solid $c-accent;
                            }
                        }
                    }
                    &__link {
                        font-size: vw(15px);
                        @include pc {
                            &:hover {
                                border-bottom: vw(3px) solid $c-accent;
                            }
                        }
                    }
                }
            }
        }
        &__contact {
            width: vw(130px);
            &__link {
                img {
                    width: vw(26px);
                }
                &__txt {
                    margin-top: vw(10px);
                    font-size: vw(15px);
                }
            }
        }
    }
    @include sp {
        height: 60px;
        &__logo {
            width: 90px;
            padding-left: 15px;
            margin-right: 15px;
        }
        &__nav {
            position: absolute;
            background: #fff;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            height: 0vh;
            overflow: hidden;
            padding: 0 15px 0;
            justify-content: flex-start;
            transition: $transition;
            &.active {
                height: 100vh;
                min-height: 100vh;
                min-height: -webkit-fill-available;
                height: calc(var(--vh, 1vh) * 100);
                overflow-y: scroll;
                padding: 0 15px 130px;
            }
            &__sub {
                order: 2;
                width: 100%;
                display: block;
                padding: 30px 0 0;
                .l-header__list {
                    display: block;
                    &__item {
                        &:nth-child(n + 2) {
                            margin-left: 0;
                        }
                        &.is-sp {
                            .l-header__list__link {
                                color: #888888;
                            }
                        }
                        &.search {
                            margin-top: 30px;
                            width: 100%;
                        }
                    }
                    &__link {
                        padding: 15px 10px;
                        display: block;
                        font-size: 1.4rem;
                        .newwindow {
                            position: relative;
                            padding-right: 20px;
                            &::before, &::after {
                                width: 13px;
                                height: 12px;
                            }
                        }
                    }
                }
            }
            &__main {
                order: 1;
                display: block;
                width: 100%;
                padding-top: 0;
                .l-header__list {
                    display: block;
                    &__item {
                        height: auto;
                        border-bottom: 1px solid #dddddd;
                        &:nth-child(n + 2) {
                            margin-left: 0;
                        }
                        &.active {
                            &.js-dropdown {
                                .l-header__list__link {
                                    color: $c-accent;
                                    border-bottom: none;
                                    &::after {
                                        opacity: 1;
                                    }
                                    &::before {
                                        opacity: 0;
                                    }
                                }
                            }
                        }
                        &.js-dropdown {
                            .l-header__list__link {
                                pointer-events: none;
                                &::before, &::after {
                                    content: "";
                                    position: absolute;
                                    top: 50%;
                                    right: 15px;
                                    transform: translate(0, -50%);
                                    transition: $transition;
                                }
                                &::after {
                                    width: 17px;
                                    height: 1px;
                                    background: $c-accent;
                                    opacity: 0;
                                }
                                &::before {
                                    width: 17px;
                                    height: 17px;
                                    background: url(/assets/img/common/icon_plus.png) center/contain no-repeat;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    &__link {
                        font-size: 1.6rem;
                        font-weight: normal;
                        padding: 20px 10px;
                        border-bottom: none;
                        position: relative;
                        &::after {
                            content: "";
                            position: absolute;
                            width: 7px;
                            height: 12px;
                            top: 50%;
                            right: 20px;
                            transform: translate(0, -50%);
                            background: url(/assets/img/common/icon_arrow_right.png) center/contain no-repeat;
                        }
                    }
                }
            }
        }
        &__global {
            width: 60px;
            height: 100%;
            margin-left: auto;
            &__link {
                padding-top: 5px;
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: #f4f4f8;
                cursor: pointer;
                img {
                    width: 22px;
                    position: relative;
                    z-index: 1;
                }
                &__txt {
                    margin-top: 6px;
                    text-align: center;
                    display: block;
                    color: #444444;
                    font-weight: 500;
                    font-size: 0.9rem;
                    position: relative;
                    z-index: 1;
                }
            }
        }
        &__contact {
            width: 68px;
            &__link {
                padding-top: 5px;
                img {
                    width: 22px;
                    height: 22px;
                    object-fit: contain;
                }
                &__txt {
                    margin-top: 6px;
                    font-size: 0.9rem;
                    font-weight: 500;
                }
            }
        }
        .l-spMenu {
            position: absolute;
            width: 70px;
            height: 60px;
            cursor: pointer;
            position: relative;
            transition: $transition;
            > span {
                position: absolute;
                width: 28px;
                height: 2px;
                background-color: $c-base;
                left: 21px;
                display: inline-block;
                transition: $transition;
                &:nth-of-type(1) {
                    top: calc(50% - 8px);
                }
                &:nth-of-type(2) {
                    top: 50%;
                }
                &:nth-of-type(3) {
                    top: calc(50% + 8px);
                }
            }
            &.active {
                transform: rotate(180deg);
                > span {
                    &:nth-of-type(1) {
                        transform: translateY(8px) rotate(-45deg);                    }
                    &:nth-of-type(2) {
                        transform: translateY(0) rotate(45deg);                  }
                    &:nth-of-type(3) {
                        opacity: 0;
                    }
                }
            }
        }
    }
    // サイト内検索
    .l-search {
        background: #f4f4f8;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 1;
        transition: $transition;
        height: 0;
        overflow: hidden;
        &.active {
            border-top: 1px solid #cccccc;
        }
        &__close {
           position: absolute;
           top: 0;
           right: 0;
           width: 50px;
           height: 50px;
           padding: 15px;
           z-index: 5;
           cursor: pointer;
           img {
                vertical-align: inherit;
           }
        }
        &__inner {
            max-width: 1200px;
            margin: 0 auto;
            padding: 40px 0;
            background: #f4f4f8;
            position: relative;
            z-index: 1;
        }
        &__input {
            max-width: 600px;
            height: 54px;
            font-size: 1.6rem;
            margin: 0 auto;
            position: relative;
            &__item {
                width: 100%;
                height: 100%;
                background: #fff;
                border-radius: 6px;
                border: 2px solid #d1d1d8;
                padding: 5px 54px 5px 15px;
                outline: none;
                letter-spacing: 0.15rem;
            }
        }
        &__submit {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            font-size: 0;
            width: 54px;
            height: 100%;
            margin-left: 5px;
            &__item {
                width: 100%;
                height: 100%;
                border-radius: 0 6px 6px 0;
            }
            &::after {
                position: absolute;
                content: '';
                top: 50%;
                right: 50%;
                width: 22px;
                height: 22px;
                transform: translate(50%, -50%);
                background: url(/assets/img/common/icon_search.png) center/contain no-repeat;
                pointer-events: none;
                transition: $transition;
            }
            @include pc {
                &:hover {
                    &::after {
                        transform: translate(50%, -50%) scale(1.1);
                    }
                }
            }
        }
        // .gsc-control-searchbox-only {
        //     max-width: 600px;
        //     height: 54px;
        //     font-size: 1.6rem;
        //     margin: 0 auto;
        //     position: relative;
        //     tr {
        //         background: #fff;
        //     }
        //     .gsc-input-box {
        //         border: none;
        //     }
        //     .gsib_a {
        //         padding: 0;
        //     }
        //     .gsib_b {
        //         display: none;
        //     }
        //     input {
        //         padding: 5px 15px 5px 15px !important;
        //     }
        //     form.gsc-search-box {
        //         margin-bottom: 0;
        //         border-radius: 6px;
        //         overflow: hidden;
        //         border: 2px solid #ccc;
        //     }
        //     table {
        //         &.gsc-search-box {
        //             height: 54px;
        //             margin-bottom: 0;
        //             td.gsc-input {
        //                 border-right: 0;    
        //             }
        //         }
        //     } 
        //     .gsc-search-button-v2, .gsc-search-button-v2:hover, .gsc-search-button-v2:focus {
        //         background: none;
        //         border: none;
        //         background: orange;
        //         svg {
        //             fill: $c-accent;
        //         }
        //     }
        //     // .gsib_a {
        //     //     width: 100%;
        //     //     height: 100%;
        //     //     background: #fff;
        //     //     border-radius: 6px;
        //     //     border: 2px solid #d1d1d8;
        //     //     padding: 5px 54px 5px 15px;
        //     //     outline: none;
        //     //     letter-spacing: .15rem;
        //     // }
        // }
        @include pcmin1200 {
            &__close {
            width: vwm(50px);
            height: vwm(50px);
            padding: vwm(15px);
            }
            &__inner {
                padding: vwm(40px) 0;
            }
            &__input {
                max-width: vwm(600px);
                height: vwm(54px);
                font-size: vwm(16px);
                &__item {
                    border-radius: vwm(6px);
                    border: vwm(2px) solid #d1d1d8;
                    padding: vwm(5px) vwm(65px) vwm(5px) vwm(15px);
                    letter-spacing: vwm(2px);
                }
            }
            &__submit {
                width: vwm(54px);
                margin-left: vwm(5px);
                &__item {
                    border-radius: 0 vwm(6px) vwm(6px) 0;
                }
                &::after {
                    width: vwm(22px);
                    height: vwm(22px);
                }
            }
        }
        @include sp {
            display: none;
        }
    }
    // DropDownMenu
    .l-dropdown {
        background: #fff;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 999;
        height: 0;
        opacity: 0;
        transition: opacity 1s ease, height .5s ease, overflow 1s ease;
        max-height: calc(80vh - 90px);
        overflow: hidden;
        overflow-y: auto;
        -ms-overflow-style: none;   /* IE, Edge 対応 */
        scrollbar-width: none;  /* Firefox 対応 */
        &::-webkit-scrollbar {  /* Chrome, Safari 対応 */
            display:none;
        }
        &.active {
            opacity: 1;
            border-top: 1px solid #cccccc;
        }
        &--scroll {
            display: none;
            background-image: -webkit-linear-gradient( 90deg, rgb(255,255,255) 0%, rgba(255,255,255,0.8) 48%, rgba(255,255,255,0) 100%);
            width: 100%;
            height: 140px;
            z-index: 1;
            position: fixed;
            top: calc(80vh - 140px);
            pointer-events: none;
            &.scrollheader {
                top: calc(80vh - 160px);
            }
            &.active {
                display: block;
            }
            &__text {
                text-align: center;
                color: $c-accent;
                position: absolute;
                bottom: 45px;
                left: 0;
                right: 0;
                animation:mousemove 1.6s ease-in-out infinite;
                &::after {
                    position: absolute;
                    content: '';
                    width: 16px;
                    height: 16px;
                    background: url(/assets/img/common/icon_scroll_arrow.png) center/contain no-repeat;
                    top: 35px;
                    left: 50%;
                    transform: translate(0, - 50%);
                }
            }
        }
        @keyframes mousemove{
            0%{bottom:40px;}
            50%{bottom:45px;}
           100%{bottom:40px;}
        }      
        &__inner {
            max-width: 1200px;
            margin: 0 auto;
            padding: 40px 30px;
            background: #fff;
            position: relative;
            z-index: 1;
            &::after {
                position: absolute;
                content: '';
                top: 0;
                left: 100%;
                right: 0;
                bottom: 0;
                width: 50%;
                background: #f4f4f8;
            }
        }
        &__ttl {
            font-size: 2.4rem;
            letter-spacing: 0.15rem;
            margin-bottom: 40px;
            > a {
                position: relative;
                display: inline-block;
                margin-right: 40px;
                &::before {
                    content: '';
                    position: absolute;
                    width: 28px;
                    height: 28px;
                    border: 1px solid $c-accent;
                    border-radius: 50%;
                    top: 50%;
                    right: -40px;
                    transform: translateY(-50%);
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 7px;
                    height: 10px;
                    top: 50%;
                    right: -30px;
                    transform: translateY(-50%);
                    background: url(/assets/img/common/icon_circle_arrow_right.png) center/contain no-repeat;
                    transition: $transition;
                }
                span {
                    background: $c-accent;
                    background: -webkit-linear-gradient( 45deg, rgb(1,60,136) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);
                    background-clip: text;
                    color: transparent;
                    @include link-underline {
                        &:before {
                            background: $c-accent;
                            background: -webkit-linear-gradient( 45deg, rgb(1,60,136) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);    
                        }
                    }
                }
                @include pc {
                    &:hover {
                        &::after {
                            right: -34px;
                        }
                    }
                }
            }
        }
        &__ttl--smaller {
            font-size: 1.6rem;
            letter-spacing: 0.15rem;
            margin: 25px 0 25px;
            font-weight: 500;
            > a {
                color: $c-accent;
                position: relative;
                display: inline-block;
                padding-right: 32px;
                &::before {
                    content: '';
                    position: absolute;
                    width: 22px;
                    height: 22px;
                    border: 1px solid $c-accent;
                    border-radius: 50%;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 8px;
                    top: 50%;
                    right: 7px;
                    transform: translateY(-50%);
                    background: url(/assets/img/common/icon_circle_arrow_right.png) center/contain no-repeat;
                    transition: $transition;
                }
                @include pc {
                    &:hover {
                        &::after {
                            right: 3px;
                        }
                    }
                }
            }
        }
        &__subttl {
            font-size: 1.5rem;
            font-weight: 500;
            margin: 0 0 15px;
            color: $c-accent;
            &__link {
                @include link-underline {
                    &::before {
                      background: $c-accent;
                    }
                }
            }
        }
        &__imgbox {
            display: flex;
            flex-wrap: wrap;
            &__item {
                width: calc((100% - 84px) / 4);
                margin-right: 28px;
                position: relative;
                &:nth-child(4n) {
                    margin-right: 0;
                }
                &:nth-child(n + 5) {
                    margin-top: 30px;
                }
                &__img {
                    overflow: hidden;
                    img {
                        transition: $transition;
                    }
                 }
                 &__txt {
                     text-align: center;
                     font-size: 1.5rem;
                     margin-top: 15px;
                     transition: $transition;
                 }
                 &__link {
                     position: absolute;
                     content: '';
                     top: 0;
                     left: 0;
                     right: 0;
                     bottom: 0;
                 }
                @include pc {
                    &:hover {
                        .l-dropdown__imgbox__item__img {
                            img {
                                transform: scale(1.1);
                            } 
                         }
                         .l-dropdown__imgbox__item__txt {
                             color: $c-accent;
                         }
                    }
                    &:has(.l-dropdown__menu-list__wrap) {
                        .l-dropdown__imgbox__item__link {
                            position: relative;
                        }
                    }
                    &__noimg {
                        margin-top: 180px;
                        .l-dropdown__menu-list__wrap {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        &__col3 {
            display: flex;
            &__item {
                margin-right: 20px;
                &:nth-child(1) {
                    width: 40%;
                }
                &:nth-child(2) {
                    width: 27%;
                }
                &:nth-child(3) {
                    width: 33%;
                }
                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
        &__menu-list {
            &__item {
                &:nth-child(n + 2) {
                    margin-top: 20px;
                }
            }
            &__link {
                padding-left: 15px;
                font-size: 1.5rem;
                transition: $transition;
                position: relative;
                display: inline-block;
                &::before {
                    content: '';
                    position: absolute;
                    width: 7px;
                    height: 10px;
                    top: 6px;
                    left: 0px;
                    background: url(/assets/img/common/icon_arrow_right.png) center/contain no-repeat;
                }
                .newwindow {
                    position: relative;
                    padding-right: 18px;
                    &::after {
                        position: absolute;
                        content: '';
                        display: block;
                        bottom: 0;
                        right: 0;
                        width: 11px;
                        height: 11px;
                        transform: translate(0, -50%);
                        transition: $transition;
                        background: url(/assets/img/common/icon_newwindow.png) center/contain no-repeat;
                        opacity: 1;
                    }
                }
                @include pc {
                    &:hover {
                        color: $c-accent;
                    }
                }
            }
            @include pc {
                &__wrap {
                    margin-top: 40px;
                }
            }
        }
        &__menu-list--col2 {
            display: flex;
            flex-wrap: wrap;
            .l-dropdown__menu-list {
                &__item {
                    width: calc((100% - 40px) / 2);
                    margin-right: 40px;
                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                    &:nth-child(n + 2) {
                        margin-top: inherit;
                    }
                    &:nth-child(n + 3) {
                        margin-top: 20px;
                    }
                }
            }
        }
        &__menu-list--sub {
            .l-dropdown__menu-list {
                &__item {
                    &:nth-child(n + 2) {
                        margin-top: 15px;
                    }
                }
                &__link {
                    font-size: 1.4rem;
                    color: #444444;
                    line-height: 1.4;
                    &::before {
                        content: '';
                        width: 6px;
                        height: 1px;
                        background: #999999;
                        top: 10px;
                        left: 0px;
                    }
                    @include pc {
                        &:hover {
                            color: $c-accent;
                        }
                    }
                }
            }
        }
        &__card--col4 {
            margin-bottom: 30px;
            display: flex;
            flex-wrap: wrap;
            .l-dropdown__card {
                &__item {
                    width: 100%;
                    background: #fff;
                    border-radius: 6px;
                    box-shadow: $boxshadow;
                    padding: 0 10px;
                    min-height: 60px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    overflow: hidden;
                    transition: $transition;
                    &:nth-child(n + 2) {
                        margin-top: 15px;
                    }
                    &__img {
                        width: 40px;
                        height: 40px;
                    }
                    &__txt {
                        width: calc(100% - 50px);
                        margin-left: 10px;
                        font-size: 1.4rem;
                        transition: $transition;
                    }
                    &__link {
                        position: absolute;
                        content: '';
                        display: block;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                    }
                    @include pc {
                        &:hover {
                            box-shadow: none;
                            .l-dropdown__card__item__txt {
                                color: $c-accent;
                            }
                        }
                    }
                }
            }
        }
        &__btn {
            margin-bottom: 30px;
            a {
                background: #fff;
                padding: 15px;
                border-radius: 6px;
                display: inline-block;
                width: 100%;
                font-size: 1.4rem;
                text-align: center;
                box-shadow: $boxshadow;
                transition: $transition;
                span {
                    position: relative;
                    display: inline-block;
                    padding-left: 43px;
                    &::before {
                        position: absolute;
                        content: '';
                        top: 50%;
                        left: 0;
                        transform: translate(0, -50%);
                        width: 34px;
                        height: 34px;
                        background: url(/assets/img/common/icon_sds.png) center/contain no-repeat;
                    }
                }
                @include pc {
                    &:hover {
                        box-shadow: none;
                        color: $c-accent;
                    }
                }
            }
        }
        .l-dropdown--products, .l-dropdown--corporate {
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            .l-dropdown__left {
                width: 79.2%;
                padding: 40px 60px 50px 30px;
                background: #fff;
            }
            .l-dropdown__right {
                width: 20.8%;
                padding: 40px 30px 50px 40px;
                background: #f4f4f8;
                .l-dropdown__imgcard {
                    border-radius: 6px;
                    box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.12);
                    width: 180px;
                    height: 180px;
                    margin-bottom: 30px;
                    overflow: hidden;
                    img {
                        object-fit: cover;
                        transition: $transition;
                    }
                    &__link {
                        &:hover {
                            img {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
        .l-dropdown--products {
            .l-dropdown__left {
                width: 72%;
            }
            .l-dropdown__right {
                width: 28%;
            }
            .l-dropdown__ttl {
                margin-bottom: 30px;
            }
            .l-dropdown__menu-list {
                &__item {
                    &:nth-child(n + 2) {
                        margin-top: 10px;
                    }
                }
                &__link {
                    font-size: 1.4rem;
                }
            }
            .m-tabtext {
                position: relative;
                &__index {
                    display: flex;
                    overflow-x: auto;
                    width: 100%;
                    border-bottom: 1px solid #ccc;
                    &__item {
                        font-size: 1.6rem;
                        color: #888888;
                        padding-bottom: 7px;
                        cursor: pointer;
                        white-space: nowrap;
                        transition: $transition;
                        &:nth-child(n + 2) {
                            margin-left: 30px;
                        }
                        &:hover {
                            color: $c-accent;
                        }
                        &.active {
                            font-weight: bold;
                            color: $c-accent;
                            border-bottom: 3px solid #0055a5;
                            pointer-events: none;
                        }
                    }
                }
                &__content {
                    margin-top: 25px;
                    &__item {
                        display: none;
                        &.active {
                            display: block;
                        }
                    }
                }
            }
        }
        .l-dropdown--service {
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            .l-dropdown__left {
                width: 70%;
                padding: 40px 60px 50px 30px;
                background: #fff;
            }
            .l-dropdown__right {
                width: 30%;
                padding: 40px 30px 50px 40px;
                background: #f4f4f8;
            }
            &__inner {
                display: flex;
                flex-wrap: wrap;
                .l-dropdown__bnr {
                    width: 60%;
                    &__item {                    
                        position: relative;
                        &:not(:first-child) {
                            margin-top: 30px;
                        }
                        @include pc {
                            &:hover {
                                .l-dropdown__bnr__img {
                                    img {
                                        transform: scale(1.1);
                                    }
                                }
                                .l-dropdown__bnr__txt {
                                    color: $c-accent;
                                }
                            }
                        }
                    }
                    &__img {
                        overflow: hidden;
                        img {
                            transition: $transition;
                        }
                    }
                    &__txt {
                        text-align: center;
                        margin-top: 15px;
                        font-size: 1.5rem;
                        transition: $transition;
                    }
                    &__link {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }
                }
                .l-dropdown__menu-list {
                    width: calc(40% - 40px);
                    margin-left: 40px;
                    &__item {
                        &:nth-child(2) {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        .l-dropdown--solution {
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            .l-dropdown__left {
                width: 70%;
                padding: 40px 60px 50px 30px;
                background: #fff;
            }
            .l-dropdown__right {
                width: 30%;
                padding: 40px 30px 50px 40px;
                background: #f4f4f8;
            }
            &__inner {
                display: flex;
                flex-wrap: wrap;
                .l-dropdown__bnr {
                    width: 60%;
                    &__item {                    
                        position: relative;
                        &:not(:first-child) {
                            margin-top: 30px;
                        }
                        @include pc {
                            &:hover {
                                .l-dropdown__bnr__img {
                                    img {
                                        transform: scale(1.1);
                                    }
                                }
                                .l-dropdown__bnr__txt {
                                    color: $c-accent;
                                }
                            }
                        }
                    }
                    &__img {
                        overflow: hidden;
                        img {
                            transition: $transition;
                        }
                    }
                    &__txt {
                        text-align: center;
                        margin-top: 15px;
                        font-size: 1.5rem;
                        transition: $transition;
                    }
                    &__link {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }
                }
                .l-dropdown__menu-list {
                    width: calc(40% - 40px);
                    margin-left: 40px;
                    &__item {
                        &:nth-child(2) {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        // 医用機器でだけ表示するメニュー
        @include pc {
            .js-onlyshow-medical {
                display: none;
            }
        }
        @include pcmin1200 {
            max-height: calc(80vh - vwm(90px));
            &--scroll {
                height: vwm(140px);
                top: calc(80vh - vwm(140px));
                &.scrollheader {
                    top: calc(80vh - vwm(160px));
                }
                &__text {
                    font-size: vwm(16px);
                    bottom: vwm(45px);
                    &::after {
                        width: vwm(16px);
                        height: vwm(16px);
                        top: vwm(35px);
                    }
                }
            }
            &__inner {
                padding: vwm(40px) vwm(30px);
            }
            &__ttl {
                font-size: vwm(24px);
                letter-spacing: vwn(2px);
                margin-bottom: vwm(40px);
                > a {
                    margin-right: vwm(40px);
                    &::before {
                        width: vwm(28px);
                        height: vwm(28px);
                        right: vwm(-40px);
                    }
                    &::after {
                        width: vwm(7px);
                        height: vwm(10px);
                        right: vwm(-30px);
                    }
                    @include pc {
                        &:hover {
                            &::after {
                                right: vwm(-34px);
                            }
                        }
                    }
                }
            }
            &__ttl--smaller {
                font-size: vwm(16px);
                letter-spacing: vwn(2px);
                margin: vwm(25px) 0 vwm(25px);
                > a {
                    padding-right: vwm(32px);
                    &::before {
                        width: vwm(22px);
                        height: vwm(22px);
                    }
                    &::after {
                        width: vwm(6px);
                        height: vwm(8px);
                        right: vwm(7px);
                    }
                    @include pc {
                        &:hover {
                            &::after {
                                right: vwm(3px);
                            }
                        }
                    }
                }
            }
            &__subttl {
                font-size: vwm(16px);
                margin-bottom: vwm(15px);
            }
            &__imgbox {
                &__item {
                    width: calc((100% - vwm(84px)) / 4);
                    margin-right: vwm(28px);
                    &:nth-child(n + 5) {
                        margin-top: vwm(30px);
                    }
                    &__txt {
                         font-size: vwm(15px);
                         margin-top: vwm(15px);
                    }
                }
            }
            &__col3 {
                &__item {
                    margin-right: vwm(20px);
                }
            }
            &__card--col4 {
                margin-bottom: vwn(30px);
                .l-dropdown__card {
                    &__item {
                        border-radius: vwm(6px);
                        padding: 0 vwm(10px);
                        min-height: vwm(60px);
                        &:nth-child(n + 2) {
                            margin-top: vwm(15px);
                        }
                        &__img {
                            width: vwm(40px);
                            height: vwm(40px);
                        }
                        &__txt {
                            width: calc(100% - vwm(50px));
                            margin-left: vwm(10px);
                            font-size: vwm(14px);
                        }
                    }
                }
            }
            &__menu-list {
                &__item {
                    &:nth-child(n + 2) {
                        margin-top: vwm(20px);
                    }
                }
                &__link {
                    padding-left: vwm(15px);
                    font-size: vwm(15px);
                    &::before {
                        width: vwm(7px);
                        height: vwm(10px);
                        top: vwm(6px);
                    }
                }
            }
            &__menu-list--col2 {
                .l-dropdown__menu-list {
                    &__item {
                        width: calc((100% - vwm(40px)) / 2);
                        margin-right: vwm(40px);
                        &:nth-child(n + 3) {
                            margin-top: vwm(20px);
                        }
                    }
                }
            }
            &__menu-list--sub {
                .l-dropdown__menu-list {
                    &__item {
                        &:nth-child(n + 2) {
                            margin-top: vwm(15px);
                        }
                    }
                    &__link {
                        font-size: vwm(14px);
                        &::before {
                            width: vwm(6px);
                            height: vwm(1px);
                            top: vwm(10px);
                            left: 0px;
                        }
                    }
                }
            }
            &__btn {
                margin-bottom: vwm(30px);
                border-radius: vwm(6px);
                a {
                    padding: vwm(15px);
                    font-size: vwm(14px);
                    box-shadow: 0 0 vwm(35px) 0 rgba(2, 34, 110, 0.12);
                    span {
                        padding-left: vwm(43px);
                        &::before {
                            width: vwm(34px);
                            height: vwm(34px);
                        }
                    }
                }
            }
            .l-dropdown--products, .l-dropdown--corporate {
                .l-dropdown__left {
                    padding: vwm(40px) vwm(60px) vwm(50px) vwm(30px);
                }
                .l-dropdown__right {
                    padding: vwm(40px) vwm(30px) vwm(50px) vwm(40px);
                    .l-dropdown__imgcard {
                        border-radius: vwm(6px);
                        box-shadow: 0px 0px vwm(35px) 0px rgba(2, 34, 110, 0.12);
                        width: vwm(180px);
                        height: vwm(180px);
                        margin-bottom: vwm(30px);
                    }
                }
            }
            .l-dropdown--products {
                .l-dropdown__ttl {
                    margin-bottom: vwm(30px);
                }
                .l-dropdown__menu-list {
                    &__item {
                        &:nth-child(n + 2) {
                            margin-top: vwm(10px);
                        }
                    }
                    &__link {
                        font-size: vwm(14px);
                    }
                }
                .m-tabtext {
                    &__index {
                        &__item {
                            font-size: vwm(16px);
                            padding-bottom: vwm(7px);
                            &:nth-child(n + 2) {
                                margin-left: vwm(30px);
                            }
                            &.active {
                                border-bottom: vwm(3px) solid #0055a5;
                            }
                        }
                    }
                    &__content {
                        margin-top: vwm(25px);
                    }
                }
            }
            .l-dropdown--service {
                .l-dropdown__left {
                    padding: vwm(40px) vwm(60px) vwm(50px) vwm(30px);
                }
                .l-dropdown__right {
                    padding: vwm(40px) vwm(30px) vwm(50px) vwm(40px);
                }
                &__inner {
                    .l-dropdown__bnr {
                        &__txt {
                            font-size: vwm(15px);
                            margin-top: vwm(15px);
                        }
                    }
                    .l-dropdown__menu-list {
                        width: calc(40% - vwm(40px));
                        margin-left: vwm(40px);
                    }
                }
            }
            .l-dropdown--solution {
                .l-dropdown__left {
                    padding: vwm(40px) vwm(60px) vwm(50px) vwm(30px);
                }
                .l-dropdown__right {
                    padding: vwm(40px) vwm(30px) vwm(50px) vwm(40px);
                }
                &__inner {
                    .l-dropdown__bnr {
                        &__txt {
                            font-size: vwm(15px);
                            margin-top: vwm(15px);
                        }
                    }
                    .l-dropdown__menu-list {
                        width: calc(40% - vwm(40px));
                        margin-left: vwm(40px);
                    }
                }
            }
        }
        @include sp {
            border-top: none;
            background: #fff;
            position: inherit;
            max-height: inherit;
            overflow: hidden;
            opacity: 1;
            &__inner {
                padding: 0 25px;
                max-height: inherit;
                overflow: initial;
                &::after {
                    display: none;
                }
            }
            &__ttl {
                font-size: 1.7rem;
                letter-spacing: 0.02rem;
                padding: 5px 0 10px;
                margin-bottom: 5px;
                font-weight: 500;
                > a {
                    margin-right: 34px;
                    &::before {
                        width: 24px;
                        height: 24px;
                        right: -34px;
                    }
                    &::after {
                        right: -26px;
                    }
                }
            }
            &__ttl--smaller {
                font-size: 1.5rem;
                margin: 23px 0 0;
            }
            &__subttl {
                font-size: 1.5rem;
                font-weight: 500;
                margin: 23px 0 7px;
            }
            &__imgbox {
                display: block;
                &__item {
                    width: 100%;
                    margin-right: 0;
                    &:nth-child(n + 5) {
                        margin-top: 0;
                    }
                    &:last-child {
                        padding-bottom: 15px;
                    }
                    &__img {
                        display: none;
                    }
                    &__txt {
                        text-align: left;
                        font-size: 1.5rem;
                        padding: 7px 0 7px 15px;
                        margin-top: 0;
                        position: relative;
                        &::before {
                            content: "";
                            position: absolute;
                            width: 6px;
                            height: 9px;
                            top: 16px;
                            left: 0px;
                            background: url(/assets/img/common/icon_arrow_right.png) center/contain no-repeat;
                        }
                    }
                }
            }
            &__col3 {
                display: block;
                &__item {
                    margin-right: 0;
                    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                        width: 100%;
                    }
                }
            }
            &__menu-list {
                &__item {
                    &:nth-child(n + 2) {
                        margin-top: 0;
                    }
                }
                &__link {
                    padding: 7px 0 7px 15px;
                    display: block;
                    &::before {
                        content: "";
                        position: absolute;
                        width: 6px;
                        height: 9px;
                        top: 16px;
                        left: 0px;
                        background: url(/assets/img/common/icon_arrow_right.png) center/contain no-repeat;
                    }
                }
            }
            &__menu-list--col2 {
                display: block;
                .l-dropdown__menu-list {
                    &__item {
                        width: 100%;
                        margin-right: 0;
                        &:nth-child(2n) {
                            margin-right: 0;
                        }
                        &:nth-child(n + 2) {
                            margin-top: inherit;
                        }
                        &:nth-child(n + 3) {
                            margin-top: 0;
                        }
                    }
                }
            }
            &__menu-list--sub {
                border-top: 1px solid #efefef;
                padding: 18px 0 0;
                .l-dropdown__menu-list {
                    &__item {
                        &:nth-child(n + 2) {
                            margin-top: 0;
                        }
                    }
                    &__link {
                        &::before {
                            top: 20px;
                        }
                    }
                }
            }
            &__card--col4 {
                margin-top: 20px;
                margin-bottom: 25px;
                display: block;
                .l-dropdown__card {
                    &__item {
                        width: 100%;
                        margin-right: 0;
                        min-height: 52px;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        box-shadow: $boxshadow-sp;
                        &:nth-child(n + 2) {
                            margin-top: 10px;
                        }
                        &__img {
                            width: 38px;
                            height: 38px;
                        }
                        &__txt {
                            margin-left: 15px;
                            font-size: 1.5rem;
                        }
                    }
                }
            }
            &__btn {
                margin-top: 13px;
                margin-bottom: 25px;
                a {
                    font-size: 1.5rem;
                    box-shadow: $boxshadow-sp;
                    span {
                        padding-left: 43px;
                        &::before {
                            width: 32px;
                            height: 32px;
                        }
                    }
                }
            }
            .l-dropdown--products, .l-dropdown--corporate, .l-dropdown--service, .l-dropdown--solution {
                display: block;
                .l-dropdown__left {
                    width: 100%;
                    padding: 0 15px;
                }
                .l-dropdown__right {
                    width: 100%;
                    margin: 15px 0 0;
                    padding: 0 15px 25px;
                    background: inherit;
                }
            }
            .l-dropdown--products {
                .l-dropdown__ttl {
                    margin-bottom: -10px;
                }
                .l-dropdown__menu-list {
                    &__item {
                        &:nth-child(n + 2) {
                            margin-top: 0;
                        }
                    }
                }
                .m-tabtext {
                    &__index {
                        display: none;
                    }
                    &__content {
                        margin-top: 0;
                        &__item {
                            display: block;
                            height: auto;
                        }
                        .l-dropdown__ttl--smaller {
                            position: inherit;
                            margin: 23px 0 7px;
                            font-size: 1.5rem;
                            a {
                                padding: 0;
                                display: block;
                                &::before, &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .l-dropdown__right {
                    margin-top: 20px;
                    .l-dropdown__ttl {
                        font-size: 1.5rem;
                        margin: 15px 0 0;
                        padding-bottom: 5px;
                    }
                }
            }
            .l-dropdown--service {
                .l-dropdown__right {
                    margin-top: 20px;
                }
                &__inner {
                    .l-dropdown__menu-list {
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
        }
    }
    
}

// 言語選択モーダル
.l-header__global-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    background: #f4f4f8;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    &.active {
        opacity: 1;
        visibility: visible;
    }
    &__close {
        font-size: 1.6rem;
        width: 290px;
        height: 50px;
        margin: 50px auto 0;
        background: #fff;
        border-radius: 28px;
        border: 2px solid #dfdfe8;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: $transition;
        .icon-close {
            position: relative;
            padding-left: 38px;
            &::before {
                content: "";
                position: absolute;
                width: 17px;
                height: 17px;
                top: 50%;
                left: 0px;
                transform: translate(0, -50%);
                background: url(/assets/img/common/icon_close.png) center/contain no-repeat;
            }
        }
        @include pc {
            &:hover {
                background: #e8e8f0;
            }
        }
    }
    &__close--icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        padding: 15px;
        z-index: 5;
        cursor: pointer;
        img {
            vertical-align: inherit;
        }
    }
    &__inner {
        max-width: 1200px;
        margin: 0 auto;
        padding: 75px 100px;
    }
    &__ttl {
        font-family: Oswald-Regular;
        font-size: 3.6rem;
        text-align: center;
        position: relative;
        margin-bottom: 30px;
        span {
            letter-spacing: 0.1rem;
            display: inline-block;
            background: $c-accent;
            background: -webkit-linear-gradient( 45deg, rgb(1,60,136) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);
            background-clip: text;
            color: transparent;
        }
    }
    &__list {
        &__item {
            display: flex;
            align-items: center;
            &:nth-child(n + 2) {
                margin-top: 30px;
            }
        }
    }
    &__region-ttl {
        width: 15%;
        font-family: Oswald-Regular;
        font-size: 2rem;
    }
    &__btn__list {
        width: 85%;
        display: flex;
        flex-wrap: wrap;
        &__item {
            width: calc((100% - 30px) / 4);
            margin-right: 10px;
            height: 50px;
            font-size: 1.5rem;
            &.wide {
                width: 100%;
                height: 55px;
            }
            &:nth-child(4n) {
                margin-right: 0;
            }
            &:nth-child(n + 5) {
                margin-top: 10px;
            }
        }
    }
    &__btn__link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: #fff;
        text-align: center;
        border-radius: 6px;
        box-shadow: $boxshadow;
        transition: $transition;
        &:hover {
            box-shadow: none;
            color: $c-accent;
            background: #e8e8f0;
        }
    }
    @include pcmin1200 {
        &__close {
            font-size: vwm(16px);
            width: vwm(290px);
            height: vwm(50px);
            margin: vwm(50px) auto 0;
            border-radius: vwm(28px);
            .icon-close {
                padding-left: vwm(38px);
                &::before {
                    width: vwm(17px);
                    height: vwm(17px);
                }
            }
        }
        &__close--icon {
            width: vwm(50px);
            height: vwm(50px);
            padding: vwm(15px);
        }
        &__inner {
            padding: vwm(75px) vwm(100px);
        }
        &__ttl {
            font-size: vwm(36px);
            margin-bottom: vwm(30px);
        }
        &__list {
            &__item {
                &:nth-child(n + 2) {
                    margin-top: vwm(30px);
                }
            }
        }
        &__region-ttl {
            font-size: vwm(20px);
        }
        &__btn__list {
            &__item {
                width: calc((100% - vwm(30px)) / 4);
                margin-right: vwm(10px);
                height: vwm(50px);
                font-size: vwm(15px);
                &.wide {
                    height: vwm(55px);
                }
                &:nth-child(n + 5) {
                    margin-top: vwm(10px);
                }
            }
        }
        &__btn__link {
            border-radius: vwm(6px);
        }
    }
    @include sp {
        &__close {
            font-size: 1.6rem;
            width: 290px;
            height: 50px;
            margin: 50px auto 0;
            background: #fff;
            border-radius: 28px;
            border: 2px solid #dfdfe8;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: $transition;
            .icon-close {
                position: relative;
                padding-left: 38px;
                &::before {
                    content: "";
                    position: absolute;
                    width: 17px;
                    height: 17px;
                    top: 50%;
                    left: 0px;
                    transform: translate(0, -50%);
                    background: url(/assets/img/common/icon_close.png) center/contain no-repeat;
                }
            }
            &:hover {
                background: #e8e8f0;
            }
        }
        &__close--icon {
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            padding: 15px;
            z-index: 5;
            cursor: pointer;
            img {
                vertical-align: inherit;
            }
        }
        &__inner {
            padding: 55px 15px 70px;
        }
        &__ttl {
            font-size: 2.4rem;
        }
        &__list {
            &__item {
                display: block;
            }
        }
        &__region-ttl {
            width: 100%;
            text-align: center;
            font-size: 2rem;
            margin-bottom: 10px;
        }
        &__btn__list {
            width: 100%;
            &__item {
                width: calc((100% - 10px) / 2);
                margin-right: 10px;
                &:nth-child(2n) {
                    margin-right: 0;
                }
                &:nth-child(n + 3) {
                    margin-top: 10px;
                }
            }
        }
        &__btn__link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 50px;
            background: #fff;
            text-align: center;
            border-radius: 6px;
            box-shadow: $boxshadow;
            transition: $transition;
            &:hover {
                box-shadow: none;
                color: $c-accent;
                background: #e8e8f0;
            }
        }
    }
}

@media print {
  .l-header {
    display: none;
  }
}