@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

.p-sitemap {
    &__col3 {
        display: flex;
        flex-wrap: wrap;
        &:nth-child(n + 2) {
            margin-top: 100px;
        }
        &__item {
            width: calc((100% - 120px) / 3);
            margin-right: 60px;
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
    &__biglink {
        border-top: 1px solid #57c5cf;
        border-image: -webkit-linear-gradient(
            -180deg,
            rgb(87, 197, 207) 0%,
            rgb(73, 169, 214) 24%,
            rgb(58, 140, 220) 50%,
            rgb(49, 118, 206) 79%,
            rgb(39, 96, 191) 100%
        );
        border-image-slice: 1;
        &:nth-child(n + 2) {
            margin-top: 60px;
        }
        &--gray {
            border-color: #cccccc;
            border-image: none;
        }
        &.p-sitemap__biglink--mt40px {
            margin-top: 40px;
        }
        a {
            font-size: 2.2rem;
            position: relative;
            display: block;
            transition: $transition;
            padding: 25px 0 20px;
            &::before, &::after {
                transition: $transition;
                position: absolute;
                content: "";
                top: 50%;
                transform: translate(0, - 50%);
            }
            &::before {
                right: 0;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background: #fff;
                border: 1px solid #002d78;
            }
            &::after {
                background: url(/assets/img/common/icon_arrow_right.png) center/contain no-repeat;
                width: 8px;
                height: 11px;
                right: 10px;
            }
            @include pc {
                &:hover {
                    color: $c-accent;
                    &::after {
                        right: 6px;
                    }
                }
            }
        }
    }
    &__link {
        border-top: 1px solid #e5e5e5;
        padding-top: 30px;
        &__item {
            &.newwindow {
                a {
                    &::after {
                        content: "";
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        bottom: 6px;
                        margin-left: 6px;
                        background: url(/assets/img/common/icon_newwindow.png) center/contain no-repeat;
                    }
                }
            }
            a {
                font-size: 1.6rem;
                position: relative;
                padding-left: 20px;
                display: block;
                transition: $transition;
                &::before {
                    position: absolute;
                    content: "";
                    width: 7px;
                    height: 10px;
                    top: 8px;
                    left: 5px;
                    background: url(/assets/img/common/icon_arrow_right.png) center/contain no-repeat;
                }
                @include pc {
                    &:hover {
                        color: $c-accent;
                    }
                }
            }
            &:nth-child(n + 2) {
                margin-top: 15px;
            }
        }
    }
    @include sp {
        &__col3 {
            &:nth-child(n + 2) {
                margin-top: 60px;
            }
            &__item {
                width: 100%;
                margin-right: 0;
                &:nth-child(n + 2) {
                    margin-top: 60px;
                }
            }
        }
        &__biglink {
            a {
                font-size: 1.8rem;
                padding: 20px 0;
            }
        }
        &__link {
            padding-top: 25px;
        }
    }
}