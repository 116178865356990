@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// リユース事業のご案内
.p-guidance {
    .m-table {
        .m-image-text--type03 {
            .m-image-text {
                &__image {
                    background: #fff;
                    img {
                        width: 150px;
                    }
                }
                &__caption {
                    font-size: 1.5rem;
                    color: $c-base;
                }
            }
            .m-text-link {
                font-size: 1.5rem;
                margin-top: 15px;
            }
            .m-list--dot__item {
                font-size: 1.5rem;
                &:nth-child(n+2) {
                    margin-top: 10px;
                }
            }
        }
        @include sp {
            .m-image-text--type03 {
                .m-image-text {
                    &__caption {
                        font-size: 1.4rem;
                    }
                }
                .m-text-link {
                    font-size: 1.4rem;
                }
                .m-list--dot__item {
                    font-size: 1.4rem;
                }
            }
        }
    }
}