@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

.p-medical {
    .m-checklist__checkflex {
        &--center {
            .m-checklist__checkflex__item {
                width: 535px;
            }
        }
    }

    .p-medical__table {
        .m-table {
            &__head {
                .m-table__head__item {
                    background: #0055a5;
                    color: #fff;
                    &:nth-child(odd) {
                        background: #3377b7;
                    }
                    &:first-child {
                        background: #fff;
                        border: none;
                    }
                }
            }
            &__body {
                .m-table__head__item {
                    background: #0055a5;
                    color: #fff;
                }
                .m-table__row {
                    transition: all 0.2s linear;
                    &.blue {
                        &:hover {
                            background: #EDF7FF;
                        }
                    }
                    &.yellow {
                        &:hover {
                            background: #ffffe3;
                        }
                    }
                    &.green {
                        &:hover {
                            background: #F0F9E4;
                        }
                    }
                    &.pink {
                        &:hover {
                            background: #FFEDF3;
                        }
                    }
                }
                .m-table__data {
                    position: relative;
                    background: transparent;
                    &.blue {
                        background: #EDF7FF;
                    }
                    &.yellow {
                        background: #ffffe3;
                    }
                    &.green {
                        background: #F0F9E4;
                    }
                    &.pink {
                        background: #FFEDF3;
                    }
                    .link {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        transition: all 0.2s linear;
                        &:hover {
                            background: rgba(255,255,255,0.4);
                        }
                    }
                }

                .m-image--col1 {
                    .m-image__image {
                        max-height: 125px;
                        width: 125px;
                    }
                    .m-image__caption {
                        margin-top: 0;
                    }
                }
            }
        }
        
    }
}