@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

.p-user_support {
    &__planlist {
        background: #f4f4f8;
        padding: 70px 0 90px;
        @include sp {
            padding: 50px 0 60px;
        }
    }
    &__box {
        padding: 80px 70px 50px;
        box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.12);
        background: #fff;
        position: relative;
        + .p-user_support__box {
            margin-top: 100px;
        }
        &__num {
            font-size: 6rem;
            font-family: Oswald-Light;
            position: absolute;
            top: -50px;
            left: 0;
            right: 0;
            width: 100%;
            text-align: center;
            &__inner {
                display: inline-block;
                background: $c-accent;
                background: -webkit-linear-gradient( 0deg, rgb(1,60,136) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);
                background-clip: text;
                color: transparent;
            }
            &::after {
                position: absolute;
                content: '';
                top: 100%;
                left: 50%;
                transform: translate(-50%, 0);
                width: 1px;
                height: 35px;
                background: $c-accent;
                background-image: -webkit-linear-gradient( -90deg, rgb(1,60,136) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);
            }
        }
        &__ttl {
            color: $c-accent;
            font-size: 2rem;
            text-align: center;
            margin-bottom: 40px;
        }
        &__inner {
            display: flex;
        }
        &__img {
            width: 390px;
            margin-right: 50px;
        }
        &__detail {
            width: calc(100% - 440px);
            .m-h4 {
                margin-top: 0;
            }
        }
        @include sp {
            padding: 70px 20px 30px;
            + .p-user_support__box {
                margin-top: 60px;
            }
            &__num {
                font-size: 5rem;
                top: -40px;
                &::after {
                    height: 30px;
                }
            }
            &__ttl {
                font-size: 1.7rem;
                margin-bottom: 20px;
            }
            &__inner {
                display: block;
            }
            &__img {
                width: 100%;
                margin-right: 0;
                margin-bottom: 25px;
            }
            &__detail {
                width: 100%;
            }
        }
    }

    .m-table--scroll {
        .m-table {
            &__head {
                .m-table__row {
                    background-image: -webkit-linear-gradient( 0deg, rgb(14,92,138) 0%, rgb(13,130,140) 100%);
                }
                .m-table__head__item {
                    color: #fff;
                    background: none;
                }
            }
            &__body {
                .m-midium {
                    margin-bottom: 5px;
                }
                .m-text-link {
                    font-size: 1.5rem;
                }
                .m-table__row {
                    .m-table__head__item {
                        background: #eff4f8;
                    }
                    &:nth-child(even) {
                        .m-table__head__item {
                            background: #e5ecf4;
                        }
                        .m-table__data {
                            background: #f6fafd;
                        }
                    }
                }
            }
        }
        @include sp {
            .m-table__content {
                min-width: 980px;
            }
        }
    }
}