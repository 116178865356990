@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

.p-404 {
    .l-lower__content {
        padding-top: 120px;
        @include sp {
            padding-top: 40px;
        }
    }
    &__box {
        display: flex;
        flex-wrap: wrap;
        &__left {
            width: 52%;
            margin-right: 8%;
        }
        &__right {
            width: 40%;
        }
        @include sp {
            &__left {
                width: 100%;
                margin-right: 0;
                display: contents;
            }
            &__right {
                order: 2;
                width: 45%;
                min-width: 190px;
                margin: 30px auto 0;
            }   
        }
    }
    @include sp {
        &__titlebox {
            order: 1;
            width: 100%;
        }
    }
    &__title {
        color: $c-accent;
        font-size: 3.6rem;
        font-family: Oswald-Regular;
        &__num {
            font-size: 10rem;
            font-family: Oswald-Light;
            margin-right: 20px;
        }
        &__text {
            color: $c-accent;
            font-size: 2rem;
            font-weight: 500;
        }
        @include sp {
            text-align: center;
            font-size: 3rem;
            &__num {
                display: block;
                font-size: 6rem;
                margin-right: 0;
                margin-bottom: -15px;
            }
            &__text {
                margin-top: 10px;
                text-align: center;
                font-size: 1.7rem;
            }
        }
    }
    &__content {
        margin-top: 40px;
        @include sp {
            order: 3;
            width: 100%;
            .m-text--normal:first-of-type {
                text-align: center;
            }
        }
    }
    .m-list--dot {
        border-top: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
        padding: 25px 0;
        margin-bottom: 25px;
        @include pcmin1200 {
            &__item {
                font-size: 1.5rem;
            }
        }
        @include sp {
            &__item {
                font-size: 1.5rem;
            }
        }
    }
    .m-btn--2col {
        margin-top: 15px;
        .m-btn {
            width: calc((100% - 20px) / 2);
        }
        @include sp {
            margin-top: 25px;
            .m-btn {
                width: 100%;
                padding: 0 25px;
            }   
        }
    }
    .m-btn--normal {
        min-width: inherit;
        width: 100%;
    }
}