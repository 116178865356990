@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

.sustainability {
  // テキストカラー
  .m-base {
    color: $c-base;
  }

  .m-normal {
    font-weight: normal;
  }

  // ローマ数字
  .l-main {
    .m-list--num {
      &.num--roman {
        > .m-list--num__item {
          &::before {
            content: counter(list-num, lower-roman) "."
          }
        }
      }
    }

    .m-list--num__item__inner {
      &.num--roman {
        > .m-list--num__item__inner__item {
          padding-left: 25px;
          &::before {
            content: counter(list-inner-num, lower-roman) "."
          }
        }
      }
    }

    .m-list--num--2digits {
      &.num--roman {
        > .m-list--num--2digits__item {
          padding-left: 30px;
          &::before {
            content: counter(list-inner-num, lower-roman) "."
          }
        }
      }
    }

    .m-list--num--2digits__item__inner {
      &.num--roman {
        > .m-list--num--2digits__item__inner__item {
          padding-left: 30px;
          &::before {
            content: counter(list-inner-num, lower-roman) "."
          }
        }
      }
    }
  }

  // 画像
  .l-main {
    .m-image--col1 {
      .m-image__item {
        display: table;
        width: auto;
        margin-left: auto;
        margin-right: auto;

        .m-image__caption {
          display: table-caption;
          caption-side: bottom;
        }
      }
    }
  }

  .m-image {
    &.m-image--col2 {
      .m-image__item {
        .m-image__ttl {
          @include pc {
            font-size: 2rem;
            margin-top: 30px;
          }
        }
      }
    }

    &.m-image--col3 {
      .m-image__item {
        .m-image__ttl {
          @include pc {
            font-size: 1.8rem;
            margin-top: 20px;
          }
        }
      }
    }
    .m-image__item {
      .m-image__ttl {
        font-weight: 500;

        @include sp {
          font-size: 1.7rem;
          margin-top: 27px;
        }
      }

      > a {
        .m-image__image {
          overflow: hidden;

          img {
            transition: $transition;
          }
        }

        .m-image__ttl {
          transition: $transition;
        }

        .m-text--normal {
          color: $c-sub;
          font-size: 1.5rem;
        }

        &:hover {
          .m-image__image {
            img {
              transform: scale(1.1);
            }
          }

          .m-image__ttl {
            color: $c-accent;
          }
        }
      }
    }
  }

  .m-table {
    .m-table__head__item {
      .m-table__head__item__inner {
        img {
          width: auto;
          max-width: 122px;
        }

        .m-w100per {
          width: 100%;
          max-width: none;
        }
      }
    }

    .m-table__data {
      .m-list--dot__item {
        font-size: 1.5rem;

        &:nth-child(n+2) {
          margin-top: 10px;
        }
      }
    }

    &.table--materiality {
      .m-table__data {
        .m-image--col3 {
          flex-wrap: nowrap;
          align-items: center;

          .m-image__item {
            margin-right: 14px;

            @include pc {
              width: 28.99%;
              max-width: 60px;
              min-width: 40px;
              flex-shrink: 0;
            }

            @include sp {
              width: 40px;
              margin-top: 0;
            }

            img {
              @include pc {
                width: 100%;
                height: 100%;
                max-width: 60px;
                min-width: 40px;
              }

              @include sp {
                width: 40px;
                height: 40px;
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        &.m-vam {
          vertical-align: middle;
        }
      }
    }
  }

  .p-sustainability__under {
    background-color: #f0f3f7;
    min-height: 0;
    max-height: none;

    @include pc {
      background-size: 250px 250px;
      height: 320px;
    }

    @include sp {
      background-size: 150px 150px;
      height: 210px;
    }

    .l-lower__mainv__ttl {
      color: $c-base;
      left: 50%;
      letter-spacing: .05em;
      text-align: center;
      top: calc(50% - 20px);
      transform: translate(-50%, -50%);
      width: 100%;

      @include pc {
        font-size: 3rem;
      }

      @include sp {
        font-size: 2.2rem;
      }

      span {
        display: block;
        font-family: 'Oswald-Regular';

        @include pc {
          font-size: 4.5rem;
        }

        @include sp {
          font-size: 3.4rem;
        }
      }
    }

    &__content {
      position: relative;
      z-index: 2;

      @include pc {
        margin-top: -60px;
      }

      @include sp {
        margin-top: -50px;
      }
    }
  }
}
