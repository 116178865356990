@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

.p-closeup {
    &__index {
        margin-top: 100px;
        display: flex;
        flex-wrap: wrap;
        &__item {
            width: calc((100% - 90px) / 4);
            margin-right: 30px;
            padding: 40px 20px 45px;
            box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.12);
            text-align: center;
            position: relative;
            background: #fff;
            transition: $transition;
            &:nth-child(4n) {
                margin-right: 0;
            }
            .m-text--normal {
                line-height: 1.6;
            }
            &::after {
                position: absolute;
                content: "";
                width: 16px;
                height: 9px;
                bottom: 20px;
                left: 50%;
                transform: translate(-50%, 0);
                background: url(/assets/img/common/icon_arrow_bottom.png) center/contain no-repeat;
                transition: $transition;
            }
            &.disabled {
                background: #f4f4f8;
                pointer-events: none;
                box-shadow: none;
            }
            &:hover {
                background: #f4f4f8;
                box-shadow: none;
                &::after {
                    bottom: 15px;
                }
            }
        }
        &__num {
            position: absolute;
            top: -50px;
            font-size: 1.5rem;
            right: 0;
            left: 0;
            text-align: center;
            font-family: Oswald-Light;
            &__inner {
                display: inline-block;
                background: #002d78;
                background: -webkit-linear-gradient(0deg, rgb(0, 45, 120) 0%, rgb(9, 90, 170) 50%, rgb(3, 130, 142) 100%);
                background-clip: text;
                color: transparent;
               &__num {
                    display: block;
                    font-size: 4.2rem;
                    margin-top: -7px;
               }
            }
        }
        &__link {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
        @include pcmin1000 {
            &__item {
                width: 100%;
                margin-right: 0;
                padding: 35px 20px 40px;
                br.is-pc {
                    display: none !important;
                }
                &:nth-child(n + 2) {
                    margin-top: 60px;
                }
            }
        }
        @include sp {
            margin-top: 70px;
            &__item {
                width: 100%;
                margin-right: 0;
                padding: 35px 20px 40px;
                &:nth-child(n + 2) {
                    margin-top: 60px;
                }
                .m-text--normal {
                    font-size: 1.4rem;
                }
            }
            &__num {
                top: -40px;
                &__inner {
                    &__num {
                        font-size: 3.2rem;
                    }
                }
            }
        }
    }
    .m-h2--gradient {
        &__inner {
            background: -webkit-linear-gradient( 0deg, rgb(0,91,136) 0%, rgb(1,117,139) 50%, rgb(1,129,140) 100%);
            background-clip: text;
            color: transparent;
        }
    }
    &__box {
        background: #fff;
        padding: 100px 70px;
        position: relative;
        margin-top: 60px;
        &:nth-of-type(n + 2) {
            margin-top: 130px;
        }
        .p-closeup__index {
            &__num {
                top: -65px;
                font-size: 2rem;
                &__inner {  
                   &__num {
                        font-size: 6rem;
                        margin-top: -15px;
                   }
                }
                &::after {
                    position: absolute;
                    content: "";
                    top: 100%;
                    left: 50%;
                    transform: translate(-50%, 0);
                    width: 1px;
                    height: 35px;
                    background: #002d78;
                    background-image: -webkit-linear-gradient(-90deg, rgb(0, 45, 120) 0%, rgb(0, 90, 170) 50%, rgb(3, 130, 142) 100%);
                }
            }
        }
        &__inner {
            display: flex;
            flex-wrap: wrap;        
            margin-top: 50px;
            &--reverse {
                flex-direction: row-reverse;
                .p-closeup__box__image {
                    margin-right: 0;
                    margin-left: 50px;
                }
            }
            &--03 {
                .p-closeup__box__image {
                    width: calc((100% - 50px)/2);
                }
                .p-closeup__box__detail {
                    width: calc((100% - 50px)/2);
                }
            }
            &:first-of-type {
                margin-top: 50px;
            }
        }
        &__image {
            width: 390px;
            margin-right: 50px;
            &__caption {
                margin-top: 15px;
                font-size: 1.4rem;
                color: $c-sub;
            }
        }
        &__detail {
            width: calc(100% - 440px);
        }
        &__ttl {
            font-size: 2.5rem;
            font-weight: 500;
            width: 100%;
            + * {
                margin-top: 25px;
            }
        }
        @include sp {
            padding: 85px 20px 40px;
            margin-top: 50px;
            &:nth-of-type(n + 2) {
                margin-top: 100px;
            }
            .p-closeup__index {
                &__num {
                    top: -60px;
                    font-size: 2rem;
                    &__inner {  
                        &__num {
                                font-size: 5rem;
                                margin-top: -10px;
                        }
                    }
                    &::after {
                        height: 30px;
                    }
                }
            }
            &__inner {      
                margin-top: 40px;
                &--reverse {
                    flex-direction: row-reverse;
                    .p-closeup__box__image {
                        margin-left: 0;
                    }
                }
                &--03 {
                    .p-closeup__box__image {
                        width: 100%;
                    }
                    .p-closeup__box__detail {
                        width: 100%;
                    }
                }
                &:first-of-type {
                    margin-top: 30px;
                }
            }
            &__image {
                width: 100%;
                margin-right: 0;
                &__caption {
                    margin-top: 15px;
                    font-size: 1.4rem;
                    color: $c-sub;
                }
            }
            &__detail {
                width: 100%;
                margin-top: 30px;
            }
            &__ttl {
                font-size: 1.8rem;
                + * {
                    margin-top: 20px;
                }
            }
        }
    }
}
