@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

.p-search {
    .gsc-control-cse {
        padding: 0;
    }
    .gsc-selected-option-container {
        min-width: 95px;
    }
}