@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// RoHS指令を始めとする環境規制に関するJEOLの製品・サービス
.p-rohs {
    .m-table__body {
        .m-table__data {
            &.p-rohs {
                &__yellow {
                    background: #fffeef;
                }
                &__green {
                    background: #d5f5cd;
                }
                &__blue {
                    background: #e3fafa;
                }
            }
        }
    }
    
}