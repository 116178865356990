@charset "UTF-8";

/**********************************
* fonts
***********************************/
// @font-face {
// 	font-family: NotoSansJP;
// 	src:url('/assets/fonts/NotoSansJP-Light.otf') format("opentype");
// 	font-weight: 300;
// }
// @font-face {
// 	font-family: NotoSansJP;
// 	src:url('/assets/fonts/NotoSansJP-Regular.otf') format("opentype");
// 	font-weight: 400;
// }
// @font-face {
// 	font-family: NotoSansJP;
// 	src:url('/assets/fonts/NotoSansJP-Medium.otf') format("opentype");
// 	font-weight: 500;
// }
// @font-face {
// 	font-family: NotoSansJP;
// 	src:url('/assets/fonts/NotoSansJP-Bold.otf') format("opentype");
// 	font-weight: bold;
// }

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Noto+Sans+JP:wght@300;400;500;700&display=swap');

// @font-face {
// 	font-family: NotoSerifJP;
// 	src:url('/assets/fonts/NotoSerifJP-Regular.otf') format("opentype");
// 	font-weight: 400;
// }

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Noto+Serif+JP&display=swap');




// 英字で使用
@font-face {
	font-family: Oswald-Light;
	src:url('/assets/fonts/Oswald-Light.ttf') format("truetype");
}
@font-face {
	font-family: Oswald-Regular;
	src:url('/assets/fonts/Oswald-Regular.ttf') format("truetype");
}
@font-face {
	font-family: Oswald-Medium;
	src:url('/assets/fonts/Oswald-Medium.ttf') format("truetype");
}

@font-face {
	font-family: Roboto-MediumItalic;
	src:url('/assets/fonts/Roboto-MediumItalic.ttf') format("truetype");
}
