@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// グローバルネットワーク
.p-corporate-globalnetwork {
    &__pin {
        &__wrap {
            position: relative;
        }
        position: absolute;
        content: "";
        width: 26px;
        height: 39px;
        background: url(/assets/img/common/icon_pin.png) center/contain no-repeat;
        transform: translateY(-140px);
        opacity: 0;
        &--01 {
            top: 17.5%;
            left: 56.7%;
        }
        &--02 {
            top: 19%;
            left: 51%;
        }
        &--03 {
            top: 24%;
            left: 48.9%;
        }
        &--04 {
            top: 25.6%;
            left: 47.5%;
        }
        &--05 {
            top: 25.8%;
            left: 50.4%;
        }
        &--06 {
            top: 27.7%;
            left: 48.7%;
        }
        &--07 {
            top: 31%;
            left: 50.9%;
        }
        &--08 {
            top: 28.8%;
            left: 32.3%;
        }
        &--09 {
            top: 31.9%;
            left: 31%;
        }
        &--10 {
            top: 29.8%;
            left: 74.4%;
        }
        &--11 {
            top: 31.8%;
            left: 73.3%;
        }
        &--12 {
            top: 33.5%;
            left: 75.2%;
        }
        &--13 {
            top: 34.3%;
            left: 23%;
        }
        &--14 {
            top: 35.8%;
            left: 30.6%;
        }
        &--15 {
            top: 35.8%;
            left: 73.3%;
        }
        &--16 {
            top: 35.8%;
            left: 71.3%;
        }
        &--17 {
            top: 40.8%;
            left: 73.3%;
        }
        &--18 {
            top: 43.8%;
            left: 70%;
        }
        &--19 {
            top: 43%;
            left: 64.3%;
        }
        &--20 {
            top: 42%;
            left: 59.2%;
        }
        &--21 {
            top: 43.2%;
            left: 26.8%;
        }
        &--22 {
            top: 48%;
            left: 70.3%;
        }
        &--23 {
            top: 52.2%;
            left: 71%;
        }
        &--24 {
            top: 65%;
            left: 38.1%;
        }
        &--25 {
            top: 70.5%;
            left: 79.4%;
        }

        &__anime {
            &--1{
              animation : bound 0.2s ease-in-out forwards;
            }
            &--2{
              animation : bound 0.5s ease-in-out 0.6s forwards;
            }
            &--3{
              animation : bound 0.5s ease-in-out 1.1s forwards;
            }
            &--4{
              animation : bound 0.3s ease-in-out 1.6s forwards;
            }
            &--5{
              animation : bound 0.2s ease-in-out 1.9s forwards;
            }
            &--6{
              animation : bound 0.1s ease-in-out 2.1s forwards;
            }
            &--7{
              animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--8{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--9{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--10{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--11{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--12{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--13{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--14{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--15{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--16{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--17{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--18{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--19{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--20{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--21{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--22{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--23{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--24{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
            &--25{
                animation : bound 0.1s ease-in-out 2.2s forwards;
            }
        }
        @keyframes bound{
            40%{ transform: translateY(-140px); opacity: 1; }
            55%{ transform: translateY(-130px); opacity: 1; }
            70%{ transform: translateY(-120px); opacity: 1; }
            80%{ transform: translateY(-110px); opacity: 1; }
            100%{ transform: translateY(0); opacity: 1; }
        }
        
        @include pcmin1200 {
            width: vwm(26px);
            height: vwm(39px);
        }
        @include sp {
            width: vwm(26px);
            height: vwm(39px);    
        }
    }
    &__ttl {
        margin-top: 80px;
        text-align: center;
        .m-sec__ttl__color {
            font-size: 5.2rem;
        }
        @include sp {
            margin-top: 40px;
            .m-sec__ttl__color {
                font-size: 4.2rem;
            }
        }
    }
    .m-col2 {
        &__item {
            &:nth-child(2) {
                .m-image__image {
                    padding-top: 3.2%;
                }
            }
        }
    }
    &__border {
        position: relative;
        &::before {
            position: absolute;
            content: '';
            left: 50%;
            top: -35px;
            width: 1px;
            height: 70px;
            background: $c-accent;
        }
        @include sp {
            &::before {
                top: -25px;
                height: 50px;
            }
        }
    }
    .l-lower__section {
        &.m-mt70px {
            margin-top: 70px;
            &.p-corporate-global__border {
                &::before {
                    top: -105px;
                }
            }
        }
        @include sp {
            &.m-mt50px-sp {
                margin-top: 50px;
                &.p-corporate-global__border {
                    &::before {
                        top: -75px;
                    }
                }
            }
        }
    }
    .m-image__caption {
        max-width: 694px;
        margin: 15px auto 0;
    }
    &__gradient {
        font-size: 3.2rem;
        font-weight: 500;
        line-height: 1.2;
        &__inner, &__num {
            background: $c-accent;
            background: -webkit-linear-gradient( 45deg, rgb(1,60,136) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);
            background-clip: text;
            color: transparent;
            font-feature-settings : inherit;
        }
        &__num {
            font-family: Oswald-Regular;
            font-size: 9.5rem;
        }
        &--smaller {
            .p-corporate-globalnetwork__gradient__num {
                font-size: 6.8rem;
            }
        }
        @include pcmin1200 {
            font-size: vwm(32px);
            &__num {
                font-size: vwm(95px);
            }
            &--smaller {
                .p-corporate-globalnetwork__gradient__num {
                    font-size: vwm(68px);
                }
            }
        }
        @include sp {
            font-size: 2.2rem;
            &__num {
                font-size: 6.5rem;
            }
            &--smaller {
                font-size: 1.8rem;
                .p-corporate-globalnetwork__gradient__num {
                    font-size: 4.2rem;
                }
            }
        }
    }
    &__position {
        position: relative;
        @include sp {
            &--01 {
                padding-top: 95px;
            }
        }
    }
    &__target01 {
        position: absolute;
        bottom: 18%;
        left: 12%;
        @include sp {
            top: 0;
            left: 0;
            right: 0;
            text-align: center;
            .p-corporate-globalnetwork__gradient__num {
                margin-right: 5px;
            }
        }
    }
    &__target02 {
        position: absolute;
        top: 20%;
        right: 18%;
    }
    &__target03 {
        position: absolute;
        top: 3%;
        right: 18%;
        @include sp {
            top: 0%;
        }
    }
}