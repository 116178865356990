@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

.p-feature_eo {
    .m-imagetext {
        &__img {
            background: #f7f7f7;
        }
        + .m-imagetext {
            padding-top: 0px;
            border-top: none;
        }
    }
    .m-banner--user-support {
        margin-top: 80px;
        @include sp {
            margin-top: 40px;
        }
    }
}