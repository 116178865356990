@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

.p-scientific {
    .m-banner--user-support {
        margin-top: 100px;
        @include sp {
            margin-top: 60px;
        }
    }
}

