@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 沿革
.p-history {
    &__ttl {
        font-size: 1.4rem;
        &__num {
            font-size: 2.2rem;
            font-weight: 500;
            font-family: Oswald-Medium;
            @include sp {
                font-size: 1.8rem;
            }
        }
    }
    &__table {
        .m-list--dot {
            margin-top: 0;
        }
        @include pc {
            &__flex {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .m-list--dot {
                    margin-right: 20px;
                }
                .p-history__imagebox {
                    &__item {
                        width: 160px;
                    }
                }
                &--type01 {
                    .m-list--dot {
                        width: calc(100% - 180px);
                    }
                    .p-history__imagebox {
                        width: 160px;
                    }
                }
                &--type02 {
                    .m-list--dot {
                        width: calc(100% - 360px);
                    }
                    .p-history__imagebox {
                        width: 340px;
                    }
                }
            }
        }
    }
    &__imagebox {
        display: flex;
        flex-wrap: wrap;
        &__item {
            &:nth-child(n + 2) {
                margin-left: 20px;
            } 
        }
        &__caption {
            font-size: 1.4rem;
            color: $c-sub;
            margin-top: 15px;
            text-align: center  ;
        }
        @include sp {
            margin-top: 20px;
            justify-content: center;
            &__item {
                width: calc((100% - 20px) / 2);
            }
        }
    }
}