@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// お問い合わせのご案内
.p-contacts {
    &__ac {
        hr {
            border-color: #e0e1e2;
            margin-top: 35px;
            padding-top: 35px;
        }
        &.m-ac01 {
            border-top: none;
        }
        .m-ac01 {
            &__item {
                border: none;
                &.active {
                    .m-ac01__ttl {
                        background: #fff;
                    }
                }
            }
            &__ttl {
                font-size: 2.2rem;
                text-align: center;
                padding: 30px 60px 30px 20px;
                background: #e5f2fd;
                box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.1);
                .p-contacts__ac__icon {
                    position: relative;
                    display: inline-block;
                    padding-left: 60px;
                    &::before {
                        position: absolute;
                        content: "";
                        width: 41px;
                        height: 36px;
                        top: 50%;
                        left: 0px;
                        transform: translate(0, -50%);
                        background: url(/assets/img/common/icon_attention_blue.png) center/contain no-repeat;
                    }
                }
            }
        }
        @include sp {
            hr {
                margin-top: 25px;
                padding-top: 25px;
            }
            .m-ac01 {
                &__ttl {
                    font-size: 1.6rem;
                    text-align: left;
                    padding: 20px 34px 20px 20px;
                    .p-contacts__ac__icon {
                        padding-left: 40px;
                        &::before {
                            width: 29px;
                            height: 26px;
                        }
                    }
                    &::before,
                    &::after {
                        right: 20px;
                    }
                }
            }
        }
    }
    .m-col2 {
        .m-contact {
            height: 100%;
        }
        .m-box {
            height: 100%;
            padding: 40px;
            border-radius: 0;
            .m-h5 {
                margin-top: 0;
            }
        }
        @include sp {
            .m-contact {
                &__box {
                    &:nth-child(2) {
                        border-top: 1px solid #ddd;
                        padding-top: 25px;
                    }
                }
                .m-text-link {
                    text-align: center;
                }
            }
            .m-box {
                padding: 25px 20px;
            }
        }
    }
}