@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

.l-main {
    .m-btn--arrow {
        text-align: right;
        margin-top: 30px;
        &__link {
            font-size: 1.6rem;
            padding-right: 55px;
            position: relative;
            white-space: nowrap;
            transition: $transition;
            display: inline-block;
            &::before {
                position: absolute;
                content: "";
                display: block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: $c-accent;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);
            }
            &::after {
                position: absolute;
                content: "";
                width: 8px;
                height: 12px;
                top: 50%;
                right: 15px;
                transform: translate(0, -50%);
                background: url(/assets/img/common/icon_arrow_right_white.png) center/contain no-repeat;
                transition: $transition;
            }
            &:hover {
                color: $c-accent;
                &::after {
                    right: 11px;
                }
            }
        }
    }
    
    // 選択を解除するボタン
    .m-btn--refresh {
        display: inline-block;
        color: #999999;
        font-size: 1.4rem;
        cursor: pointer;
        transition: $transition;
        pointer-events: none;
        .icon__reflesh {
            position: relative;
            padding-left: 30px;
            &::before, &::after {
                position: absolute;
                content: "";
                display: block;
                top: 50%;
                left: 0;
                width: 22px;
                height: 20px;
                transform: translate(0, -50%);
                transition: $transition;
            }
            &:before {
                background: url(/assets/img/common/icon_reflesh_disabled.png) center/contain no-repeat;
                opacity: 1;
            }
            &::after {
                background: url(/assets/img/common/icon_reflesh_hover.png) center/contain no-repeat;
                opacity: 0;
            }
        }
        
        &.active {
            pointer-events: inherit;
            color: $c-base;
            .icon__reflesh {
                &:before {
                    background: url(/assets/img/common/icon_reflesh.png) center/contain no-repeat;
                }
            }
            @include pc {
                &:hover {
                    color: $c-accent;
                    .icon__reflesh {
                        &::before {
                            opacity: 0;
                        }
                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    // 絞り込み条件ボタン
    .m-btn--filter {
        font-size: 1.6rem;
        color: #fff;
        font-weight: 500;
        background: #606e85;
        border-radius: 6px;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
        height: 54px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        position: relative;
        &::after {
            position: absolute;
            content: "";
            display: block;
            top: 50%;
            right: 20px;
            width: 17px;
            height: 17px;
            transform: translate(0, -50%);
            transition: $transition;
            background: url(/assets/img/common/icon_plus_white.png) center/contain no-repeat;
        }
        .icon__filter {
            position: relative;
            padding-left: 30px;
            &::before {
                position: absolute;
                content: "";
                display: block;
                top: 50%;
                left: 0;
                width: 20px;
                height: 19px;
                transform: translate(0, -50%);
                transition: $transition;
                background: url(/assets/img/common/icon_filter.png) center/contain no-repeat;
            }
        }
    }

    // 確定ボタン
    .m-btn--sure {
        min-width: 290px;
        min-height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: -webkit-linear-gradient( -180deg, rgb(87,197,207) 0%, rgb(73,169,214) 24%, rgb(58,140,220) 50%, rgb(49,118,206) 79%, rgb(39,96,191) 100%);
        border-radius: 28px;
        color: #fff;
        font-size: 1.6rem;
        text-align: center;
        cursor: pointer;
    }

    // 検索ボタン
    .m-btn--search {
        min-width: 290px;
        min-height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 28px;
        color: #fff;
        font-size: 1.6rem;
        text-align: center;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &::before, &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            transition: $transition;
        }
        &::before {
            background: -webkit-linear-gradient( -180deg, rgb(87,197,207) 0%, rgb(73,169,214) 24%, rgb(58,140,220) 50%, rgb(49,118,206) 79%, rgb(39,96,191) 100%);
            opacity: 1;
        }
        &::after {
            background: -webkit-linear-gradient( 0deg, rgb(87,197,207) 0%, rgb(73,169,214) 24%, rgb(58,140,220) 50%, rgb(49,118,206) 79%, rgb(39,96,191) 100%);
            opacity: 0;
        }
        &:hover {
            &::after {
                opacity: 1;
            }
        }
        .icon__search {
            position: relative;
            padding-left: 30px;
            z-index: 1;
            &::before {
                position: absolute;
                content: "";
                display: block;
                top: 50%;
                left: 0;
                width: 22px;
                height: 20px;
                transform: translate(0, -50%);
                transition: $transition;
            }
            &:before {
                background: url(/assets/img/common/icon_search_white.png) center/contain no-repeat;
            }
            input {
                vertical-align: baseline;
            }
        }
    }
}
