@use "sass:math";
@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

// コンテンツ幅
.l-main {
	padding-top: 90px;
	@include pcmin1280 {
			padding-top: vw(90px);
	}
	@include sp {
			padding-top: 60px;
	}
	&__inner {
		max-width: 1260px;
		margin: 0 auto;
		padding: 0 30px;
		&.no-pd {
				padding: 0;
		}
		@include sp {
				padding: 0 15px;
		}
		&:has(.m-newslist) {
			max-width: 1260px;
			margin: 0 auto;
			padding: 0 30px;
			.m-newslist__item {
				.tag {
					background-color: #fff;
				}
			} 
		}
	}
	.m-newstab {
		.l-main__inner {
			max-width: 1260px;
			margin: 0 auto;
			padding: 0 30px;
		}
	}
	/*
	+.l-products{
			margin-top: 105px;
	}*/
}

// 下層ページ共通
.l-lower {
    position: relative;
}
.l-breadcrumb {
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    z-index: 5;
    max-width: 100%;
    &__list {    
        padding: 15px 0;
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        &__item {
            margin-left: 30px;        
            font-size: 1.2rem;
            line-height: 1.8;
            color: $c-sub;
            position: relative;
            &:first-child {
                padding-left: 15px;
                margin-left: 0;
            }
            &:nth-child(n + 2) {
                &::before {
                    position: absolute;
                    content: "";
                    width: 5px;
                    height: 8px;
                    top: 50%;
                    left: -15px;
                    transform: translate(0, -50%);
                    background: url(/assets/img/common/icon_breadcrumb_arrow.png) center/contain no-repeat;
                    transition: $transition;
                }
            }
            &:last-child {
                pointer-events: none;
                color: #333333;
            }
        }
    }
    .l-breadcrumb__list--white {
        .l-breadcrumb__list__item {
            a {
                color: #fff;
            }
            &:nth-child(n + 2) {
                &::before {
                    background: url(/assets/img/common/icon_breadcrumb_arrow_white.png) center/contain no-repeat;
                    transition: $transition;
                }
            }
        }
    }
    @include pcmin1280 {
        top: vw(90px);
    }
    @include sp {
        display: none;
    }
}
.l-lower__mainv {
    position: relative;
    z-index: 1;
    min-height: 400px;
    max-height: 500px;
    &__img {
        img {
            min-height: 400px;
            max-height: 500px;
            width: 100%;
            object-fit: cover;
        }
    }
    &__ttl {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 40px;
        color: #fff;
    }
    @include sp {
        min-height: inherit;
        max-height: 400px;
        img {
            min-height: inherit;
            max-height: 400px;
        }
        &__ttl {
            font-size: 24px;
        }
    }
}
.l-lower__header {
    padding: 120px 0 80px;
    &__ttl {
        font-size: 4rem;
        font-weight: 500;
    }
    &__ttl-sub {
        font-size: 2.4rem;
        margin-top: 30px;
        line-height: 1.4;
    }
    @include sp {
        padding: 40px 0;
        &__ttl {
            font-size: 2.4rem;
        }
        &__ttl-sub {
            font-size: 1.8rem;
            margin-top: 10px;
        }
    }
}
.l-lower__content {
    padding-bottom: 140px;
    @include sp {
        padding-bottom: 70px;
    }
}
.l-lower__section {
    &:nth-of-type(n + 2) {
        margin-top: 100px;
    }
    @include sp {
        &:nth-of-type(n + 2) {
            margin-top: 60px;
        }   
    }
}
.l-lower__bg {
    background: #f4f4f8;
    padding: 70px 0 90px;
    @include sp {
        padding: 50px 0 60px;
    }
}

@media print {
	.l-breadcrumb {
		position: relative;
		top: 0;
	}
	.l-main {
		padding-top: 0;
			.m-h2--gradient__inner, .m-h3--gradient__inner {
					color: #002d78;
			}
	}
	.l-lower__header {
		padding: 50px 0 20px;
	}
}