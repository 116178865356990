@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 会社概要
.p-corporate-about {
    &__map {
        iframe {
            width: 100%;
            height: 500px;
        }
    }
}