@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 過去の動画(ウェビナー一覧)
.p-archive_movies {
    &__movielist {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &__img {
            width: 180px;
        }
        &__caption {
            margin-top: 15px;
            font-size: 1.4rem;
            color: $c-sub;
        }
        &__text {
            margin-left: 40px;
            width: calc(100% - 220px);
        }
        &__date {
            margin-top: 15px;
            font-size: 1.4rem;
            color: $c-sub;
        }
        @include sp {
            display: block;
            &__img {
                width: 180px;
                margin: 0 auto;
            }
            &__text {
                margin-top: 30px;
                margin-left: 0;
                margin-right: 0;
                width:100%;
                font-size: 1.4rem;
                .m-text--normal {
                    font-size: 1.4rem;
                }
            }
        }
    }
    .m-ac01 {
        .p-archive_movies {
            &__movielist {
                flex-direction: row-reverse;
                &__text {
                    margin-left: 0;
                    margin-right: 40px;
                }
            }
        }
        @include sp {
            &__ttl {
                font-size: 1.6rem;
            }
        }
    }
}

.p-archive_movies_detail {
    .elq-form .elq-required {
        color: #e60e00;
        font-size: 1.6rem;
        font-weight: 500;
    }
    .elq-form .elq-label {
        display: inline-block;
        margin-bottom: 5px;
        pointer-events: none;
    }
    .elq-form .form-element-layout {
        margin-top: 30px;
    }
    .elq-form input[type=checkbox], .elq-form input[type=radio] {
        display: initial;
    }
    .elq-form .elq-item-input {
        height: 60px;
        width: 100%;
        padding: 0 20px;
        border-radius: 8px;
        background: #f5f6f9;
        border: 2px solid #d1d1d8;
        font-size: 1.6rem;
        outline-color: #002d78;
    }
    .elq-form .elq-item-select {
        height: 60px;
        width: 100%;
        padding: 0 20px;
        border-radius: 8px;
        background: #f5f6f9;
        border: 2px solid #d1d1d8;
        font-size: 1.6rem;
        outline-color: #002d78;
    }
    .elq-form .elq-field-style input[type=submit] {
        min-width: 290px;
        min-height: 56px;
        padding: 14px 20px;
        border: 2px solid #dfdfe8;
        border-radius: 60px;
        font-size: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        transition: all .2s linear;
        text-align: center;
        cursor: pointer;
    }
    .elq-form .elq-field-style input[type=submit]:hover {
        background: #dfdfe8;
    }
    .elq-form .LV_invalid {
        color: #e60e00;
        font-size: 1.4rem;
        font-weight: 500;
    }
    .elq-form .LV_valid {
        display: none;
    }
    .LV_invalid_field, .elq-item-input.LV_invalid_field:active, .elq-item-input.LV_invalid_field:hover, textarea.LV_invalid_field:active, textarea.LV_invalid_field:hover {
        outline-color:#e60e00 !important;
        border: 2px solid #e60e00 !important;
    }
    .elq-form #elq-FormLastRow {
        position: absolute;
        left: -80%;
        top: -80%;
    }
    @media (max-width:767px){
        .elq-form .elq-item-input {
            height: 50px;
            padding: 0 15px;
        }
        .elq-form .elq-item-select {
            height: 50px;
            padding: 0 15px;
        }
        .elq-form .elq-field-style input[type=submit] {
            width: 100%;
        }
    }
}