@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 全自動アミノ酸分析機ページ
.p-cl {
    &__red {
        color: #ff0000;
    }
    &__orange {
        color: #ff962c;
    }
    &__skyblue {
        color: #38b5cb;
    }
    &__blue {
        color: #00007b;
    }

    .m-table {
        &__body {
            .m-table__row {
                .m-table__head__item {
                    color: #fff;
                }
                &:nth-child(1) {
                    .m-table__head__item {
                        background: #ff0000;
                    }   
                }
                &:nth-child(2) {
                    .m-table__head__item {
                        background: #ff962c;
                    }   
                }
                &:nth-child(3) {
                    .m-table__head__item {
                        background: #38b5cb;
                    }   
                }
                &:nth-child(4) {
                    .m-table__head__item {
                        background: #00007b;
                    }   
                }
                &:nth-child(5) {
                    .m-table__head__item {
                        background: #000;
                    }   
                }
            }
        }
    }
}

// やさしい科学
.p-science {
    &__bg {
        padding: 80px 0 140px;
        background: url(/assets/img/products/science/bg.jpg);
    }
    .m-catalog__list {
        &__item {
            &__img {
                padding: 20px 0;
                background: #fff;
                box-shadow: 4px 6.928px 17.1px 0.9px rgba(0, 34, 60, 0.08);
                position: relative;
                &::after {
                    position: absolute;
                    content: '';
                    top: -15px;
                    left: 15px;
                    background: url(/assets/img/products/science/clip.png) center/contain no-repeat;
                    width: 35px;
                    height: 50px;

                }
                img {
                    position: initial;
                }
            }
        }
        @include pcmin1200 {
            &__item {
                &__img {
                    &::after {
                        top: vwm(-15px);
                        left: vwm(15px);
                        width: vwm(35px);
                        height: vwm(50px);
                    }
                }
            }
        }
    }
    @include sp {
        &__bg {
            padding: 50px 0 90px;
        }
        .m-catalog__list {
            &__item {
                &__img {
                    padding: 15px 0;
                    &::after {
                        position: absolute;
                        content: '';
                        top: -7px;
                        left: 8px;
                        background: url(/assets/img/products/science/clip.png) center/contain no-repeat;
                        width: 25px;
                        height: 35px;
                    }
                }
            }
        }
    }
}