@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 微細な世界
.p-nano {
    overflow: hidden;
    .m-checklist__checkflex:nth-of-type(n + 2) {
        margin-top: 0;
        padding-top: 0;
    }
    .js-more-btn {
        display: none;
    }
    .m-image-index {
        &__item {
            &__img {
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: 65%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: auto;
                    height: auto;
                    max-width: 115%;
                    max-height: initial;
                }
            }
            &__link {
                cursor: pointer;
            }
            &:hover {
                .m-image-index__item__img {
                    img {
                        transform: translate(-50%,-50%) scale(1.1);
                    }
                }
            }
        }
    }
    &__modal {
        &.m-modal {
            .m-modal {
                &__contents {
                    max-width: 1000px;
                    &__inner {
                        padding: 0;
                        overflow: initial;
                        .m-h4 {
                            margin-top: 0;
                            + * {
                                margin-top: 10px;
                            }
                        }
                        display: flex;
                        flex-wrap: wrap;
                    }
                }
            }
        }
        &__image {
            width: 50%;
            background: #000;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            img {
                object-fit: contain;
                max-width: 100%;    
                max-height: 100%;
            }
        }
        &__info {
            width: 50%;
            padding: 45px;
            overflow-y: auto;
            height: 100%;
        }
        .m-table-explanation {
            &__data {
                > .m-text--normal + .m-text-link {
                    margin-top: 15px;
                }
            }
        }
        @include sp {
            &.m-modal {
                .m-modal {
                    &__contents {
                        &__inner {
                            padding: 0;
                            overflow-y: auto;
                        }
                    }
                }
            }
            &__image {
                width: 100%;
            }
            &__info {
                width: 100%;
                padding: 25px 20px 40px;
                overflow-y: initial;
                height: auto;
            }
        }
    }
    .m-tab {
        @include sp {
            .m-bg {
                padding-bottom: 5px;   
            }
        }
    }
}