@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;
.p-nano-detail{
    .m-table__data {
        .m-text-link__link{
            padding-right: 20px;
            margin-right: 5px;
            padding-left: 0;
            @include sp{
                padding-right: 15px;
                margin-right: 5px;
                padding-left: 0;
            }
            &:before{
                display: none;
            }
        }
    }
}