@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

.p-module {
    &__index {
        padding: 40px 0;
        background: $c-bg;
    }
    &__content {
        padding: 50px 0;
        &__item {
            margin-top: 30px;
            border: 1px solid #ccc;
            padding: 20px 10px 10px;
        }
    }
    pre {
        font-family: "Yu Gothic Medium";
        display: block;
        background: #212835;
        border-radius: 6px;
        overflow: hidden;
        padding: 30px 10px 20px;
        position: relative;
        white-space: pre;
        white-space: pre-wrap;
        word-wrap: break-word;
        &.html, &.css{
            &::before {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                color: #fff;
                padding: 7px 8px 4px 11px;
                font-size: 1.4rem;
                font-weight: bold;
            }
        }
        &.html {
            &::before {
                content: 'HTML';
                background: $c-red;
            }
        }
        &.css {
            &::before {
                content: 'CSS';
                background: $c-accent;
            }
        }
    }
    code {
        display: block;
        font-family: "Yu Gothic Medium";
        color: #fff;
        line-height: 1.8;
        font-size: 1.5rem;
    }
}

