@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 海外関係会社
.p-overseas_relative {
  &__icons {
    display: flex;
    &__item {
        &:nth-child(n +2) {
            margin-left: 15px;
        }
        img {
            max-height: 40px;
            width: auto;
        }
        a {
            transition: $transition;
            &:hover {
                opacity: 0.75;
            }
        }
    }
    @include sp {
        &__item {
            img {
                max-height: 30px;
            }
        }
    }
  }
  .m-image-text {
    img {
        max-width: 100%;
        width: auto;
    }
  }
}