@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 技術・事例
.p-technology_cases {
    &__links {
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        &__item {
            width: calc((100% - 3.3% * 2) / 3);
            margin-right: 3.3%; //40px
            position: relative;
            &:nth-child(3n) {
                margin-right: 0;
            }
            .img {
                overflow: hidden;
                img {
                    transition: $transition;
                }
            }
            .ttl {
                font-size: 2.2rem;
                font-weight: 500;
                color: #fff;
                text-align: center;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 0 20px 25px;
                z-index: 1;
            }
            .link {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 5;
            }
            @include pc {
                &:hover {
                    .img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
        @include sp {
            margin-top: 30px;
            &__item {
                width: 100%;
                margin-right: 0;
                &:nth-child(3n) {
                    margin-right: 0;
                }
                &:nth-child(n + 2) {
                    margin-top: 15px;
                }
                .ttl {
                    font-size: 1.8rem;
                    padding: 20px;
                    text-align: left;
                    display: flex;
                    align-items: center;
                    top: 0;
                }
            }
        }
    }
}