@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// ソリューショントップ
.p-solutions {
    &__lead {
        position: relative;
        z-index: 1;
        &__img {
            img {
                width:100%;
                min-height: 375px;
                max-height: 460px;
                object-fit:cover;
            }
        }
        &__inner {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translate(0, -50%);
        }
        &__ttl {
            max-width: 450px;
            margin: 0 auto 50px;
        }
        @include sp {
            &__img {
                img {
                    max-height: 375px;
                }
            }
            &__ttl {
                max-width: 270px;
                margin: 0 auto 30px;
            }
        }
    }
    .m-col2__item {
        @include sp {
            &:nth-child(2) {
                margin-top: 15px;
            }
        }
    }
}