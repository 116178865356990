@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// オープンイノベーション
.p-co-univ {
    &__flex {
        display: flex;
        &__item {
            width: calc((100% - 60px)/4);
            margin-right: 20px;
            &:nth-child(4n) {
                margin-right: 0;
            }
            .m-noimage-index.m-noimage-index--col4 {
                .m-noimage-index__item {
                    width: 100%;    
                    margin-right: 0;
                }
            }
        }
        @include sp {
            display: block;
            &__item {
                width: 100%;
                margin-right: 0px;
            }
        }
    }
}