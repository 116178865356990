@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

.l-basic-block {
    // パンくず調整
    .l-breadcrumb {
        &__list {    
            &__item {
                margin-left: 30px;        
                font-size: 1.2rem;
                line-height: 1.8;
                color: $c-sub;
                position: relative;
                &:nth-child(1) {
                    padding-left: 15px;
                    margin-left: 0;
                    &::before {
                        display: none;
                    }
                }
                &:nth-child(n + 2) {
                    margin-top: 0;
                    padding-left: 0;
                }
            }
        }
    }


    // 画像
    p > img {
        max-width: 100%;
        width: auto;
        margin: 0 auto;
    }
    figure.mt-figure {
        text-align: center;
        margin: 0 auto;
        display: block !important;
        img {
            max-width: 100%;
            width: auto;
            margin: 0 auto;
        }
        figcaption {
            margin-top: 15px;
            font-size: 1.4rem;
            color: #666;
            line-height: 1.6;
        }
    }

    // 見出し
    h5 {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.4;
        margin-top: 50px;
        + * {
            margin-top: 25px;
        }
        @include sp {
            font-size: 1.7rem;
            margin-top: 35px;
            + * {
                margin-top: 15px;
            }
        }
    }

    h4 {
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 1.4;
        margin-top: 60px;
        + * {
            margin-top: 30px;
        }
        @include sp {
            font-size: 1.8rem;
            margin-top: 40px;
            + * {
                margin-top: 20px;
            }
        }
    }

    h3 {
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 1.4;
        padding-left: 15px;
        border-left: 4px solid $c-accent;
        margin-top: 60px;
        + * {
            margin-top: 35px;
        }
        @include sp {
            font-size: 2rem;
            padding-left: 10px;
            margin-top: 40px;
            border-left: 3px solid $c-accent;
            + * {
                margin-top: 30px;
            }
        }
    }

    h2 {
        font-size: 3.2rem;
        font-weight: 500;
        line-height: 1.4;
        border-top: 2px solid #e5e5e5;
        padding: 70px 0 0;
        position: relative;
        margin-top: 100px;
        &::before {
            position: absolute;
            content: "";
            display: block;
            top: -2px;
            left: 0;
            width: 200px;
            height: 4px;
            background: -webkit-linear-gradient(
                0deg,
                rgb(0, 45, 120) 0%,
                rgb(0, 86, 122) 50%,
                rgb(0, 130, 125) 100%
            );
        }
        + * {
            margin-top: 60px;
        }
        @include sp {
            font-size: 2.2rem;
            padding: 40px 0 0;
            margin-top: 60px;
            &::before {
                width: 100px;
            }
            + * {
                margin-top: 30px;
            }
        }
    }

    h1 {
        font-size: 4rem;
        font-weight: 500;
        @include sp {
            font-size: 2.4rem;
        }
    }
    // 打消し用スタイル
    .m-h2--gradient {
        font-size: 3.2rem;
        line-height: 1.4;
        border-top: none;
        padding: 0;
        position: inherit;
        margin-top: inherit;
        &::before {
            display: none;
        }
    }
    .m-h3--gradient {
        font-size: 2.6rem;
        line-height: 1.4;
        font-weight: 500;
        padding-left: 0;
        border-left: 0;
        &__inner {
            background: $c-accent;
            background: -webkit-linear-gradient( 45deg, rgb(1,60,136) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);
            background-clip: text;
            color: transparent;
        }
        @include sp {
            font-size: 2rem;
            padding-left: 0;
            border-left: 0;
        }
    }
    .m-h3--new {
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 1.4;
        text-indent: 50px;
        position: relative;
        padding-left: 0;
        border-left: 0;
        &::before {
            position: absolute;
            content: "";
            top: 8px;
            left: 0px;
            width: 40px;
            height: 21px;
            background: url(/assets/img/common/icon_new.png) center/contain no-repeat;
        }
        + * {
            margin-top: 30px;
        }
        @include sp {
            font-size: 2rem;
            &::before {
                top: 4px;
            }
            + * {
                margin-top: 20px;
            }
        }
    }
    .l-products__contact {
        &__txtbox {
            .ttl {
                line-height: inherit;
                border-top: none;
                padding: 0;
                position: relative;
                margin-top: 0;
                &::before {
                    display: none;
                }
            }
        }
    }
    

    // テキストブロック
    p {
        font-size: 1.6rem;
        line-height: 2;
        + p, + .m-text--biggest, + .m-text--bigger, + .m-text--big, + .m-text--normal, + .m-text--small, + .m-text--small, + .m-text--smallest {
            margin-top: 20px;
        }
    }
    // テキストブロック調整
    .m-copy--large {
        font-size: 3rem;
        line-height: 1.6;
        @include sp {
            font-size: 2.2rem;
        }
    }
    .m-copy--normal {
        font-size: 2.2rem;
        line-height: 1.6;
        @include sp {
            font-size: 1.8rem;
        }
    }
    .m-copy--small {
        font-size: 1.5rem;
        line-height: 1.5;
    }


    // テキストブロック 箇条書きリスト
    ul {
        li {
            position: relative;
            font-size: 1.6rem;
            line-height: 1.6;
            padding-left: 20px;
            &::before {
                position: absolute;
                content: '';
                top: 10px;
                left: 5px;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: #666666;
            }
            &:nth-child(n + 2) {
                margin-top: 15px;
            }
            ul {
                li {
                    padding-left: 20px;
                    margin-top: 10px;
                    font-size: 1.5rem;
                    color: $c-sub;
                    position: relative;
                     &::before {
                        position: absolute;
                        content: '';
                        top: 10px;
                        left: 5px;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background: $c-accent;
                    }
                }
            }
        }
    }

    // テキストブロック 番号書きリスト
    ol {
        li {
            position: relative;
            font-size: 1.6rem;
            line-height: 1.6;
            padding-left: 20px;
            counter-increment: list-num;
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: counter(list-num) '.';
            }
            &:nth-child(n + 2) {
                margin-top: 15px;
            }
            ol {
                li {
                    padding-left: 20px;
                    margin-top: 10px;
                    font-size: 1.5rem;
                    color: $c-sub;
                    counter-increment: list-inner-num-1;
                    position: relative;
                    &::before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: counter(list-inner-num-1) '.';
                    }
                    ol {
                        li {
                            padding-left: 20px;
                            margin-top: 10px;
                            font-size: 1.5rem;
                            color: $c-sub;
                            counter-increment: list-inner-num-2;
                            position: relative;
                            &::before {
                                position: absolute;
                                top: 0;
                                left: 0;
                                content: counter(list-inner-num-2) '.';
                            }
                        }
                    }
                }
            }
        }
    }

    // リスト打消し用
    .m-gallery {
        &__sub {
            &__item {
                &::before {
                    display: none;
                }
                padding-left: 0;
            }
        }
    }
    .m-ac01 {
        &__item {
            padding-left: 0;
            &::before {
                display: none;
            }
            &:nth-child(n+2) {
                margin-top: 0;
            }
        }
    }
    .m-ac02 {
        &__item {
            padding-left: 0;
            &::before {
                display: none;
            }
            &:nth-child(n+2) {
                margin-top: 0;
            }
        }
    }
    .m-tabtext {
        &__index, &__content {
            &__item {
                padding-left: 0;
                &::before {
                    display: none;
                }
                &:nth-child(n+2) {
                    margin-top: 0;
                }
            }
        }
    }
    .m-list--dot {
        &__item {
            position: relative;
            font-size: 1.6rem;
            line-height: 1.6;
            padding-left: 20px;
            &::before {
                position: absolute;
                content: '';
                top: 13px;
                left: 5px;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: #666666;
            }
            &:nth-child(n + 2) {
                margin-top: 15px;
            }
            &__inner {
                &__item {
                    padding-left: 20px;
                    margin-top: 10px;
                    font-size: 1.5rem;
                    color: $c-sub;
                    position: relative;
                     &::before {
                        position: absolute;
                        content: '';
                        top: 10px;
                        left: 5px;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background: $c-accent;
                    }
                }
            }
        }
        + .m-text-link {
            .m-text-link__link {
                &::before {
                    left: 4px;
                }
            }
        }
    }
    .m-list--num {
        &__item {
            position: relative;
            font-size: 1.6rem;
            line-height: 1.6;
            padding-left: 20px;
            counter-increment: list-num;
            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                width: inherit;
                height: inherit;
                background: inherit;
                content: counter(list-num) '.';
            }
            &:nth-child(n + 2) {
                margin-top: 15px;
            }
            &__inner {
                &__item {
                    padding-left: 20px;
                    margin-top: 10px;
                    font-size: 1.5rem;
                    color: $c-sub;
                    counter-increment: list-inner-num;
                    position: relative;
                    &::before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: inherit;
                        height: inherit;
                        background: inherit;
                        content: counter(list-inner-num) '.';
                    }
                }
            }
        }
    }
    // 製品詳細ページナビゲーションのリスト打消し用
    .p-products_detail {
        &__nav {
            &__item {
                padding-left: 0;
                &::before {
                    display: none;
                }
            }
            @include pcmin1000 {
                &__item {
                    &:nth-child(n + 2) {
                        margin-top: 0;
                    }
                }
            }
            @include sp {
                &__item {
                    &:nth-child(n + 2) {
                        margin-top: 0;
                    }
                }
            }
        }
    }
    // 用語集「その他の用語集」のリスト打消し用
    .p-words-detail__links {
        &__item {
            padding-left: 0;
            &::before {
                display: none;
            }
            &:nth-child(n + 2) {
                margin-top: 0;
            }
        }
        @include sp {
            &__item {
                &:nth-child(n + 2) {
                    margin-top: 15px;
                }
            }
        }
    }
    .m-link-banner {
        &__item {
            padding-left: 0;
            &::before {
                display: none;
            }
            &:nth-child(n + 2) {
                margin-top: 0;
            }
        }
    }


    // ハイパーリンクテキスト
    p {
        a {
            color: #002d78;
            border-bottom: 1px solid #002d78;
            transition: $transition;
            &:hover {
                border-color: rgba(0,0,0,0);            
            }
        }
    }
    // ハイパーリンク打消し用
    .m-text-link {
        &__link {
            border-bottom: none;
            color: $c-base;
            &::before {
                top: 11px;
            }
            &:hover {
                color: $c-accent;
            }
            &.newwindow::after {
                bottom: 9px;
            }
            &.pdf::before, &.xls::before, &.doc::before, &.dl::before {
                top: 8px;
            }
        }
    }

    // 羅列
    hr {
        color: #ccc;
    }

    // 2カラム
    .mt-be-columns {
        display: flex;
        flex-wrap: wrap;
        .mt-be-column {
            width: calc((100% - 50px)/2);
            margin-right: 50px;
            &:nth-child(2n) {
                margin-right: 0;
            }
            &:nth-child(n + 3) {
                margin-top: 50px;
            }
        }
        @include sp {
            flex-direction: column;
            .mt-be-column {
                width: 100%;
                margin-right: 0;
                &:nth-child(n + 2) {
                    margin-top: 30px;
                }
                &:nth-child(n + 3) {
                    margin-top: 30px;
                }
            }
        }
    }

    // やさしい科学バナーの調整

    .l-products__banner {
        &__item {
            .ttl {
                color: $c-accent;
                line-height: initial;
                border-top: none;
                padding: 0;
                margin: 0;
                &::before {
                    display: none;
                }
            }
        }
    }
}