@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

.p-cookie {
    #ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
        margin-top: 25px;
        color: $c-accent;
        border: 1px solid $c-accent;
        height: auto;
        white-space: normal;
        word-wrap: break-word;
        padding: 12px 25px;
        font-size: 1.6rem;
        line-height: 1.2;
        cursor: pointer;
        transition: 0.1s ease;
        &:hover {
            color: #fff;
            background-color: $c-accent;
        }
    }
}