@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 製品情報TOP
.p-products {
    background: #e9e9f1;
    .l-lower__mainv {
        min-height: 500px;
        max-height: 600px;
        &__img {
            img {
                min-height: 500px;
                max-height: 600px;
            }
        }
        @include sp {
            min-height: inherit;
            max-height: 400px;
            img {
                min-height: inherit;
                max-height: 400px;
            }
        }
    }
    &__index {
        &__list {
            display: flex;
            max-width: 1600px;
            margin: 0 auto;
            &__item {
                width: calc(100% / 4);
                border-right: 1px solid #e0e0e0;
                position: relative;
                background: #fff;
                @include pc {
                    &:hover {
                        .img {
                            img {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
                &:last-child {
                    border-right: none;
                }
                .img {
                    overflow: hidden;
                    img {
                        transition: $transition;
                    }
                }
                .ttl {
                    font-size: 2.2rem;
                    text-align: center;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 20px;
                    z-index: 1;
                    pointer-events: none;
                }
                .link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    // background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 50%);
                }
            }
        }
        @include sp {
            &__list {
                display: flex;
                flex-wrap: wrap;
                &__item {
                    width: 50%;
                    &:nth-child(2n) {
                        border-right: none;
                    }
                    &:nth-child(n + 3) {
                        border-top: 1px solid #e0e0e0;
                    }
                    .ttl {
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }
}
