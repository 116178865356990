@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

// IR情報
.p-ir {
    .l-lower__mainv {
        min-height: 500px;
        &__img {
            img {
                min-height: 500px;
            }
        }
        @include sp {
            min-height: inherit;
            &__img {
                img {
                    min-height: inherit;
                }
            }
        }
    }
    &__stock {
        @include pc {
            position: absolute;
            right: 80px;
            top: 50%;
            transform: translate(0, -50%);
            background: #fff;
            padding: 35px 40px;
            max-width: 440px;
        }
        @include sp {
            padding: 30px 25px 40px;
        }
        .m-text-link {
            margin-top: 20px;
        }
        .m-btn {
            margin-top: 15px;
        }
    }
    &__ttl__wrap {
        position: relative;
        text-align: center;
        @include sp {
        }
        .m-sec__ttl {
            &__color {
                font-family: Noto Sans JP;
            }
        }
        .m-btn--arrow {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
            margin-top: 0;
        }
    }
    .m-news {
        margin-top: 30px;
        &__item {
            padding: 15px 0;
            border: none;
        }
        @include sp {
            margin-top: 0;
        }
    }
    &__report {
        display: flex;
        justify-content: space-between;
        // @include pcmin1000 {
        //     display: block;
        // }
        @include sp {
            display: block;
        }
        &__body {
            width: calc((100% - 50px) / 2);
            padding: 100px 0 30px;
            &:first-child {
                background: url("/assets/img/ir/ir_img01.jpg") center / cover no-repeat;
            }
            &:last-child {
                background: url("/assets/img/ir/ir_img02.jpg") center / cover no-repeat;
            }
            // @include pcmin1000 {
            //     width: 100%;
            //     min-height: 275px;
            //     display: flex;
            //     flex-direction: column;
            //     justify-content: center;
            //     align-items: center;
            //     padding: 40px 0;
            //     &:first-child {
            //         background: url("/assets/img/ir/ir_img01_sp.jpg") center / cover no-repeat;    
            //     }
            //     &:last-child {
            //         background: url("/assets/img/ir/ir_img02_sp.jpg") center / cover no-repeat;
            //         margin-top: 15px;
            //     }
            // }
            @include sp {
                width: 100%;
                min-height: 275px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 40px 0;
                &:first-child {
                    background: url("/assets/img/ir/ir_img01_sp.jpg") center / cover no-repeat;    
                }
                &:last-child {
                    background: url("/assets/img/ir/ir_img02_sp.jpg") center / cover no-repeat;
                    margin-top: 15px;
                }
            }
        }
        &__ttl {
            color: #fff;
  
          @include pc {
            font-size: 3.2rem;
          }
  
          @include sp {
            font-size: 2.4rem;
          } 
        }
  
        &__txt {
            color: #fff;
            line-height: 2;
  
            @include pc {
                margin-top: 30px;
            }
  
            @include sp {
                margin-top: 20px;
            }
        }
        .m-link {
            color: #fff;
            border-color: #fff;
        }

        .m-btn {
            > .m-btn--normal {
                background: #fff;
                box-shadow: none;
                border-color: #fff;
                color: $c-accent;
                
                &::before {
                    display: none;
                    box-shadow: none;
                }
                &:hover {
                    background-color: $c-accent;
                    border-color: $c-accent;
                    color: #fff;
                }
                @include sp {
                    min-width: 220px;
                    width: 220px;
                }
            }
        }
    }
    &__list {
        margin-top: 80px;
        @include sp {
            margin-top: 60px;
        }
        @include pc {
          display: flex;
        }
  
        &__item {
          background-color: #f0f3f7;
  
          @include pc {
            padding: 36px 3.3% 60px; //1200pxの時40px
            width: 33.05333333%;
          }
  
          @include sp {
            padding: 36px 15px 50px;
          }
  
          &__icon {
            width: 56px;
            height: 50px;
            margin: 0 auto 25px;
          }
          
          .m-text-link {
            margin-top: 25px;
            + .m-text-link {
                margin-top: 10px;
            }
          }
  
          + .p-ir__list__item {
            @include pc {
              margin-left: 0.42%; //1200pxの時5px
            }
  
            @include sp {
              margin-top: 10px;
            }
          }
        }
    }
}


// IRトップメッセージ
.p-ir {
    &__h2 {
        font-size: 2.2rem;
        line-height: 1.4;
        &__inner {
            background: $c-accent;
            background: -webkit-linear-gradient( 0deg, rgb(0,45,120) 0%, rgb(0,86,122) 50%, rgb(0,130,125) 100%);
            background-clip: text;
            color: transparent;
        }
        @include sp {
            font-size: 1.6rem;
        }
    }
    &__lead {
        font-family: "Noto Serif JP";
        font-size: 2.8rem;
        line-height: 2;
        margin: 45px 0 75px;
        letter-spacing: 0.04em;
        @include sp {
            margin: 30px 0 45px;
            font-size: 1.9rem;
        }
    }
}

#ir_library {
    table {
        img {
            width: auto;
            vertical-align: middle;
        }
    }
    .selectapp {
        position: relative;
        select {
          height: 60px;
          width: 100%;
          max-width: 300px;
          padding: 0 20px;
          border-radius: 8px;
          background: #f5f6f9;
          border: 2px solid #d1d1d8;
          font-size: 1.6rem;
          outline-color: $c-accent;
          &.unselected {
            color: $c-sub;
          }
        }
        &::after {
          position: absolute;
          content: "";
          top: 50%;
          right: 35px;
          width: 15px;
          height: 10px;
          transform: translate(50%, -50%);
          background: url(/assets/img/common/icon_arrow_bottom.png)
            center/contain no-repeat;
          pointer-events: none;
        }
    }
    h3 {
        font-size: 3.2rem;
        font-weight: 500;
        line-height: 1.4;
        border-top: 2px solid #e5e5e5;
        padding: 70px 0 0;
        position: relative;
        margin: 100px 0 0 !important;
        &::before {
            position: absolute;
            content: "";
            display: block;
            top: -2px;
            left: 0;
            width: 200px;
            height: 4px;
            background: -webkit-linear-gradient(
                0deg,
                rgb(0, 45, 120) 0%,
                rgb(0, 86, 122) 50%,
                rgb(0, 130, 125) 100%
            );
        }
        + * {
            margin-top: 60px;
        }
        @include sp {
            font-size: 2.2rem;
            padding: 40px 0 0;
            margin-top: 60px !important;
            &::before {
                width: 100px;
            }
            + * {
                margin-top: 30px;
            }
        }
    }
    .dot-list {
        border-top: 1px solid #cccccc;
        li {
            position: relative;
            padding: 20px 0;
            border-bottom: 1px solid #cccccc;
            a {
                transition: $transition;
                @include pc {
                    &:hover {
                        color: $c-accent;
                    }
                }
            }
            &.teisei {
                margin-left: 0;
                padding-left: 40px;
                background-position-x: 20px;
            }
            @include sp {
                padding: 15px;
            }
        }
    }
}