@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// JEOL-Nikon CLEMソリューションセンター
.p-jeol_nikon_clem {
    &__border {
        position: relative;
        &::before {
            position: absolute;
            content: '';
            left: 50%;
            top: -35px;
            width: 1px;
            height: 70px;
            background: $c-accent;
        }
        @include sp {
            &::before {
                top: -25px;
                height: 50px;
            }
        }
    }
}