@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 環境トップ
.p-sustainability__under {
  &--environment {
    background-image: url(/assets/img/sustainability/environment/environment_mv_icon.png);
    background-repeat: no-repeat;

    @include pc {
      background-position: top calc(50% - 10px) right calc(50% - 230px);
    }

    @include sp {
      background-position: top calc(50% - 20px) right calc(50% - 108px);
    }
  }
}
