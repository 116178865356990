@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 社会トップ
.p-sustainability__under {
  &--social {
    background-image: url(/assets/img/sustainability/social/social_mv_icon.png);
    background-repeat: no-repeat;

    @include pc {
      background-position: top calc(50% - 10px) right calc(50% - 180px);
    }

    @include sp {
      background-position: top calc(50% - 20px) right calc(50% - 110px);
    }
  }
}
