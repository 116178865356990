@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

.l-main {
    .m-text--lead {
        font-size: 1.8rem;
        line-height: 2;
        @include sp {
            font-size: 1.6rem;
        }
    }
    .m-vam {
        vertical-align: middle;
    }
    .m-inline-block {
        display: inline-block;
    }
    .m-text--image {
        display: inline-block;
    }
}