@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 国内拠点
.p-corporate-branches {
    .m-image-text {
        .m-h4 {
            + * {
                margin-top: 20px;
            }
            &:nth-of-type(n + 2) {
                margin-top: 40px;
            }
        }
        @include sp {
            .m-h4 {
                &:nth-of-type(n + 2) {
                    margin-top: 30px;
                }
            }   
        }
    }
}