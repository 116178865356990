@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

.l-main, .content_data {
	* {
		@for $i from 0 through 20 {
			$v: $i * 5;

			&.m-image-width-#{$v} {
				width: $v * 1%;
			}
	
			@include pcmin1200 {
				&.m-image-width-pcmin-#{$v} {
					width: $v * 1%;
				}
			}

			@include sp {
				&.m-image-width-sp-#{$v} {
					width: $v * 1%;
				}
			}
		}
	}
}