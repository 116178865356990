@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

.l-main, .content_data {
	/*
	* margin-top"
	******************************/
	* {
		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-mt#{$v}px {
				margin-top: $v * 1px;
			}
		}

		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-mt#{$v}px-sp {
				@include sp {
					margin-top: $v * 1px;
				}
			}
		}
		&.m-mt3px-sp {
			@include sp {
				margin-top: 3px;
			}
		}

		// カスタムブロックスペーサー用
		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-spacer-mt#{$v}px {
				margin-top: $v * 1px;
				height: 0;
			}
		}

		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-spacer-mt#{$v}px-sp {
				@include sp {
					margin-top: $v * 1px;
					height: 0;
				}
			}
		}
		&.m-spacer-mt3px-sp {
			@include sp {
				margin-top: 3px;
				height: 0;
			}
		}
	}

	/*
	* マイナスマージン margin-top"
	******************************/
	* {
		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-mt#{$v}px-minus {
				margin-top: -$v * 1px;
			}
		}

		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-mt#{$v}px-minus-sp {
				@include sp {
					margin-top: -$v * 1px;
				}
			}
		}
	}

	/*
	* マイナスマージン margin-left"
	******************************/
	* {
		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-ml#{$v}px-minus {
				margin-left: -$v * 1px;
			}
		}

		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-ml#{$v}px-minus-sp {
				@include sp {
					margin-left: -$v * 1px;
				}
			}
		}
	}

	/*
	* margin-bottom"
	******************************/
	* {
		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-mb#{$v}px {
				margin-bottom: $v * 1px;
			}
		}

		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-mb#{$v}px-sp {
				@include sp {
					margin-bottom: $v * 1px;
				}
			}
		}
	}

	/*
	* padding-top"
	******************************/
	* {
		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-pt#{$v}px {
				padding-top: $v * 1px;
			}
		}

		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-pt#{$v}px-sp {
				@include sp {
					padding-top: $v * 1px;
				}
			}
		}
	}

	/*
	* padding-bottom"
	******************************/
	* {
		@for $i from 0 through 30 {
			$v: $i * 5;
			&.m-pb#{$v}px {
				padding-bottom: $v * 1px;
			}
		}
		@for $i from 0 through 30 {
			$v: $i * 5;
			&.m-pb#{$v}px-sp {
				@include sp {
					padding-bottom: $v * 1px;
				}
			}
		}
	}


	/*
	* padding-left"
	******************************/
	* {
		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-pl#{$v}px {
				padding-left: $v * 1px;
			}
		}

		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-pl#{$v}px-sp {
				@include sp {
					padding-left: $v * 1px;
				}
			}
		}
	}

	/*
	* padding-right"
	******************************/
	* {
		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-pr#{$v}px {
				padding-right: $v * 1px;
			}
		}

		@for $i from 0 through 30 {
			$v: $i * 5;

			&.m-pr#{$v}px-sp {
				@include sp {
					padding-right: $v * 1px;
				}
			}
		}
	}

	/*
	*  余白打消し用"
	******************************/
	.m-mt0 { margin-top: 0 !important; }
	.m-mb0 { margin-bottom: 0 !important; }
	.m-pt0 { padding-top: 0 !important; }
	.m-pb0 { padding-bottom: 0 !important; }

	.m-mt0-sp { 
		@include sp {
			margin-top: 0 !important; 
		}
	}
	.m-mb0-sp { 
		@include sp {
			margin-bottom: 0 !important;
		}
	 }
	.m-pt0-sp { 
		@include sp {
			padding-top: 0 !important;
		}
	 }
	.m-pb0-sp { 
		@include sp {
			padding-bottom: 0 !important;
		}
	 }


	.m-width100 {
		width: 100% !important;
	}

	.m-border {
		border: 1px solid #ccc;
	}
}
@media print {
	.l-header, .l-header__global-modal {
		display: none;
	  }
	.l-main .m-h2--gradient__inner,
	.l-main .m-h3--gradient__inner {
		color: #002d78;
	}
	.l-main .m-text--lead {
		margin-top: 20px;
	}
	.l-lower__section:nth-of-type(n + 2) {
		margin-top: 40px;
	}
}