@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

.p-news {
    &__banner {
        border-top: 1px solid #eeeeee;
        margin-top: 100px;
        padding-top: 60px;
        &__list {
            display: flex;
            flex-wrap: wrap;
            &__item {
                width: calc((100% - 50px)/2);
                margin-right: 50px;
                box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.12);
                border-left: 2px solid #57c5cf;
                border-image: -webkit-linear-gradient(
                    90deg,
                    rgb(87, 197, 207) 0%,
                    rgb(73, 169, 214) 24%,
                    rgb(58, 140, 220) 50%,
                    rgb(49, 118, 206) 79%,
                    rgb(39, 96, 191) 100%
                );
                border-image-slice: 1;
                position: relative;
                transition: $transition;
                background: #fff;
                display: flex;
                align-items: center;
                &:nth-child(2n) {
                    margin-right: 0;
                }
                &:hover {
                    background: #f4f4f8;
                    box-shadow: none;
                    .p-news__banner__list__item__ttl {
                        color: $c-accent;
                    }
                }
                &__ttl {
                    font-size: 1.8rem;
                    font-weight: 500;
                    line-height: 1.6;
                    width: calc(100% - 200px);
                    padding: 35px;
                }
                &__image {
                    width: 200px;
                }
                &__link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }
        @include sp {
            margin-top: 60px;
            padding-top: 50px;
            &__list {
                &__item {
                    width: 100%;
                    margin-right: 0;
                    &:nth-child(n + 2) {
                        margin-top: 15px;
                    }
                    &__ttl {
                        font-size: 1.6rem;
                        width: calc(100% - 100px);
                        padding: 25px 20px;
                    }
                    &__image {
                        width: 100px;
                    }
                }
            }
        }
    }
    .m-newstab {
        &__content {
            margin-top: 10px;
            .m-text--normal {
                margin-top: 30px;
            }
        }
        @include sp {
            &__content {
                margin-top: 0;
            }
        }
    }
}