@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

.p-top {
    .l-main__inner {
        max-width: 1600px;
        padding: 0 6%;
        &.no-pd {
            padding: 0;
        }
        @include pcmin1260 {
            padding: 0 30px;
        }
    }
    &__technology, &__seminar {
        // slick 設定
        .slick-slider {
            position: relative;
            &.is-few, &.is-few-pcmin {
                &::after {
                    display: none;
                }
                @include pc {
                    .slick-track {
                        width: 100% !important;
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
            &::after {
                position: absolute;
                content: '/';
                font-size: 1.5rem;
                font-weight: 500;
                color: #222;
                display: block;
                top: 97.5%;
                right: 120px;
            }
        }
        .slick-slide { //1200pxの時 20px
            height: auto;
            margin-right: 1.6666666666666667vw;
            margin-left: 1.6666666666666667vw;
        }
        .slick-list {
            overflow: inherit;
            @include pcmin1600 {
                //variableWidth: false時の余白
                margin-right: -20px;
                margin-left: -20px;
            }
        }
        .slick-track {
            display: flex;
            height: auto;
            padding-bottom: 20px;
        }
        .slick-prev, .slick-next {
            top: 100%;
            position: absolute;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #fff;
            border: 1px solid $c-accent;
            z-index: 5;
            transition: all 0.2s linear;
            opacity: 1;
            &::before {
                content: '';
                background: url(/assets/img/common/icon_arrow_right.png) center/contain no-repeat;
                width: 8px;
                height: 13px;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                transition: $transition;
            }
            &::after {
                width: 50px;
                text-align: center;
                font-size: 1.5rem;
                font-weight: 500;
                color: #222;
                display: block;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
            }
            &.slick-disabled {
                pointer-events: none;
                opacity: 0.5;
                &::before {
                    opacity: 0.5;
                }
                &::after {
                    opacity: 1;
                    color: #aaaaaa;
                }
            }
        }
        .slick-prev {
            left: inherit;
            right: 185px;
            &::before {
                transform: translate(-50%, -50%) rotate(-180deg);
                left: 50%;
            }
            &::after {
                content: "PREV";
                left: calc(100% + 10px);
                text-align: left;
            }
            &:hover {
                &::before {
                    transform: translate(calc(-50% - 5px), -50%) rotate(-180deg);
                }   
            }
        }
        .slick-next {
            right: 20px;
            &::before {
                top: 50%;
                transform: translate(-50%, -50%);
                left: 50%;
            }
            &::after {
                content: "NEXT";
                right: calc(100% + 10px);
            }
            &:hover {
                &::before {
                    transform: translate(calc(-50% + 5px), -50%);
                }   
            }
        }
        @include sp {
            .slick-slider {
                &::after {
                    display: none;
                }
                &.is-few-sp {
                    .slick-slide {
                        width: 100%;
                        margin-left: 0;
                        margin-right: 0;    
                    }
                    .slick-track {
                        width: 100% !important;
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
            .slick-track {
                padding-bottom: 0;
            }
            .slick-slide  {
                margin-right: 10px;
                margin-left: 10px;
            }
            .slick-prev, .slick-next {
                display: none;
            }
        }
        // slick 設定ここまで
    }

    // TOP共通パーツ
    &__ttl__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
        @include sp {
            margin-bottom: 40px;
            display: block;
        }
    }
    &__ttl {
        line-height: 1.2;
        &__color {
            background: $c-accent;
            background: -webkit-linear-gradient( 45deg, rgb(1,60,136) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);
            background-clip: text;
            color: transparent;
            font-feature-settings : inherit;
            font-size: 5.2rem;
            font-family: Oswald-Regular;
            letter-spacing: 0.08rem;
        }
        &__thin {
            font-family: Oswald-Light;
        }
        &__sub {
            margin-left: 15px;
            background-clip: none;
            color: $c-base;
            font-size: 1.6rem;
        }
        @include sp {
            &__color {
                font-size: 4.2rem;
            }
            &__sub {
                margin-left: 0;
                margin-top: 15px;
                display: block;
            }
        }
    }
    &__btn {
        &__link {
            font-size: 1.6rem;
            padding-right: 55px;
            position: relative;
            white-space: nowrap;
            transition: $transition;
            &::before {
                position: absolute;
                content: "";
                display: block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: $c-accent;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);
            }
            &::after {
                position: absolute;
                content: "";
                width: 8px;
                height: 12px;
                top: 50%;
                right: 15px;
                transform: translate(0, -50%);
                background: url(/assets/img/common/icon_arrow_right_white.png) center/contain no-repeat;
                transition: $transition;
            }
            &:hover {
                color: $c-accent;
                &::after {
                    right: 11px;
                }
            }
        }
        @include sp {
            margin-top: 40px;
            text-align: right;
        }
    }

    // メインビジュアル
    &__mainv {
        position: relative;
        // Slick設定
        .slick-slider {
            margin-bottom: 0;
        }
        .slick-slider .slick-track {
            display: flex;
        }
        .slick-initialized .slick-slide {
            display: flex;
            justify-content: flex-end;
            height: auto;
            align-items: flex-end;
        }
        &__control {
            display: flex;
            justify-content: flex-end;
            svg {
                vertical-align: inherit;
            }
            .slick-dots { 
                width: auto;
                bottom: 35px;
                text-align: right;
                padding-right: 30px;
                z-index: 10;
                li {
                    position: relative;
                    width: 30px;
                    height: 30px;
                    &:not(:last-child) {
                        margin-right: 5px;
                    }
                    button {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                        font-size: 0;
                        &::before {
                            position: absolute;
                            content: '';
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            background: #fff;
                        }
                    }
                }
            }
        }
        // Slick設定ここまで
        &__list {
            position: relative;
            &__item {        
                height: calc(100vh - 90px);
                height: calc((var(--vh, 1vh) * 100) - 90px);
                position: relative;
                &::after {
                    position: absolute;
                    content: '';
                    right: 0;
                    bottom: 0;
                    background: -webkit-linear-gradient( 76deg, rgb(1,60,136) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);
                    width: 480px;
                    height: 280px;
                    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
                }
            } 
        }
        &__img {
            height: 100%;
            img {
                height: 100%;
                object-fit: cover;
                object-position: top; //70thで追加
            }
        }
        &__txtbox {
            position: absolute;
            left: 0;
            bottom: 0;
            margin-left: 50px;
            margin-bottom: 50px;
            .ttl {
                // font-size: 5.4rem;
                font-size: vwm(54px);
                font-weight: bold;
                color: #fff;
                line-height: 1.4;
                letter-spacing: 1rem;
                text-shadow: 0 0 15px rgba(#2055b5, 0.4);
            }
            .txt {
                margin-top: 20px;
                // font-size: 1.7rem;
                font-size: vwm(15px);
                color: #fff;
                letter-spacing: 0.05rem;
                line-height: 1.8;
                text-shadow: 0 0 15px #2055b5;
            } 
        }
        &__banner {
            position: absolute;
            right: 0;
            bottom: 0;
            margin-right: 40px;
            margin-bottom: 40px !important;
            z-index: 1;
            width: 45%;
            &__item {
                &__inner {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 0 5px;
                    &:hover {
                        .info {
                            .product-num {
                                .underline {
                                    &::before {
                                        transform: scaleX(1);
                                    }
                                }
                            }
                        }
                    }
                    .img {
                        width: 210px;
                        margin-right: 20px;
                        width: vwm(210px);
                        margin-right: vwm(20px);
                    }
                    .info {
                        width: calc(100% - 230px);
                        width: calc(100% - vwm(230px));
                        .tag {
                            display: inline-block;
                            padding: 8px 15px;
                            line-height: 1;
                            font-weight: 500;
                            font-family: Roboto-MediumItalic;
                            position: relative;
                            font-size: 1.6rem;
                            font-size: vwm(16px);
                            color: $c-accent;
                            &::before {
                                position: absolute;
                                content: '';
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background: #46d7fc;
                                transform: skewX(-10deg);
                            }
                            span {
                                position: relative;
                            }
                        }
                        .product-name {
                            font-size: 1.5rem;
                            font-size: vwm(15px);
                            font-weight: normal;
                            display: block;
                            color: #fff;
                            margin-top: 10px;
                        }
                        .product-num {
                            font-size: 2.5rem;
                            font-size: vwm(25px);
                            font-weight: bold;
                            color: #fff;
                            .underline {
                                @include link-underline {
                                    &::before {
                                        background: #fff;
                                    }
                                }
                            }
                        }
                    }
                    .link {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        cursor: pointer;
                    }
                }
            }
        }
       
        @include pcmin1600 {
            // Slick設定
            &__control {
                .slick-dots { 
                    bottom: vwm(35px);
                    padding-right: vwm(30px);
                    li {
                        width: vwm(30px);
                        height: vwm(30px);
                        &:not(:last-child) {
                            margin-right: vwm(5px);
                        }
                        button {
                            &::before {
                                width: vwm(5px);
                                height: vwm(5px);
                            }
                        }
                    }
                }
            }
            // Slick設定ここまで
            &__list {
                &__item {        
                    height: calc(100vh - vwm(90px));
                    height: calc((var(--vh, 1vh) * 100) - vwm(90px));
                    &::after { 
                        width: vwm(480px);
                        height: vwm(280px);
                    }
                } 
            }
            &__txtbox {
                margin-left: vwm(50px);
                margin-bottom: vwm(50px);
                .ttl {
                    font-size: vwm(54px);
                    letter-spacing: vwm(10px);
                }
                .txt {
                    margin-top: vwm(20px);
                    font-size: vwm(17px);
                    letter-spacing: vwm(1px);
                } 
            }
            &__banner {
                margin-right: vwm(40px);
                margin-bottom: vwm(40px) !important;
                &__item {
                    &__inner {
                        .img {
                            width: vwm(210px);
                            margin-right: vwm(20px);
                        }
                        .info {
                            .tag {
                                font-size: vwm(16px);
                                padding: vwm(8px) vwm(15px);
                            }
                            .product-name {
                                font-size: vwm(15px);
                                margin-top: vwm(10px);
                            }
                            .product-num {
                                font-size: vwm(25px);
                            }
                        }
                    }
                }
            }
        }
        @include sp {
            // Slick設定
            &__control {
                .slick-dots { 
                    bottom: 15px;
                    padding-right:15px;
                }
            }
            .slick-initialized .slick-slide {
                justify-content: center;
            }
            // Slick設定ここまで
            &__list {
                &__item {        
                    height: calc(100vh - 60px);
                    height: calc((var(--vh, 1vh) * 100) - 60px);
                    &::after { 
                        width: 250px;
                        height: 220px;
                    }
                } 
            }
            &__txtbox {
                position: absolute;
                top: 40px;
                left: 0;
                right: 0;
                bottom: inherit;
                margin-left: 0;
                margin-bottom: 0;
                .ttl {
                    font-size: 2.7rem;
                    text-align: center;
                    letter-spacing: 0.5rem;
                }
                .txt {
                    font-size: 1.3rem;
                    text-align: center;
                } 
            }
            &__banner {
                left: 0;
                margin-bottom: 35px !important;
                justify-content: center;
                width: 100%;
                &__item {
                    &__inner {                
                        padding: 0 15px;
                        .img {
                            width: 150px;
                            margin-right: 15px;
                        }
                        .info {
                            width: calc(100% - 165px);
                            .tag {
                                font-size: 1.4rem;
                                padding: 5px 10px;
                            }
                            .product-name {
                                font-size: 1.3rem;
                                margin-top: 10px;
                            }
                            .product-num {
                                font-size: 2.2rem;
                            }
                        }
                    }
                }
            }
        }
        // MV 70thボタン
        &__btn {
            max-width: 480px;
            width: 100%;
            letter-spacing: 5px;
            border: solid 3px #fff;
            color: #fff;
            &:hover {
                background-color: #fff;
                transition: all .2s linear;
                a.mv-btn {
                    color: #7db2e6;
                    
                }
            }
            a.mv-btn {
                display: block;
                padding: 20px;
                border-radius: 15px;
                font-size: 2.4rem;
                font-weight: 600;
                text-align: center;
                @include sp {
                    font-size: 1.8rem;
                }
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                    font-size: 1.8rem;
                }
                span {
                    &::after {
                        position: absolute;
                        content: "";
                        width: 12px;
                        height: 18px;
                        top: 50%;
                        right: 30px;
                        transform: translate(0, -50%);
                        background: url(/assets/img/common/icon_arrow_right_white.png) center / contain no-repeat;
                        transition: all .2s linear;
                    }
                }
            }
            &:hover {
                a.mv-btn {
                    span {
                        &::after {
                            background-image: none;
                            background-color: #7db2e6;
                            mask-image: url(/assets/img/common/icon_arrow_right.png);
                            mask-size: contain;
                            mask-repeat: no-repeat;
                            mask-position: center;
                            right: 25px;
                        }
                    }
                }
            }
            @include pc {
                position: absolute;
                left: 0;
                bottom: 0;
                margin-left: vwm(50px);
                margin-bottom: vwm(50px);
            }
            @include sp {
                display: block;
                max-width: 100%;
                position: relative;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                &__bg {
                    padding: 30px 6%;
                    margin-bottom: 0;
                    background-color: #7db2e6;
                }
            }
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                display: block;
                max-width: 100%;
                width: 75%;
                position: relative;
                text-align: center;
                margin-top: 15px;
                margin-left: auto;
                margin-right: auto;
                &__bg {
                    height: 100px;
                    padding: 30px 6%;
                    margin-bottom: 0;
                    background-color: #7db2e6;
                }
            }
        }
    }
    // 重要なお知らせ
    &__information {
        background: $c-bg;
        padding: 30px 0;
        &__inner {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        &__ttl {
            width: 220px;
            margin-right: 30px;
            font-size: 1.8rem;
            font-weight: bold;
            color: $c-red;
            text-align: center;
            .icon__important {
                padding-left: 45px;
                position: relative;
                &::before {
                    position: absolute;
                    content: '';
                    top: 50%;
                    left: 0px;
                    width: 30px;
                    height: 30px;
                    transform: translate(0, -50%);
                    background: url(/assets/img/top/icon_information.png) center/contain no-repeat;
                }
            }
        }
        &__content {
            width: calc(100% - 250px);
        }
        &__list {
            display: flex;
            flex-wrap: wrap;
            &__date {
                width: 110px;
                font-weight: normal;
                font-size: 1.5rem;
                color: $c-sub;
                &:nth-of-type(n + 2) {
                    margin-top: 10px;
                }
            }
            &__detail {
                width: calc(100% - 110px);
                &:nth-of-type(n + 2) {
                    margin-top: 10px;
                }
                &__link {
                    font-size: 1.6rem;
                    transition: $transition;
                    @include link-underline;
                    &:hover {
                        color: $c-accent;
                    }
                    &--sub {
                        color: $c-sub;
                        margin-left: 5px;
                    }
                    &.icon__zip, &.icon__pdf, &.icon__xls, &.icon__doc  {
                        display: inline-block;
                        .p-top__information__list__detail__link--sub {
                          &::before {
                            display: inline-block;
                            content: "";
                            width: 18px;
                            height: 17px;
                            margin-right: 5px;
                            margin-bottom: -3px;
                          }
                        }
                    }
                    &.icon__zip {
                        .p-top__information__list__detail__link--sub {
                            &::before {
                            background: url(/assets/img/common/icon_zip.png) center/contain no-repeat;
                            }
                        }
                    }
                    &.icon__pdf {
                        .p-top__information__list__detail__link--sub {
                            &::before {
                            background: url(/assets/img/common/icon_pdf.png) center/contain no-repeat;
                            }
                        }
                    }
                    &.icon__xls {
                        .p-top__information__list__detail__link--sub {
                            &::before {
                            background: url(/assets/img/common/icon_xls.png) center/contain no-repeat;
                            }
                        }
                    }
                    &.icon__doc {
                        .p-top__information__list__detail__link--sub {
                            &::before {
                            background: url(/assets/img/common/icon_doc.png) center/contain no-repeat;
                            }
                        }
                    }
                    &.icon__newwindow {
                        display: inline-block;
                        &::after {
                            display: inline-block;
                            content: "";
                            width: 13px;
                            height: 12px;
                            margin-left: 5px;
                            background: url(/assets/img/common/icon_newwindow.png) center/contain no-repeat;
                            transition: $transition;
                        }
                    }
                }
            }
        }
        @include sp {
            padding: 35px 0 50px;
            &__inner {
                display: block;
            }
            &__ttl {
                width: 100%;
                margin-right: 0;
            }
            &__content {
                width: 100%;
            }
            &__list {
                margin-top: 25px;
                &__date {
                    width: 100%;
                    &:nth-of-type(n + 2) {
                        margin-top: 25px;
                    }
                }
                &__detail {
                    margin-top: 15px;
                    width: 100%;
                    &:nth-of-type(n + 2) {
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    // メニュー
    &__index {
        padding: 90px 0;
        &__list {
            display: flex;
            flex-wrap: wrap;
            &__item {
                width: calc((100% - 2.8% * 3)/4);
                margin-right: 2.8%;
                position: relative;
                &:nth-child(4n) {
                    margin-right: 0;
                }
                &:nth-child(n + 5) {
                    margin-top: 40px;
                }
                &__img {
                    overflow: hidden;
                    border: 1px solid #d4d4d4;
                    img {
                        transition: $transition;
                    }
                 }
                 &__txt {
                     font-size: 1.6rem;
                     margin-top: 15px;
                     transition: $transition;
                     .newwindow {
                        position: relative;
                        padding-right: 18px;
                        &::after {
                            position: absolute;
                            content: "";
                            display: block;
                            bottom: 0;
                            right: 0;
                            width: 11px;
                            height: 11px;
                            transform: translate(0, -50%);
                            transition: $transition;
                            background: url(/assets/img/common/icon_newwindow.png) center/contain no-repeat;
                            opacity: 1;
                        }
                     }
                 }
                 &__link {
                     position: absolute;
                     content: '';
                     top: 0;
                     left: 0;
                     right: 0;
                     bottom: 0;
                 }
                @include pc {
                    &:hover {
                        .p-top__index__list__item__img {
                            img {
                                transform: scale(1.1);
                            } 
                         }
                         .p-top__index__list__item__txt {
                             color: $c-accent;
                         }
                    }
                }
            }
        }
        @include sp {
            padding: 60px 0;
            &__list {
                display: flex;
                flex-wrap: wrap;
                &__item {
                    width: calc((100% - 10px) / 2);
                    margin-right: 10px;
                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                    &:nth-child(n + 5) {
                        margin-top: 30px;
                    }
                    &:nth-child(n + 3) {
                        margin-top: 30px;
                    }
                    &__txt {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }

    // 製品情報
    &__products {
        padding: 90px 0 50px;
        position: relative;
        .p-top__ttl__wrap {
            position: relative;
            z-index: 1;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;        
            height: 80%;
            background: $c-bg;
            z-index: 0;
        }
        &__list {
            display: flex;
            box-shadow: 0px 0px 35px 0px rgba(39, 44, 54, 0.1);
            &__item {
                width: calc(100% / 4);
                border-right: 1px solid #e0e0e0;
                position: relative;
                background: #fff;
                @include pc {
                    &:hover {
                        .p-top__products__list__item__img {
                            img {
                                transform: scale(1.1);
                            }
                        }
                        .p-top__products__list__item__ttl {
                            color: $c-accent;
                        }
                    }
                }
                &:last-child {
                    border-right: none;
                }
                &__img {
                    overflow: hidden;
                    img {
                        transition: $transition;
                    }
                }
                &__txtbox {
                    padding: 40px 6%;
                }
                &__ttl {
                    font-size: 2.2rem;
                    font-weight: 500;
                    text-align: center;
                    transition: $transition;
                }
                &__txt {
                    margin-top: 25px;
                    font-size: 1.5rem;
                    color: $c-sub;
                }
                &__link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }
        @include sp {
            padding: 60px 0 20px;
            &__list {
                display: flex;
                flex-wrap: wrap;
                &__item {
                    width: 50%;
                    &:nth-child(2n) {
                        border-right: none;
                    }
                    &:nth-child(n + 3) {
                        border-top: 1px solid #e0e0e0;
                    }
                    &__txtbox {
                        padding: 20px 15px 30px;
                    }
                    &__ttl {
                        font-size: 1.7rem;
                    }
                    &__txt {
                        margin-top: 15px;
                        font-size: 1.3rem;
                    }
                }
            }
            .p-top__btn {
                padding: 0 15px;
            }
        }
    }

    // 技術・事例
    &__technology {
        padding: 90px 0 20px;
        overflow: hidden;
        &__index {
            display: flex;
            &__item {
                font-size: 1.6rem;
                color: #888888;
                margin-left: 30px;
                padding-bottom: 8px;
                cursor: pointer;
                &:first-child {
                    margin-left: 0;
                }
                &.active {
                    color: $c-accent;
                    font-weight: bold;
                    border-bottom: 3px solid $c-accent;
                }
            }
        }
        
        &__list {
            &.slick-slider {
                &::after {
                    top: 98%;
                }
            }
            // display: flex;
            @media screen and (min-width: 1200px) and (max-width: 1600px) {
                &.is-few-pcmin {
                    .slick-track {
                        width: 100% !important;
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
            &__item {
                width: 270px;
                margin: 40px 20px;
                background: #fff;
                position: relative;
                @include pc {
                    &:hover {
                        .img {
                            img {
                                transform: scale(1.1);
                            }
                        }
                        .ttl {
                            color: $c-accent;
                        }
                    }
                }
                .tag {
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: 1.3rem;
                    color: #fff;
                    padding: 5px 10px;
                    min-width: 95px;
                    text-align: center;
                    z-index: 1;
                    line-height: 1;
                }
                .tag--type01 {
                    background: #0543ab;
                }
                .tag--type02 {
                    background: #00a973;
                }
                .tag--type03 {
                    background: #009fc1;
                }
                .img {
                    overflow: hidden;
                    img {
                        transition: $transition;
                    }
                }
                .info {
                    margin: 30px 0 0;
                    .sub-ttl {
                        color: #444444;
                        font-size: 1.4rem;
                    }
                    .ttl {
                        font-size: 1.8rem;
                        min-height: 8rem;
                        transition: $transition;
                        margin-top: 25px;
                    }
                    .from {
                        font-size: 1.3rem;
                        color: $c-sub;
                        margin-top: 20px;
                    }
                }
                .link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }
        @include sp {
            padding: 60px 0 20px;
            .p-top__btn {
                margin-top: 0;
            }
            &__index {
                &__item {
                    font-size: 1.4rem;
                    margin-left: 20px;
                }
            }
            
            &__list {
                &__item {
                    width: 220px;
                    margin: 40px 20px;
                    .tag {
                        font-size: 1.2rem;
                        min-width: 88px;
                    }
                    .info {
                        margin: 25px 0 0;
                        .sub-ttl {
                            font-size: 1.3rem;
                        }
                        .ttl {
                            font-size: 1.6rem;
                            margin-top: 15px;
                        }
                        .from {
                            font-size: 1.2rem;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }

    // イベント・セミナー
    &__event {
        padding: 90px 0;
        overflow: hidden;
        &__ttl {
            font-size: 2.5rem;
            font-weight: 500;
            margin-bottom: 40px;
        }
        &__list {
            &__item {                  
                background: #fff;
                box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.12);
                display: flex;
                position: relative;
                transition: $transition;
                @include pc {
                    &:hover {
                        background: #f4f4f8;
                        box-shadow: none;
                        .p-top__event__list__item__detail {
                            .txt {
                                color: $c-accent;
                            }
                        }
                    }
                }
                &:nth-child(n + 2) {
                    margin-top: 35px;
                }
                &__img {
                    width: 20.8%;
                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
                &__txtbox {
                    width: calc(100% - 20.8%);
                    padding: 30px 2.6%;
                    display: flex;
                }
                &__date {
                    width: 40%;
                    border-right: 1px solid #d6d6d6;
                    padding-right: 2.6%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .year {
                        font-size: 1.6rem;
                        color: $c-sub;
                    }
                    .date {
                        font-size: 2.2rem;
                        font-weight: 500;
                        margin-top: 10px;
                    }
                    .place {
                        margin-top: 10px;
                        font-size: 1.4rem;
                        color: #444444;
                        .icon__place {
                            position: relative;
                            display: inline-block;
                            padding-left: 30px;
                            &::before {
                                position: absolute;
                                content: '';
                                top: 50%;
                                left: 0;
                                transform: translate(0, -50%);
                                width: 16px;
                                height: 20px;
                                background: url(/assets/img/top/icon_place.png) center/contain no-repeat;
                            }
                        }
                    }
                }
                &__detail {
                    width: 60%;
                    padding-left: 2.6%;
                    display: flex;
                    align-items: center;
                    .txt {
                        font-size: 1.8rem;
                        transition: $transition;
                    }
                }
                &__link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }
        &__noevent {
            margin-top: 35px;
            background: #f4f4f8;
            padding: 30px 35px;
            &__txt {
                font-size: 1.8rem;
                .icon_noevent {
                    position: relative;
                    display: inline-block;
                    padding-left: 55px;
                    &::before {
                        position: absolute;
                        content: '';
                        top: 50%;
                        left: 0;
                        transform: translate(0, -50%);
                        width: 33px;
                        height: 31px;
                        background: url(/assets/img/top/icon_no_event.png) center/contain no-repeat;
                    }
                }
            }
        }
        @include sp {
            padding: 60px 0 80px;
            .p-top__btn {
                margin-top: 20px;
            }
            &__ttl {
                font-size: 2.2rem;
                margin-bottom: 25px;
            }
            &__list {
                &__item {          
                    display: block;
                    &:nth-child(n + 2) {
                        margin-top: 25px;
                    }
                    &__img {
                        width: 100%;
                        height: 140px;
                    }
                    &__txtbox {
                        width: 100%;
                        padding: 25px;
                        display: block;
                    }
                    &__date {
                        width: 100%;
                        border-right: none;
                        border-bottom: 1px solid #d6d6d6;
                        padding-right: 0;
                        padding-bottom: 15px;
                        margin-bottom: 15px;
                        .year {
                            font-size: 1.4rem;
                        }
                        .date {
                            font-size: 1.7rem;
                        }
                    }
                    &__detail {
                        width: 100%;
                        padding-left: 0;
                        .txt {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
            &__noevent {
                margin-top: 25px;
                padding: 25px;
                &__txt {
                    font-size: 1.6rem;
                }
            }
        }
        // セミナー
        .p-top__seminar {
            &__wrap {
                margin-top: 80px;
                .p-top__event__ttl {
                    margin-bottom: 0;
                }
            }
            &__list {
                // display: flex;
                &__item {
                    width: 385px;
                    margin: 40px 20px;
                    padding: 60px 30px 40px;
                    background: #fff;
                    box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.12);
                    transition: $transition;
                    position: relative;
                    @include pc {
                        &:hover {
                            background: #f4f4f8;
                            box-shadow: none;
                            .ttl {
                                color: $c-accent;
                            }
                        }
                    }
                    .tag {
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-size: 1.3rem;
                        color: #fff;
                        padding: 5px 10px;
                        min-width: 85px;
                        text-align: center;
                        line-height: 1;
                    }
                    .tag--webinar {
                        background: #8fc31f;
                    }
                    .tag--seminar {
                        background: #f39800;
                    }
                    .tag--seminar-webinar {
                        background: #4E84E9;
                    }
                    .ttl {
                        font-size: 1.8rem;
                        min-height: 8rem;
                        transition: $transition;
                    }
                    .info {
                        margin-top: 30px;
                        color: #444444;
                        font-size: 1.5rem;
                        display: flex;
                        flex-wrap: wrap;
                        .date {
                            width: 100%;
                            margin-bottom: 10px;
                          .icon__date {
                              position: relative;
                              display: inline-block;
                              padding-left: 30px;
                              &::before {
                                position: absolute;
                                content: '';
                                top: 50%;
                                left: 0;
                                transform: translate(0, -50%);
                                width: 19px;
                                height: 19px;
                                background: url(/assets/img/top/icon_date.png) center/contain no-repeat;
                            }
                          }  
                        }
                        .time {
                            width: calc((100% - 1.7%) / 2);
                            margin-right: 1.7%;
                            .icon__time {
                                position: relative;
                                display: inline-block;
                                padding-left: 30px;
                                &::before {
                                  position: absolute;
                                  content: '';
                                  top: 50%;
                                  left: 0;
                                  transform: translate(0, -50%);
                                  width: 19px;
                                  height: 19px;
                                  background: url(/assets/img/top/icon_time.png) center/contain no-repeat;
                              }
                            }  
                        }
                        .place {
                            width: calc((100% - 1.7%) / 2);
                            font-size: 1.4rem;
                            .icon__place {
                                position: relative;
                                display: inline-block;
                                padding-left: 30px;
                                &::before {
                                  position: absolute;
                                  content: '';
                                  top: 50%;
                                  left: 0;
                                  transform: translate(0, -50%);
                                  width: 19px;
                                  height: 19px;
                                  background: url(/assets/img/top/icon_place.png) center/contain no-repeat;
                              }
                            } 
                        }
                    }
                    .person {
                        margin-top: 25px;
                        padding-top: 25px;
                        border-top: 1px solid #e8e8e8;
                        display: flex;
                        &__img {
                            width: 60px;
                            height: 60px;
                            margin-right: 15px;
                        }
                        &__info {
                            width: calc(100% - 75px);
                            .name {
                                font-size: 1.4rem;
                            }
                            .txt {
                                font-size: 1.2rem;
                                color: $c-sub;
                                margin-top: 5px;
                            }
                        }
                    }
                    .link {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }
                }
            }
            @include sp {
                padding-left: 15px;
                &__wrap {
                    margin-top: 60px;
                }
                &__list {
                    // display: flex;
                    &__item {
                        width: 285px;
                        margin: 25px 10px;
                        padding: 40px 25px 30px;
                        .tag {
                            font-size: 1.2rem;
                            padding: 5px 10px;
                            min-width: 85px;
                        }
                        .ttl {
                            font-size: 1.6rem;
                            min-height: 4.5rem;
                        }
                        .info {
                            margin-top: 20px;
                            font-size: 1.3rem;
                            .date {
                              .icon__date {
                                  padding-left: 25px;
                                  &::before {
                                    width: 16px;
                                    height: 16px;
                                }
                              }  
                            }
                            .time {
                                .icon__time {
                                    padding-left: 25px;
                                    &::before {
                                      width: 16px;
                                      height: 16px;
                                  }
                                }  
                            }
                            .place {
                                font-size: 1.3rem;
                                .icon__place {
                                    padding-left: 25px;
                                    &::before {
                                      width: 16px;
                                      height: 16px;
                                  }
                                } 
                            }
                        }
                        .person {
                            margin-top: 30px;
                            padding-top: 20px;
                        }
                    }
                }
            }
        }
    }

    // ABOUT JEOL
    &__about {
        padding: 130px 0;
        background: url(/assets/img/top/about_bg.png) top/cover no-repeat;
        .p-top__ttl {
            margin-bottom: 50px;
            &__color {
                color: #fff;
            }
            &__sub {
                color: #fff;
            }
        }
        &__txtarea {
            width: 45%;
        }
        &__txt {
            font-size: 1.6rem;
            color: #fff;
            line-height: 2;
            letter-spacing: 0.05rem;
        }
        &__btnarea {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            .p-top__btn {
                margin-top: 30px;
                &:first-child {
                    margin-right: 30px;
                }
                &__link {
                    color: #fff;
                    &::before {
                        background: #fff;
                    }
                    &::after {
                        background: url(/assets/img/common/icon_arrow_right.png) center/contain no-repeat;
                    }
                }
            }
        }
        @include sp {
            padding: 60px 0 250px;
            background: -webkit-linear-gradient( -90deg, rgb(6,92,167) 0%, rgb(64,146,208) 100%);
            position: relative;
            &::after {
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                right: 0;            
                background: url(/assets/img/top/about_bg_sp.png) bottom/cover no-repeat;
                height: 250px;
            }
            &__txtarea {
                width: 100%;
            }
            &__btnarea {
                display: block;
                position: relative;
                z-index: 1;
                .p-top__btn {
                    margin-top: 30px;
                    &:first-child {
                        margin-right: 0;
                    }
                    &:last-child {
                        margin-top: 40px;
                    }
                }
            }
        }
    }

    // NEWS
    &__news {
        padding: 150px 0;
        .p-top__ttl__wrap {
            position: relative;
            justify-content: center;
            margin-bottom: 20px;
            .p-top__ttl {
                margin-bottom: 0;
                &__sub {
                    display: block;
                    margin-left: 0;
                    text-align: center;
                }
            }
            .p-top__btn {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);
            }
        }
        @include sp {
            padding: 80px 0 100px;
            .p-top__ttl__wrap {
                margin-bottom: 15px;
                .p-top__ttl {
                    &__sub {
                        text-align: left;
                    }
                }
            }
        }
    }
    // message
    &__message {
        display: flex;
        justify-content: center;
        width: 75%;
        height: auto;
        padding: 15px;
        margin-top: 40px;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        border: #dbdbdb solid 1px;
        font-size: 100%;
        @include sp {
            width: 90%;
        }
        + .p-top__information {
            margin-top: 40px;
        }
    }
}
