@charset "UTF-8";

// ベーステキストカラー
$c-base: #222222;
// 説明文,日付などのサブテキストカラー
$c-sub: #666666;
// アクセントカラー
$c-accent: #002d78;
// 赤字
$c-red: #e60e00;
// 主にバックグランドで使用
$c-bg: #eff0f5;