@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 講習ページ
.p-training {
    .js-movie-modal-open {
        .m-image-text__image {
            background: #000;
            cursor: pointer;
            img {
                transition: $transition;
            }
        }
        @include pc {
            &:hover {
                .m-image-text__image img {
                    opacity: 0.6;
                }       
            }
        }
    }
    .m-modal {
        &__contents {
            max-width: 900px;
            &__inner {
                padding: 0;
            }
        }
    }
    @include sp {
        .m-image-text {
            flex-direction: column-reverse;
            &--type01 {
                .m-image-text {
                    &__item {
                        &:first-child {
                            margin-top: 30px;
                        }
                        &:nth-child(2n) {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
    &__box--white {
        padding: 60px 50px 70px;
        background: #fff;
        border-radius: 0;
        @include sp {
            padding: 40px 20px;
        }
    }
    &__border {
        position: relative;
        &::before {
            position: absolute;
            content: '';
            left: 50%;
            top: -105px;
            width: 1px;
            height: 70px;
            background: $c-accent;
        }
        @include sp {
            &::before {
                top: -75px;
                height: 50px;
            }
        }
    }
    .l-lower__section {
        &.m-mt70px {
            margin-top: 70px;
        }
        @include sp {
            &.m-mt50px-sp {
                margin-top: 50px;
            }
        }
    } 
}